/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Select, Textarea } from "components";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM, MEASUREMENTS_TYPE } from "enums";
import {
  SingleSectionElement,
  Drawing,
  JoineryDimension,
  AdditionBottomWindow,
  AdditionSideWindow,
  WindowAccessories,
  WindowSills,
} from "features/addingMeasurement";
import {
  useStartMeasurement,
  usePutWindowsReplacementMeasurementValues,
} from "hooks/measurements";
import { handleMeasurementSuccess } from "features/addingMeasurement/utils";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useUploadFile } from "hooks/utils";
import { ScrollToError } from "utils";
import { useGetMeasurementTypes } from "hooks/measurements";

interface WindowsReplacementMeasurementValuesType {
  measurementUuid: string | null;
  prevStep: () => void;
  measurementInfo: any;
  setStep(step: number): void;
}

const WindowsReplacementMeasurementValues = ({
  measurementUuid,
  prevStep,
  setStep,
  measurementInfo,
}: WindowsReplacementMeasurementValuesType) => {
  const navigate = useNavigate();
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const [formDraw, setFormDraw] = useState<any>();
  const [isAddNew, setIsAddNew] = useState(false);
  const { data: measurementTypes } = useGetMeasurementTypes(true);
  const typeOfMeasuementUuid = measurementTypes?.find(
    (item) => item.type === MEASUREMENTS_TYPE.WINDOWS_REPLACEMENT
  )?.value;

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const commentsWindowAdditionComment =
    initialMeasurementInfo?.windowAdditionUuids?.find(
      (item: any) => item.comment
    )?.comment;

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      handleMeasurementSuccess(data, searchParams, setSearchParams, setStep);
    },
  });

  const uploadFileMutation = useUploadFile({
    onSuccess: (data) => {
      sessionStorage.removeItem("canvasState");
      if (isAddNew) {
        setIsAddNew(false);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } else {
        goToList();
      }
    },
  });

  const { mutate: updateMeasurementInfo } =
    usePutWindowsReplacementMeasurementValues({
      onSuccess: (data) => {
        if (formDraw !== "oldDraw") {
          uploadFileMutation.mutate({
            parentUuid: data.measurement.measurementInfo.uuid,
            type: "windowDivisionDrawingWindowsReplacement",
            files: [formDraw],
          });
        } else {
          goToList();
        }
      },
    });

  const handleOnSubmit = async (values: any, actions: any) => {
    setFormDraw(values.drawing);
    updateMeasurementInfo(values);
    if (isAddNew) {
      startMeasurement.mutate({
        typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
        orderUuid,
      });
    }
  };

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any,
    setFieldValue: any
  ) => {
    const {
      joinerySizeWidth,
      windowSideAdditionSize,
      joinerySizeHeight,
      windowBottomAdditionSize,
      holeInternalHeight,
      holeInternalWidth,
      warningComment,
    } = values;

    if (
      joinerySizeHeight &&
      joinerySizeWidth &&
      holeInternalHeight &&
      holeInternalWidth
    ) {
      const holeWidth = parseInt(holeInternalWidth, 10);
      const holeHeight = parseInt(holeInternalHeight, 10);
      const joineryHeight = parseInt(joinerySizeHeight, 10);
      const joineryWidth = parseInt(joinerySizeWidth, 10);
      const bottomAddition = isNaN(parseInt(windowBottomAdditionSize, 10))
        ? 0
        : parseInt(windowBottomAdditionSize, 10);
      const sideAddition = isNaN(parseInt(windowSideAdditionSize, 10))
        ? 0
        : parseInt(windowSideAdditionSize, 10);

      const widthDifference = holeWidth + sideAddition - joineryWidth;
      const heightDifference = holeHeight + bottomAddition - joineryHeight;
      if (
        widthDifference >= 0 &&
        widthDifference <= 50 &&
        heightDifference >= 0 &&
        heightDifference <= 50
      ) {
        setIsErrorDimension(false);
      } else {
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
        setIsErrorDimension(true);
      }
    }
    return false;
  };

  const initialValues = {
    measurementUuid: measurementUuid,
    roomName: initialMeasurementInfo?.roomName || "",
    holeExternalWidth: initialMeasurementInfo?.holeExternalWidth || "",
    holeExternalHeight: initialMeasurementInfo?.holeExternalHeight || "",
    holeInternalWidth: initialMeasurementInfo?.holeInternalWidth || "",
    holeInternalHeight: initialMeasurementInfo?.holeInternalHeight || "",
    joinerySizeWidth: initialMeasurementInfo?.joinerySizeWidth || "",
    joinerySizeHeight: initialMeasurementInfo?.joinerySizeHeight || "",
    windowBottomAddition: initialMeasurementInfo?.windowBottomAddition || "",
    windowBottomAdditionSize:
      initialMeasurementInfo?.windowBottomAdditionSize || "",
    windowSideAdditionSize:
      initialMeasurementInfo?.windowSideAdditionSize || "",
    windowSideAddition: initialMeasurementInfo?.windowSideAddition || "",
    windowAdditionUuids: initialMeasurementInfo?.windowAdditionUuids || [],
    typeOfSlidingWindow: initialMeasurementInfo?.typeOfSlidingWindow || "",
    comments: initialMeasurementInfo?.comments || "",
    windowComment: initialMeasurementInfo?.windowComment || "",
    commentsWindowAddition: commentsWindowAdditionComment || "",
    warningComment: initialMeasurementInfo?.warningComment || "",
    file: initialMeasurementInfo?.file || [],
    windowSill: initialMeasurementInfo?.windowSill || [],
  };

  interface ObjectWithComment {
    comment: string;
  }

  const isObjectWithComment = (value: any): value is ObjectWithComment => {
    return (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      typeof value.comment === "string" &&
      value.comment.trim() !== ""
    );
  };

  const validationSchema = Yup.object().shape({
    holeExternalWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeExternalHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeInternalWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeInternalHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    joinerySizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    joinerySizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    windowBottomAdditionSize: Yup.string().test(
      "windowBottomAdditionSize-required",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { windowBottomAddition } = this.parent;
        if (!windowBottomAddition && value) {
          return this.createError({
            message: VALIDATION_MESSAGES.REQUIRED,
          });
        }
        if (windowBottomAddition) {
          return value !== undefined && value !== "";
        }
        return true;
      }
    ),

    windowSideAdditionSize: Yup.string().test(
      "windowSideAdditionSize-required",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { windowSideAddition } = this.parent;
        if (!windowSideAddition && value) {
          return this.createError({
            message: VALIDATION_MESSAGES.REQUIRED,
          });
        }
        if (windowSideAddition) {
          return value !== undefined && value !== "";
        }
        return true;
      }
    ),
    drawing: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    warningComment: isErrorDimension
      ? Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
      : Yup.string(),
    windowSill: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        length: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
        width: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
      })
    ),
    // windowAdditionUuids: Yup.array()
    //   .of(
    //     Yup.mixed().test(
    //       "has-no-empty-comment",
    //       VALIDATION_MESSAGES.REQUIRED,
    //       (value) => {
    //         if (typeof value === "string") return true;
    //         if (
    //           typeof value === "object" &&
    //           value !== null &&
    //           "comment" in value
    //         ) {
    //           return (
    //             typeof value.comment === "string" && value.comment.trim() !== ""
    //           );
    //         }
    //         return true;
    //       }
    //     )
    //   )
    //   .nullable()
    //   .default([]),
    windowAdditionUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object-with-comment",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value) &&
              typeof (value as ObjectWithComment).comment === "string" &&
              (value as ObjectWithComment).comment.trim() !== "")
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string-or-object-with-comment",
        VALIDATION_MESSAGES.REQUIRED,
        (array) =>
          array.some(
            (item) => typeof item === "string" || isObjectWithComment(item)
          )
      ),
  });

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values, actions) => handleOnSubmit(values, actions)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          handleCheckValidateDimensions(
            values,
            setFieldError,
            errors,
            setFieldValue
          );
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                errors={errors}
                label="Rysunek podziału okien"
                name="drawing"
                component={Drawing}
              />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name={["holeExternalWidth", "holeExternalHeight"]}
                errors={errors}
                className="am-margin-top window-replacement"
              >
                <div className="am-section-heading">
                  Wymiar otworu z zewnątrz
                </div>
                <Field
                  label="Szerokość"
                  name={`holeExternalWidth`}
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
                <Field
                  label="Wysokość"
                  name={`holeExternalHeight`}
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name={["holeInternalWidth", "holeInternalHeight"]}
                errors={errors}
                className="am-margin-top window-replacement"
              >
                <div className="am-section-heading">Wymiar otworu wewnątrz</div>
                <Field
                  label="Szerokość"
                  name={`holeInternalWidth`}
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
                <Field
                  label="Wysokość"
                  name={`holeInternalHeight`}
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>

              <Field
                errors={errors}
                label="Wymiar stolarki"
                validateField={validateField}
                component={JoineryDimension}
                windowReplacement
              />
              <Field
                errors={errors}
                label="Dodatek dół okna"
                white
                name="windowBottomAddition"
                windowReplacement
                component={AdditionBottomWindow}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                validateField={validateField}
              />
              <Field
                errors={errors}
                validateField={validateField}
                label="Dodatek bok okna"
                white
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                windowReplacement
                name="windowSideAddition"
                component={AdditionSideWindow}
                isErrorDimension={isErrorDimension}
              />
              {typeOfMeasuementUuid && (
                <Field
                  values={values}
                  errors={errors}
                  validateField={validateField}
                  name="windowAdditionUuids"
                  typeOfMeasuementUuid={typeOfMeasuementUuid}
                  component={WindowAccessories}
                />
              )}
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="typeOfSlidingWindow"
                errors={errors}
              >
                <Field
                  label="Rodzaj okna przesuwnego"
                  optional
                  name="typeOfSlidingWindow"
                  placeholder="Wybierz"
                  component={Select}
                  options={[
                    { value: "hst", label: "HST" },
                    { value: "smartSlide", label: "Smart-slide" },
                    { value: "psk", label: "PSK" },
                  ]}
                  white
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                component={WindowSills}
              />
              <Field
                optional
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={async () => {
                    await handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowsReplacementMeasurementValues;
