export enum PAYMENT_METHOD {
  cash = "cash",
  transfer = "transfer",
  inShowroom = "inShowroom",
  noPayment = "noPayment",
  null = "null",
}

export const PAYMENT_METHOD_NAME: Record<PAYMENT_METHOD, string> = {
  [PAYMENT_METHOD.cash]: "Gotówka",
  [PAYMENT_METHOD.transfer]: "Przelew",
  [PAYMENT_METHOD.inShowroom]: "Salon",
  [PAYMENT_METHOD.noPayment]: "Brak",
  [PAYMENT_METHOD.null]: "-",
};