/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Switch, Textarea } from "components";
import {
  SingleSectionElement,
  HoleDimension,
  AdditionalWarning,
} from "features/addingMeasurement";
import { useStartMeasurement } from "hooks/measurements";
import { ScrollToError } from "utils";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { usePutExternalDoorsReplacementInfo } from "hooks/measurements";
import "./external-door-in-row-measurement-values.scss";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { handleMeasurementSuccess } from "features/addingMeasurement/utils";

interface ExternalDoorInRowMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorInRowMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorInRowMeasurementValuesType) => {
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const [isAddNew, setIsAddNew] = useState(false);

  const initialValues = {
    measurementUuid: measurementUuid,
    roomName: initialMeasurementInfo?.roomName || "",
    doorSide: initialMeasurementInfo?.doorSide || "left",
    openingDirection: initialMeasurementInfo?.openingDirection || "outwards",
    holeExternalWidth: initialMeasurementInfo?.holeExternalWidth || "",
    holeInternalWidth: initialMeasurementInfo?.holeInternalWidth || "",
    holeExternalHeight: initialMeasurementInfo?.holeExternalHeight || "",
    holeInternalHeight: initialMeasurementInfo?.holeInternalHeight || "",
    holeWidthDifference: initialMeasurementInfo?.holeWidthDifference || "",
    holeHeightDifference: initialMeasurementInfo?.holeHeightDifference || "",
    doorWidth: initialMeasurementInfo?.doorWidth || "",
    doorHeight: initialMeasurementInfo?.doorHeight || "",
    doorSidelightRight: initialMeasurementInfo?.doorSidelightRight || "",
    doorSidelightLeft: initialMeasurementInfo?.doorSidelightLeft || "",
    doorTopLight: initialMeasurementInfo?.doorTopLight || "",
    doorBottomExtension: initialMeasurementInfo?.doorBottomExtension || "",
    holeIsCorrect: initialMeasurementInfo?.holeIsCorrect ?? true,
    correctHoleWidth: initialMeasurementInfo?.correctHoleWidth || "",
    correctHoleHeight: initialMeasurementInfo?.correctHoleHeight || "",
    holePreparation: initialMeasurementInfo?.holePreparation || "",
    manufacturer: initialMeasurementInfo?.manufacturer || "",
    model: initialMeasurementInfo?.model || "",
    color: initialMeasurementInfo?.color || "",
    typeOfDoorFrame: initialMeasurementInfo?.typeOfDoorFrame || "",
    doorHandle: initialMeasurementInfo?.doorHandle || "",
    doorKnocker: initialMeasurementInfo?.doorKnocker || "",
    peephole: initialMeasurementInfo?.peephole || false,
    glassPanels: initialMeasurementInfo?.glassPanels || "",
    powerSupply: initialMeasurementInfo?.powerSupply || "",
    dripCap: initialMeasurementInfo?.dripCap || false,
    shortenDoorHeight: initialMeasurementInfo?.shortenDoorHeight || "",
    reedSwitches: initialMeasurementInfo?.reedSwitches || false,
    comments: initialMeasurementInfo?.comments || "",
    warningComment: initialMeasurementInfo?.warningComment || "",
  };

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");

  const { mutate } = usePutExternalDoorsReplacementInfo({
    onSuccess: (data) => {
      if (isAddNew) {
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      handleMeasurementSuccess(data, searchParams, setSearchParams, setStep);
    },
  });

  const handleOnSubmit = async (values: any, actions: any) => {
    await mutate(values);
  };

  const validationSchema = Yup.object().shape({
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    holeExternalWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeInternalWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeExternalHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeInternalHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeWidthDifference: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeHeightDifference: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    doorWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    doorHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    correctHoleWidth: Yup.number().when("holeIsCorrect", {
      is: (value: any) => value === false,
      then: (schema) => schema.required(VALIDATION_MESSAGES.REQUIRED),
      otherwise: (schema) => schema.notRequired(),
    }),
    correctHoleHeight: Yup.number().when("holeIsCorrect", {
      is: (value: any) => value === false,
      then: (schema) => schema.required(VALIDATION_MESSAGES.REQUIRED),
      otherwise: (schema) => schema.notRequired(),
    }),
    warningComment: isErrorDimension
      ? Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
      : Yup.string(),
  });

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any
  ) => {
    const {
      doorWidth,
      doorSidelightRight,
      doorSidelightLeft,
      doorTopLight,
      doorBottomExtension,
      doorHeight,
      holeInternalWidth,
      holeInternalHeight,
      warningComment,
    } = values;

    if (
      (doorWidth && holeInternalWidth && doorHeight && holeInternalHeight) ||
      isErrorDimension
    ) {
      const parsedDoorWidth = parseInt(doorWidth, 10) || 0;
      const parsedDoorSidelightRight = parseInt(doorSidelightRight, 10) || 0;
      const parsedDoorSidelightLeft = parseInt(doorSidelightLeft, 10) || 0;
      const parsedDoorTopLight = parseInt(doorTopLight, 10) || 0;
      const parsedDoorBottomExtension = parseInt(doorBottomExtension, 10) || 0;
      const parsedDoorHeight = parseInt(doorHeight, 10) || 0;
      const parsedHoleWidth = parseInt(holeInternalWidth, 10) || 0;
      const parsedHoleHeight = parseInt(holeInternalHeight, 10) || 0;

      const totalDoorWidth =
        parsedDoorWidth + parsedDoorSidelightRight + parsedDoorSidelightLeft;
      const totalDoorHeight =
        parsedDoorHeight + parsedDoorBottomExtension + parsedDoorTopLight;

      let isError = false;

      const widthDifference = parsedHoleWidth - totalDoorWidth;
      if (widthDifference < 0 || widthDifference > 30) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }

      const heightDifference = parsedHoleHeight - totalDoorHeight;
      if (heightDifference < 0 || heightDifference > 30) {
        isError = true;
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
      }

      setIsErrorDimension(isError);
    }
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values, actions) => handleOnSubmit(values, actions)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }

          handleCheckValidateDimensions(values, setFieldError, errors);
          console.log(values.holeIsCorrect);
          return (
            <form
              className="amfs__form form amfs--with-warning-comment"
              onSubmit={handleSubmit}
            >
              <ScrollToError errorDimension={isErrorDimension} />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorSide"
                errors={errors}
              >
                <Field
                  first="Lewe"
                  second="Prawe"
                  stringMode
                  label="Stronność"
                  name="doorSide"
                  valueFirst="left"
                  valueSecond="right"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="openingDirection"
                errors={errors}
              >
                <Field
                  label="Kierunek otwierania"
                  first="Do wewnątrz"
                  second="Na zewnątrz"
                  valueFirst="inwards"
                  valueSecond="outwards"
                  stringMode
                  name="openingDirection"
                  component={Switch}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                label="Wymiar otworu"
                doorReplacement
                component={HoleDimension}
                setFieldValue={setFieldValue}
              />
              <SingleSectionElement
                touched={touched}
                name={["doorWidth", "doorHeight"]}
                errors={errors}
                className="padding-top-separator"
              >
                <div className="doors-form">
                  <div className="am-section-heading">
                    Wymiar docelowych drzwi
                  </div>

                  <Field
                    label="Szerokość"
                    name="doorWidth"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    label="Wysokość"
                    name="doorHeight"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    optional
                    label="Doświetle boczne prawe"
                    name="doorSidelightRight"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />
                  <Field
                    optional
                    label="Doświetle boczne lewe"
                    name="doorSidelightLeft"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    optional
                    label="Doświetle górne"
                    name="doorTopLight"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />

                  <Field
                    optional
                    label="Poszerzenie dolne"
                    name="doorBottomExtension"
                    type="number"
                    rightPlaceholder="mm"
                    component={Input}
                  />
                </div>
              </SingleSectionElement>

              <AdditionalWarning
                isErrorDimension={isErrorDimension}
                errors={errors}
                values={values}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />

              <SingleSectionElement
                touched={touched}
                name="holeIsCorrect"
                errors={errors}
              >
                <Field
                  checked={values.holeIsCorrect}
                  label={`Czy otwór jest prawidłowy?`}
                  name="holeIsCorrect"
                  component={Switch}
                />
              </SingleSectionElement>

              {!!values.holeIsCorrect === false && (
                <>
                  <SingleSectionElement
                    touched={touched}
                    name="correctHoleWidth"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa szerokość otworu"
                      name="correctHoleWidth"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="correctHoleHeight"
                    errors={errors}
                    className="padding-top-separator"
                  >
                    <Field
                      label="Prawidłowa wysokość otworu"
                      name="correctHoleHeight"
                      type="number"
                      rightPlaceholder="mm"
                      component={Input}
                    />
                  </SingleSectionElement>
                  <SingleSectionElement
                    touched={touched}
                    name="holePreparation"
                    errors={errors}
                  >
                    <Field
                      checked={values.holePreparation === "investor"}
                      label={`Kto ma przygotować otwór?`}
                      name="holePreparation"
                      first="Inwestor"
                      second="MartDom"
                      stringMode
                      valueFirst="investor"
                      valueSecond="martdom"
                      component={Switch}
                    />
                  </SingleSectionElement>
                </>
              )}
              <SingleSectionElement
                touched={touched}
                name="manufacturer"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Producent"
                  maxLength={30}
                  name="manufacturer"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="model"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Model"
                  maxLength={30}
                  name="model"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Kolor"
                  maxLength={30}
                  name="color"
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name="typeOfDoorFrame"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={30}
                  label="Rodzaj ościeżnicy"
                  name="typeOfDoorFrame"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorHandle"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Klamka"
                  maxLength={30}
                  name="doorHandle"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="doorKnocker"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Antaba"
                  maxLength={30}
                  name="doorKnocker"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="peephole"
                errors={errors}
              >
                <Field
                  checked={values.peephole}
                  label={`Wizjer`}
                  optional
                  name="peephole"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="glassPanels"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Szklenie"
                  name="glassPanels"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="powerSupply"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  maxLength={30}
                  label="Zasilacz"
                  name="powerSupply"
                  component={Input}
                />
              </SingleSectionElement>

              <SingleSectionElement
                touched={touched}
                name="dripCap"
                errors={errors}
              >
                <Field
                  checked={values.dripCap}
                  label={`Okapnik`}
                  optional
                  name="dripCap"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="shortenDoorHeight"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  optional
                  label="Skrót drzwi"
                  name="shortenDoorHeight"
                  rightPlaceholder="mm"
                  type="number"
                  maxLength={6}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="reedSwitches"
                errors={errors}
              >
                <Field
                  checked={values.reedSwitches}
                  label={`Kontraktrony`}
                  optional
                  name="reedSwitches"
                  component={Switch}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorInRowMeasurementValues;
