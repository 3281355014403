import { USER_ROLE } from "enums";

export const SubMenuItems = (userRole: string | undefined) => {
  const isAdmin = userRole === USER_ROLE.admin;
  if (isAdmin) {
    return [
      {
        label: "Zlecenia",
        value: "orders",
      },
      {
        label: "Lista czynności",
        value: "activityList",
      },
      {
        label: "Checklisty dla monterów",
        value: "checklistInstallation",
      },
    ];
  } else {
    return [
      {
        label: "Zlecenia do ustalenia",
        value: "ordersToBeDetermined",
        status: "ordersToBeDetermined",
      },
      {
        label: "Zlecenia ustalone",
        value: "fixedOrders",
        status: "fixedOrders",
      },
      {
        label: "Zlecenia zarchiwizowane",
        value: "archived",
        status: "archived",
      },
    ];
  }
};
