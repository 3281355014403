import { useState } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  StaticAlert,
  SmsCodeInput,
} from "components";
import "./login-sms-code.scss";
import { Field, Formik } from "formik";
import { ROUTE_ENUM, USER_ROLE } from "enums";
import { useLoginBySms, useSendSms } from "hooks/auth";
import { useNavigate } from "react-router";
import { useGetMe } from "hooks/auth";

const LoginSmsCode = () => {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { refetch } = useGetMe();
  const phone = sessionStorage.getItem("phoneNumber");
  const { mutate } = useSendSms({});

  const { mutate: login } = useLoginBySms({
    onSuccess: async () => {
      const response = await refetch();
      const isAdvisor =
        response && response.data && response.data.role === USER_ROLE.advisor;
      if (isAdvisor) {
        navigate(ROUTE_ENUM.EMPLOYEE_CALENDAR);
      } else {
        navigate(ROUTE_ENUM.ORDERS_LIST);
      }
    },
    onError: (error) => {
      setShowError(true);
    },
  });

  const handleCodeChange = (smsCode: string) => {
    if (smsCode.length === 4) {
      login({ smsCode });
    }
  };

  const handleResendSmsCode = () => {
    if (phone) {
      const phoneJson = JSON.parse(phone);
      mutate(phoneJson);
    }
  };

  return (
    <Page>
      <PageContent>
        <UserAccessPanel
          goBack={ROUTE_ENUM.HOME}
          heading="Wprowadź kod dostępu"
        >
          <div className="uap__description login-sms-code__description">
            Wprowadź 4-cyfrowy kod, który przesłaliśmy Ci w wiadomości SMS.
          </div>
          <Formik
            initialValues={{ phonePrefixUuid: "+48", phoneNumber: "" }}
            enableReinitialize
            onSubmit={async (values) => null}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="login-sms-code__form" onSubmit={handleSubmit}>
                <StaticAlert
                  show={showError}
                  label="Nieprawidłowy kod dostępu"
                />
                <Field
                  component={SmsCodeInput}
                  onCodeChange={handleCodeChange}
                />
              </form>
            )}
          </Formik>
          <div className="login-sms-code__forget">
            Nie otrzymałeś kodu dostępu?
            <span
              className="login-sms-code__forget-link"
              onClick={() => handleResendSmsCode()}
            >
              Wyślij ponownie
            </span>
          </div>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default LoginSmsCode;
