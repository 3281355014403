import { useState, useEffect } from "react";
import {
  CheckLists,
  ItemsLeftAtClient,
  ReceiptProtocol,
} from "features/orders";
import { Button, Textarea, SVG } from "components";
import "./receipt-protocol-generator.scss";
import { Formik, Field } from "formik";
import { useGetChecklistOrder } from "hooks/checklist";
import { useSearchParams } from "react-router-dom";
import { usePutCustomerComment } from "hooks/orders";
import { OrderType } from "types/OrderTypes";
import { SVG_TYPE } from "enums";

interface ReceiptProtocolGeneratorProps {
  orderDetails: OrderType;
}
const ReceiptProtocolGenerator = ({
  orderDetails,
}: ReceiptProtocolGeneratorProps) => {
  const [newCustomerComment, setNewCustomerComment] = useState("");
  const { mutate: updateCustomerComment } = usePutCustomerComment({
    onSuccess: ({ installationGroup: { customerComments } }) => {
      setNewCustomerComment(customerComments);
    },
  });
  const { measurementsArrangedInstallations } = orderDetails;
  const [searchParams] = useSearchParams();
  const groupUuid = searchParams.get("groupUuid");
  const { data: checkList } = useGetChecklistOrder(groupUuid);
  const [isOpenProtocol, setIsOpenProtocol] = useState(false);
  const [hasUncheckedRequired, setHasUncheckedRequired] = useState(false);
  const [errorEndAppointment, setErrorEndAppointment] = useState({
    isError: false,
    errorMessage: "",
  });
  const groupInstallation = measurementsArrangedInstallations?.find(
    (installationGroup) => installationGroup.groupUuid === groupUuid
  );
  const initialCustomerComment = groupInstallation?.customerComments;
  const isProtocolApproved = !!groupInstallation?.protocolApproved || false;
  const handleRequiredStatusChange = (status: boolean) => {
    setHasUncheckedRequired(status);
  };
  const handleGenerateProtocol = () => {
    if (hasUncheckedRequired) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz nieznaczone wymagane checklisty. Uzupełnij brakujące elementy, aby móc wygenerować raport.",
      });
    } else {
      setIsOpenProtocol(true);
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  };

  useEffect(() => {
    if (!hasUncheckedRequired && errorEndAppointment.isError) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  }, [hasUncheckedRequired, errorEndAppointment.isError]);

  const handleUpdateClientComment = (comment: string) => {
    groupUuid && updateCustomerComment({ groupUuid, comment });
  };

  return (
    <>
      <ReceiptProtocol
        newCustomerComment={newCustomerComment}
        groupInstallation={groupInstallation}
        orderDetails={orderDetails}
        groupUuid={groupUuid}
        checkList={checkList}
        onClose={() => setIsOpenProtocol(false)}
        isOpen={isOpenProtocol}
      />
      <div className="active-order__box receipt-protocol-generator">
        <Formik
          initialValues={{
            comments: groupInstallation?.additionalVisitComments || "",
            client: !!groupInstallation?.additionalVisitRequired,
            comment: groupInstallation?.customerComments || "",
          }}
          enableReinitialize
          onSubmit={async (values: any) => console.log(values)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            errors,
            values,
            setFieldValue,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <form>
                <CheckLists
                  isProtocolApproved={isProtocolApproved}
                  errorEndAppointment={errorEndAppointment}
                  onRequiredStatusChange={handleRequiredStatusChange}
                />
                {isProtocolApproved ? (
                  <>
                    <div className="checklist-approved">
                      <div className="active-order__heading receipt-protocol--customer-comments">
                        Uwagi od klienta
                        <span>(opcjonalne)</span>
                      </div>
                      <div className="active-order__details-paragraph">
                        {initialCustomerComment || "-"}
                      </div>
                    </div>
                  </>
                ) : (
                  <Field
                    className="receipt-protocol-generator__textarea"
                    label="Uwagi od klienta"
                    optional
                    maxLength={255}
                    name="comment"
                    placeholder=""
                    component={Textarea}
                    errors={errors}
                    onBlur={(e: any) =>
                      handleUpdateClientComment(e.target.value)
                    }
                  />
                )}
                <ItemsLeftAtClient
                  isProtocolApproved={isProtocolApproved}
                  orderDetails={orderDetails}
                />
                {isProtocolApproved ? (
                  <div className="active-order__heading active-order__heading-approved">
                    Protokół odbioru został zatwierdzony{" "}
                    <SVG type={SVG_TYPE.CHECK_CIRCLE} />
                  </div>
                ) : (
                  <div className="receipt-protocol-generator__button-wrapper">
                    <Button
                      className="receipt-protocol-generator__button"
                      label="Generuj protokół odbioru"
                      onClick={() => handleGenerateProtocol()}
                    />
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ReceiptProtocolGenerator;
