import moment from "moment";

interface Event {
  title: string;
  start: Date;
  end: Date;
}

interface EventsToday {
  isEventToday: boolean;
  events: Event[];
}

export const generateStartTimeOptions = (
  eventsToday: EventsToday = { isEventToday: false, events: [] },
  date: any
) => {
  const options = [];
  const now = moment();
  const currentDate = moment(date.split(" ")[0], "YYYY-MM-DD");
  const isToday = now.isSame(currentDate, "day");
  for (let hour = 6; hour <= 21; hour++) {
    let maxMinute = hour === 21 ? 45 : 59;
    for (let minute = 0; minute <= maxMinute; minute += 15) {
      const timeLabel = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      const timeMoment = moment(`${date.split(" ")[0]} ${timeLabel}`);
      if (isToday && timeMoment.isBefore(now)) {
        continue;
      }
      let isOverlapping = false;
      if (eventsToday.events) {
        for (let event of eventsToday.events) {
          const startMoment = moment(event.start);
          const endMoment = moment(event.end);
          if (timeMoment.isBetween(startMoment, endMoment, undefined, "[)")) {
            isOverlapping = true;
            break;
          }
        }
      }

      if (!isOverlapping) {
        options.push({ value: timeLabel, label: timeLabel });
      }
    }
  }

  return options;
};

export const generateEndTimeOptions = (
  eventsToday: EventsToday = { isEventToday: false, events: [] },
  date: any
) => {
  const options = [];
  let startHour = 6;
  let startMinute = 15;
  for (let hour = startHour; hour <= 22; hour++) {
    let maxMinute = hour === 22 ? 0 : 45;
    let minute = hour === startHour ? startMinute : 0;
    for (; minute <= maxMinute; minute += 15) {
      const timeLabel = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      const timeMoment = moment(`${date.split(" ")[0]} ${timeLabel}`);
      let isOverlapping = false;
      if (eventsToday.events) {
        for (let event of eventsToday.events) {
          const startMoment = moment(event.start);
          const endMoment = moment(event.end);
          if (timeMoment.isBetween(startMoment, endMoment, undefined, "(]")) {
            isOverlapping = true;
            break;
          }
        }
      }
      if (!isOverlapping) {
        options.push({ value: timeLabel, label: timeLabel });
      }
    }
  }
  return options;
};

export const checkEventsToday = (calendarEvents: any, date: any) => {
  const today = moment(date).startOf("day");
  const eventsToday = calendarEvents?.filter((event: any) =>
    moment(event.start).isSame(today, "day")
  );
  return {
    isEventToday: eventsToday?.length > 0,
    events: eventsToday,
  };
};
