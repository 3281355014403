/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import "./calendar-input.scss";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { pl } from "date-fns/locale";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { Button } from "components";

interface SingleDateRangePickerProps {
  label?: string;
  errors: any;
  form: any;
  field: any;
  onChange?: (data: { dateStart: string; dateEnd: string }) => void;
  minDate?: Date;
  withButton?: any;
  size?: "large" | "small" | "medium";
}

const customPlLocale = {
  ...pl,
  localize: {
    ...pl.localize,
    day: (n: any) => {
      const dayNames = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
      return dayNames[n];
    },
  },
};
registerLocale("custom-pl", customPlLocale);

const SingleDateRangePicker = ({
  label,
  errors = {},
  form,
  field,
  onChange,
  minDate,
  size = "large",
  withButton,
}: SingleDateRangePickerProps) => {
  const isArrayField = field.name.includes(".");
  const [textPart, setTextPart] = useState("");
  const dotIndex = field.name.indexOf(".");
  const elementIndex = field.name.substring(dotIndex + 1);
  const fieldName = field.name.substring(0, dotIndex);

  function removeNonNumericChars(inputString: any) {
    return inputString.replace(/\D/g, "");
  }

  useEffect(() => {
    if (isArrayField) {
      const parts = field.name.split(".");
      if (parts.length > 2) {
        const dynamicTextPart = parts.slice(2).join(".");
        setTextPart(dynamicTextPart);
      }
    }
  }, [form.values[fieldName], field.value]);

  const parseDate = (value: string | null) => {
    if (value) {
      const date = moment(value, "DD/MM/yyyy").toDate();
      return date;
    }
    return null;
  };

  const calendarRef = useRef<HTMLDivElement>(null);
  const [dateStart, setDateStart] = useState<Date | null>(
    parseDate(field?.value?.dateStart)
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    parseDate(field?.value?.dateEnd)
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node) &&
        isDatePickerOpen
      ) {
        setIsDatePickerOpen(false);
        handleCloseDate();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  const handleConfirmDate = () => {
    setIsDatePickerOpen(false);
    form.setFieldValue(field.name, {
      dateStart: dateStart ? moment(dateStart).format("DD/MM/YYYY") : "",
      dateEnd: dateEnd
        ? moment(dateEnd).format("DD/MM/YYYY")
        : moment(dateStart).format("DD/MM/YYYY"),
    });

    if (!dateEnd) {
      setDateEnd(dateStart);
    }

    if (onChange) {
      onChange({
        dateStart: dateStart ? moment(dateStart).format("DD/MM/YYYY") : "",
        dateEnd: dateEnd
          ? moment(dateEnd).format("DD/MM/YYYY")
          : moment(dateStart).format("DD/MM/YYYY"),
      });
    }
  };

  const handleCloseDate = () => {
    setDateStart(parseDate(field.value?.dateStart));
    setDateEnd(parseDate(field.value?.dateEnd));
    setIsDatePickerOpen(false);
  };

  function showError() {
    if (isArrayField) {
      if (dotIndex !== -1) {
        if (elementIndex.includes(".")) {
          const afterRemoveString = removeNonNumericChars(elementIndex);

          const errorTextPart =
            errors[fieldName]?.[afterRemoveString]?.[textPart] || "";
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][afterRemoveString] &&
                errorTextPart && <span>{errorTextPart}</span>}
            </div>
          );
        } else {
          const errorTextPart =
            errors[fieldName]?.[elementIndex]?.[textPart] || "";

          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][elementIndex] &&
                errorTextPart && <span>{errorTextPart}</span>}
            </div>
          );
        }
      } else {
        return null;
      }
    } else if (errors && form.touched) {
      const errorTextPart = errors[field.name]?.[textPart] || "";
      return (
        <div className="input__error">
          {form.touched[field.name] &&
            (errorTextPart || errors[field.name]) && (
              <span>{errorTextPart || errors[field.name]}</span>
            )}
        </div>
      );
    }
  }

  return (
    <div className={`calendar-input__wrapper`} ref={calendarRef}>
      <div className="calendar-input__content">
        {label && <label className="calendar-input__label">{label}</label>}
        <div
          className={`calendar-input__icon ${
            size
              ? `calendar-input__icon--${size} ${
                  withButton ? "calendar-input__icon--with-button" : ""
                }`
              : ""
          }`}
          onClick={() => setIsDatePickerOpen(true)}
        >
          {withButton && (
            <Button size="small" label="Potwierdź " onClick={withButton} />
          )}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
              stroke="#096EB5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div onClick={() => !isDatePickerOpen && setIsDatePickerOpen(true)}>
          <DatePicker
            className={`${size ? `react-datepicker__input--${size}` : ""}`}
            readOnly
            selectsRange
            startDate={dateStart}
            endDate={dateEnd}
            onChange={(dates: [Date | null, Date | null]) => {
              const [start, end] = dates;
              setDateStart(start);
              setDateEnd(end);
            }}
            minDate={minDate}
            dateFormat="dd/MM/yyyy"
            shouldCloseOnSelect={false}
            open={isDatePickerOpen}
            locale="custom-pl"
            renderCustomHeader={({
              monthDate,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div className="react-datepicker__custom-head">
                <button
                  type="button"
                  aria-label="Previous Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  onClick={decreaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString("pl", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <button
                  aria-label="Next Month"
                  type="button"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
          >
            <div className="react-datepicker__buttons">
              <Button
                size="medium"
                onClick={handleCloseDate}
                stroke
                label="Anuluj"
              />
              <Button
                size="medium"
                label="Potwierdź"
                onClick={handleConfirmDate}
              />
            </div>
          </DatePicker>
        </div>
      </div>
      {showError()}
    </div>
  );
};

export default SingleDateRangePicker;
