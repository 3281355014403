import { useState, useRef, useEffect } from "react";
import "./desktop-menu.scss";
import { NavLink } from "react-router-dom";
import { ROUTE_ENUM, SVG_TYPE, USER_ROLE } from "enums";
import { SVG } from "components";
import { useLogout } from "hooks/auth/";
import { UserType } from "types/UsersTypes";

interface DesktopMenuType {
  user: UserType | undefined;
}

const DesktopMenu = ({ user }: DesktopMenuType) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigationRef = useRef<HTMLElement | null>(null);
  const { mutate: logout } = useLogout({});
  const isAdmin = user?.role === USER_ROLE.admin;
  const isAdvisor = user?.role === USER_ROLE.advisor;
  const isScheduleManager = user?.role === USER_ROLE.scheduleManager;

  useEffect(() => {
    const navigationNode = navigationRef.current;

    if (navigationNode) {
      const handleMouseEnter = () => setIsNavOpen(true);
      const handleMouseLeave = () => setIsNavOpen(false);

      navigationNode.addEventListener("mouseenter", handleMouseEnter);
      navigationNode.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        navigationNode.removeEventListener("mouseenter", handleMouseEnter);
        navigationNode.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [navigationRef]);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <nav
        ref={navigationRef}
        className={`desktop-menu ${isNavOpen ? "desktop-menu--open" : ""}`}
      >
        <div
          className="desktop-menu-item desktop-menu__logo"
          // onClick={() => setIsNavOpen(!isNavOpen)}
        >
          {/* <SVG type={SVG_TYPE.LOGO_WHITE} /> */}
        </div>
        {isAdmin ? (
          <>
            <NavLink
              to={ROUTE_ENUM.DASHBOARD_STATISTICS}
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.DASHBOARD} />
              <div className="desktop-menu-item__name">Dashboard</div>
            </NavLink>
            <NavLink to={ROUTE_ENUM.ORDERS_LIST} className="desktop-menu-item">
              <SVG type={SVG_TYPE.ORDERS_LIST} />
              <div className="desktop-menu-item__name">Lista zleceń</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.USERS}?page=1&location=1`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.USERS} />
              <div className="desktop-menu-item__name">Użytkownicy</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.EMPLOYEE_RATES}?page=1&tab=`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.EMPLOYEE_RATES} />
              <div className="desktop-menu-item__name">Stawki pracownicze</div>
            </NavLink>
            <NavLink
              to={ROUTE_ENUM.ASSEMBLY_MATERIAL}
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.ASSEMBLY_MATERIALS} />
              <div className="desktop-menu-item__name">Materiały montażowe</div>
            </NavLink>
            <NavLink
              to={ROUTE_ENUM.EMPLOYEE_CALENDAR}
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.CALENDAR} />
              <div className="desktop-menu-item__name">Kalendarz</div>
            </NavLink>
          </>
        ) : isAdvisor ? (
          <NavLink
            to={ROUTE_ENUM.EMPLOYEE_CALENDAR}
            className="desktop-menu-item"
          >
            <SVG type={SVG_TYPE.CALENDAR} />
            <div className="desktop-menu-item__name">Kalendarz</div>
          </NavLink>
        ) : (
          <>
            <NavLink to={ROUTE_ENUM.ORDERS_LIST} className="desktop-menu-item">
              <SVG type={SVG_TYPE.ORDERS_LIST} />
              <div className="desktop-menu-item__name">Lista zleceń</div>
            </NavLink>
            <NavLink
              to={
                isScheduleManager
                  ? ROUTE_ENUM.EMPLOYEE_CALENDAR
                  : ROUTE_ENUM.CALENDAR
              }
              className="desktop-menu-item"
            >
              <SVG type={SVG_TYPE.CALENDAR} />
              <div className="desktop-menu-item__name">Kalendarz</div>
            </NavLink>
          </>
        )}
        {!isAdmin && (
          <div
            onClick={() => handleLogout()}
            className={`desktop-menu-item desktop-menu-item--employee-logout`}
          >
            <SVG type={SVG_TYPE.LOGOUT} />
            <div className="desktop-menu-item__name">Wyloguj się</div>
          </div>
        )}
      </nav>
    </>
  );
};

export default DesktopMenu;
