/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetOrderDetails, useFinishInstallation } from "hooks/orders";
import { useGetMeasurements } from "hooks/measurements";
import { SVG_TYPE, ROUTE_ENUM, ORDER_ENUM, FILE_ENUM } from "enums";
import { Button, SVG, StaticAlert } from "components";
import { useNavigate } from "react-router";
import {
  CloseMeasurement,
  ActiveOrderDetails,
  Measurements,
  Verifications,
  AppointmentCannotHappen,
  ReceiptProtocolGenerator,
  InvoiceAndPaymentMethods,
  FollowUpMeeting,
  AdditionalInstallationVerification,
} from "features/orders";
import { UsedAssemblyMaterials } from "features/usedAssemblyMaterials";
import { Photos } from "features/Photos";
import "./active-order.scss";
import { ActivityTime } from "features/activity";
import { useGetMe } from "hooks/auth";
import { isMobile } from "utils";

const ActiveOrder = () => {
  const navigate = useNavigate();
  const { data: authorizedUser } = useGetMe();
  const userRole = authorizedUser && authorizedUser?.role;
  const [isCloseMeasurementOpen, setIsCloseMeasurementOpen] = useState(false);
  const [isErrorPhotos, setIsErrorPhotos] = useState<{
    isError: boolean;
    type: string | string[];
  }>({
    isError: false,
    type: "",
  });
  const [errorEndAppointment, setErrorEndAppointment] = useState({
    isError: false,
    errorMessage: "",
  });
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid") || "";
  const groupUuid = searchParams.get("groupUuid");
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}${
    groupUuid ? `&groupUuid=${groupUuid}` : ""
  }`;

  const { data: orderDetails } = useGetOrderDetails(orderUuid, true, true);
  const { data: measurements, isLoading } = useGetMeasurements(orderUuid);
  const { mutate: finishInstallation } = useFinishInstallation({
    onSuccess: () => {
      handleGoToOrdersList(true);
    },
  });

  useEffect(() => {
    const handlePopState = (event: any) => {
      window.history.pushState(null, "", window.location.href);
      handleGoToOrdersList();
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (isMobile()) {
      const alert = document.getElementsByClassName("static-alert--show")?.[0];
      if (alert) {
        alert.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [isErrorPhotos, errorEndAppointment]);

  const {
    FIXED_VERIFICATION,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
  } = ORDER_ENUM;
  const isAdditionalInstallation =
    orderDetails &&
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.length > 0 &&
    !groupUuid;
  const verifications = orderDetails?.status === FIXED_VERIFICATION;

  const {
    INSTALLATION_PHOTO,
    MEASUREMENTS_PHOTO,
    ADDITIONAL_VISIT_CURRENT_PHOTO,
    ADDITIONAL_VISIT_INSTALLATION_PHOTO,
  } = FILE_ENUM;
  const groupInstallation =
    orderDetails &&
    orderDetails.measurementsArrangedInstallations?.find(
      (installationGroup) => installationGroup.groupUuid === groupUuid
    );
  const isProtocolApproved = !!groupInstallation?.protocolApproved || false;

  useEffect(() => {
    if (isProtocolApproved) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  }, [orderDetails]);

  const fixedInstallation =
    (orderDetails?.status === INSTALLATION_TO_BE_RELEASED ||
      orderDetails?.status === FIXED_INSTALLATION ||
      orderDetails?.status === INSTALLATION_IN_PROGESS) &&
    !isAdditionalInstallation;
  const fixedMeasurement =
    orderDetails?.status === FIXED_MEASUREMENTS && !isAdditionalInstallation;

  const handleGoToOrdersList = (withoutUuids = false) => {
    if (withoutUuids) {
      navigate(`${ROUTE_ENUM.ORDERS_LIST}${page ? `?page=${page}` : ""}`);
    } else {
      navigate(`${ROUTE_ENUM.ORDERS_LIST}${orderDetailsUrl}`);
    }
  };

  const handleCloseMeasurementOrder = () => {
    const isAnyMeasurementIncomplete =
      measurements &&
      measurements.length > 0 &&
      measurements.some((item) => !item.isFinished);
    const isAnyMeasurementAvailable = measurements && measurements.length > 0;
    const isFile = orderDetails?.files.some(
      (file) => file.type === FILE_ENUM.MEASUREMENTS_PHOTO
    );
    if (!isFile) {
      setIsErrorPhotos({ isError: true, type: MEASUREMENTS_PHOTO });
    }
    if (!isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Aby zakończyć zlecenie musisz dodać przynajmniej jeden pomiar.",
      });
    } else if (isAnyMeasurementAvailable && isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz niedokończone pomiary. Uzupełnij brakujące dane, aby móc zakończyć zlecenie.",
      });
    } else if (
      isAnyMeasurementAvailable &&
      !isAnyMeasurementIncomplete &&
      isFile
    ) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
      setIsCloseMeasurementOpen(true);
    } else if (isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
    }
  };

  const handleCloseInstallation = () => {
    let isAdditionalCommentsRequired = false;
    const isFile = orderDetails?.files.filter(
      (file) => file.type === FILE_ENUM.INSTALLATION_PHOTO
    );
    const isGroupFile = isFile?.find(
      (item) => item.orderInstallationUuid === groupUuid
    );

    if (!!groupInstallation?.additionalVisitRequired) {
      const isRequired = ["", null].includes(
        groupInstallation?.additionalVisitComments
      );
      if (isRequired) {
        isAdditionalCommentsRequired = true;
      }
    }

    if (!!groupInstallation?.protocolApproved === false) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz niewygenerowany protokół odbioru. Wygeneruj dokument, aby móc zakończyć zlecenie.",
      });
    } else {
      setErrorEndAppointment({
        isError: false,
        errorMessage: "",
      });
      if (!isFile || !isGroupFile) {
        setIsErrorPhotos({ isError: true, type: INSTALLATION_PHOTO });
      } else {
        setIsErrorPhotos({ isError: false, type: "" });
        !isAdditionalCommentsRequired &&
          groupUuid &&
          finishInstallation({ groupUuid });
      }
    }
  };

  return (
    <>
      {orderDetails && (
        <>
          <CloseMeasurement
            data={{ label: orderDetails?.name, uuid: orderUuid }}
            isOpen={isCloseMeasurementOpen}
            onClose={() => setIsCloseMeasurementOpen(false)}
          />
          <div className="dashboard-tab-name dashboard-tab-name-with-back">
            <div
              className="dashboard-tab-name-with-back__icon"
              onClick={() => handleGoToOrdersList()}
            >
              <SVG type={SVG_TYPE.ARROW_BACK} />
            </div>
            {orderDetails?.name}
          </div>
          <div className="active-order__content">
            <div className="active-order__box active-order__box--left">
              <ActivityTime />

              {fixedInstallation && (
                <>
                  <UsedAssemblyMaterials orderDetails={orderDetails} />
                  <InvoiceAndPaymentMethods orderDetails={orderDetails} />
                  <Photos
                    userRole={userRole}
                    orderDetails={orderDetails}
                    type={MEASUREMENTS_PHOTO}
                    label="Zdjęcia z pomiaru"
                  />
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={INSTALLATION_PHOTO}
                    adding
                    label="Zdjęcia z montażu"
                  />
                  <StaticAlert
                    className="fixed-installation"
                    show={errorEndAppointment.isError}
                    label={errorEndAppointment.errorMessage}
                  />
                  <AppointmentCannotHappen
                    groupUuid={groupUuid}
                    uuid={orderUuid}
                    type="installation"
                    handleCloseOrder={handleCloseInstallation}
                  />
                </>
              )}
              {fixedMeasurement && (
                <Measurements
                  isLoading={isLoading}
                  errorEndMeasurement={errorEndAppointment}
                  measurements={measurements}
                />
              )}
              {fixedMeasurement && (
                <>
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={MEASUREMENTS_PHOTO}
                    label="Zdjęcia z pomiaru"
                    adding
                  />
                </>
              )}
              {isAdditionalInstallation && (
                <>
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={MEASUREMENTS_PHOTO}
                    label="Zdjęcia z pomiaru"
                  />
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={INSTALLATION_PHOTO}
                    label="Zdjęcia z montażu"
                  />
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={[
                      ADDITIONAL_VISIT_CURRENT_PHOTO,
                      ADDITIONAL_VISIT_INSTALLATION_PHOTO,
                    ]}
                    label="Zdjęcia z montażu uzupełniającego"
                    adding
                  />
                </>
              )}
              {fixedMeasurement && (
                <AppointmentCannotHappen
                  uuid={orderUuid}
                  type="measurement"
                  handleCloseOrder={handleCloseMeasurementOrder}
                />
              )}
              {isAdditionalInstallation && (
                <AdditionalInstallationVerification
                  uuid={orderUuid}
                  additionalVisitCheckList={
                    orderDetails.additionalVisitCheckList!
                  }
                  setIsErrorPhotos={setIsErrorPhotos}
                  orderDetails={orderDetails}
                />
              )}
              {verifications && (
                <Verifications
                  uuid={orderUuid}
                  verifications={orderDetails?.verificationParams}
                />
              )}
            </div>
            <div className="active-order--right">
              <ActiveOrderDetails
                isProtocolApproved={isProtocolApproved}
                isLoading={isLoading}
                orderDetails={orderDetails}
              />
              {fixedInstallation && groupInstallation && (
                <>
                  <ReceiptProtocolGenerator orderDetails={orderDetails} />
                  <FollowUpMeeting
                    groupInstallation={groupInstallation}
                    installation={groupInstallation}
                    groupUuid={groupUuid}
                  />
                </>
              )}
              <Button
                label="Zakończ zlecenie"
                className="active-order__details-button"
                onClick={() => handleCloseMeasurementOrder()}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActiveOrder;
