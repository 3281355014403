/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Input, StaticAlert } from "components";
import { Field } from "formik";

import "./hole-dimension.scss";

interface HoleDimensionType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
  valueFirst?: string;
  valueSecond?: string;
  aboveZero?: boolean;
  doorReplacement?: boolean;
  setFieldValue?: any;
}

const HoleDimension = ({
  errors,
  form,
  field,
  label,
  validateField,
  valueFirst,
  valueSecond,
  aboveZero = false,
  doorReplacement = false,
  setFieldValue,
}: HoleDimensionType) => {
  useEffect(() => {
    const {
      holeExternalWidth,
      holeInternalWidth,
      holeExternalHeight,
      holeInternalHeight,
    } = form.values;

    setTimeout(() => {
      if (holeExternalWidth !== undefined && holeInternalWidth !== undefined) {
        if (holeExternalWidth !== "" && holeInternalWidth !== "") {
          setFieldValue(
            "holeWidthDifference",
            holeExternalWidth - holeInternalWidth
          );
        } else {
          setFieldValue("holeWidthDifference", "");
        }
      }

      if (
        holeExternalHeight !== undefined &&
        holeInternalHeight !== undefined
      ) {
        if (holeExternalHeight !== "" && holeInternalHeight !== "") {
          setFieldValue(
            "holeHeightDifference",
            holeExternalHeight - holeInternalHeight
          );
        } else {
          setFieldValue("holeHeightDifference", "");
        }
      }
    }, 100);
  }, [
    form.values.holeExternalWidth,
    form.values.holeInternalWidth,
    form.values.holeExternalHeight,
    form.values.holeInternalHeight,
  ]);

  const handleDimensionChange = (e: any) => {
    const { name, value } = e.target;
    const numericValue = value ? parseFloat(value) || 0 : "";
    setFieldValue(name, numericValue);

    setTimeout(() => {
      const {
        holeExternalWidth,
        holeInternalWidth,
        holeExternalHeight,
        holeInternalHeight,
      } = form.values;

      if (
        (name === "holeExternalWidth" || name === "holeInternalWidth") &&
        holeExternalWidth !== undefined &&
        holeInternalWidth !== undefined
      ) {
        if (holeExternalWidth !== "" && holeInternalWidth !== "") {
          setFieldValue(
            "holeWidthDifference",
            holeExternalWidth - holeInternalWidth
          );
        } else {
          setFieldValue("holeWidthDifference", "");
        }
      }
      if (
        (name === "holeExternalHeight" || name === "holeInternalHeight") &&
        holeExternalHeight !== undefined &&
        holeInternalHeight !== undefined
      ) {
        if (holeExternalHeight !== "" && holeInternalHeight !== "") {
          setFieldValue(
            "holeHeightDifference",
            holeExternalHeight - holeInternalHeight
          );
        } else {
          setFieldValue("holeHeightDifference", "");
        }
      }
    }, 0);
  };

  return (
    <div className="hole-dimension">
      <div className="am-section-heading">{label}</div>

      {doorReplacement ? (
        <>
          <Field
            label="Szerokość zewnętrzna"
            name={`holeExternalWidth`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
            onChange={handleDimensionChange}
          />
          <Field
            label="Szerokosć wewnętrzna"
            name={`holeInternalWidth`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
            onChange={handleDimensionChange}
          />
          <Field
            label="Wysokość zewnętrzna"
            name={`holeExternalHeight`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
            onChange={handleDimensionChange}
          />
          <Field
            label="Wysokość wewnętrzna"
            name={`holeInternalHeight`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
            onChange={handleDimensionChange}
          />
          <div className="floor-area__ended">
            <Field
              disabled
              label="Różnica szerokości"
              name={`holeWidthDifference`}
              type="number"
              rightPlaceholder="mm"
              component={Input}
            />

            <Field
              disabled
              label="Różnica wysokości"
              name={`holeHeightDifference`}
              type="number"
              rightPlaceholder="mm"
              component={Input}
            />
          </div>
        </>
      ) : (
        <>
          <Field
            label="Szerokość"
            name={`${valueFirst ? valueFirst : "holeSizeWidth"}`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
          />
          <Field
            label="Wysokość"
            name={`${valueSecond ? valueSecond : "holeSizeHeight"}`}
            type="number"
            rightPlaceholder="mm"
            component={Input}
          />
        </>
      )}
      {aboveZero && (
        <Field
          label="Wysokość od poziomu zero"
          name="heightFromZeroLevel"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      )}

      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              doorReplacement
                ? errors["holeExternalWidth"] ||
                  errors["holeInternalWidth"] ||
                  errors["holeExternalHeight"] ||
                  errors["holeWidthDifference"] ||
                  errors["holeHeightDifference"]
                : valueFirst && valueSecond
                ? (errors[valueFirst] && errors[valueFirst]) ||
                  (errors[valueSecond] && errors[valueSecond])
                : (errors["holeSizeWidth"] && errors["holeSizeWidth"]) ||
                  (errors["holeSizeHeight"] && errors["holeSizeHeight"]) ||
                  (aboveZero &&
                    errors["heightFromZeroLevel"] &&
                    errors["heightFromZeroLevel"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <div className="am-separator" />
    </div>
  );
};

export default HoleDimension;
