import { Switch, TriState } from "components";
import "./extra-work.scss";

interface ExtraWorkType {
  form: any;
  values: any;
  externalDoor: boolean;
}

const ExtraWork = ({ form, values, externalDoor = false }: ExtraWorkType) => {
  return (
    <div className="extra-work">
      <div className="am-section-heading">Dodatkowe prace</div>
      {!externalDoor && (
        <>
          <div className="kwcc__switch">
            <label className="kwcc__label">
              Demontaż starych okien w całości
            </label>
            <Switch
              checked={values.oldWindowDismantlingInvestor}
              name="oldWindowDismantlingInvestor"
              form={form}
            />
          </div>
          <div className="kwcc__switch">
            <label className="kwcc__label">Zabezpieczenie podłogi/mebli</label>
            <TriState
              name="floorFurnitureProtection"
              first={{ name: "Inwestor", value: "investor" }}
              second={{ name: "Martdom", value: "martdom" }}
              third={{ name: "Nikt", value: "nobody" }}
              form={form}
            />
          </div>
        </>
      )}
      <div className="kwcc__switch">
        <label className="kwcc__label">
          Przygotowanie otworów pod montaż trójwarstwowy
        </label>
        <TriState
          name="preparationOfOpeningsForTripleLayerInstallation"
          first={{ name: "Inwestor", value: "investor" }}
          second={{ name: "Martdom", value: "martdom" }}
          third={{ name: "Nikt", value: "nobody" }}
          form={form}
        />
      </div>
      <div className="kwcc__switch">
        <label className="kwcc__label">Zmiana wymiaru otworów</label>
        <TriState
          name="changeOfOpeningDimensions"
          first={{ name: "Inwestor", value: "investor" }}
          second={{ name: "Martdom", value: "martdom" }}
          third={{ name: "Nikt", value: "nobody" }}
          form={form}
        />
      </div>
      <div className="kwcc__switch">
        <label className="kwcc__label">Uprzątnięcie budowy - INWESTOR</label>
        <Switch
          checked={values.constructionSiteCleanupInvestor}
          name="constructionSiteCleanupInvestor"
          form={form}
        />
      </div>
    </div>
  );
};

export default ExtraWork;
