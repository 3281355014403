import { Modal, Button } from "components";
import { useArchivedOrder } from "hooks/orders";

interface ArchiveOrderProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const ArchiveOrder = ({ isOpen, onClose, data }: ArchiveOrderProps) => {
  const { mutate: archiveOrder } = useArchivedOrder({
    onSuccess: (uuid: string) => {
      onClose();
    },
  });

  const handleSubmit = (data: any) => {
    archiveOrder({ uuid: data.uuid });
  };

  return (
    <>
      <Modal
        className="am-close-modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Archiwizacja zlecenia</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zarchiwizować <span>{data.name}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => handleSubmit(data)} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default ArchiveOrder;
