import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

// Typy danych
export type Measurement = {
  typeUuid: string;
  counter: number;
  numberOfAssemblyTeams: number;
  plannedAssemblyTime: number;
  numberOfInstallationCrews: number;
  date: {
    dateStart: string; 
    dateEnd: string;   
  };
  files?: File[];
};

export type OrderCreateData = {
  name: string;
  clientName: string;
  phonePrefixUuid: string;
  phoneNumber: number;
  email?: string;
  address: string;
  files?: File[];
  type: 1 | 2; // 1: "Pomiar + Montaż", 2: "Montaż"
  measurements?: Measurement[];
};


const usePostOrderCreate = (
  options?: UseMutationOptions<any, Error, OrderCreateData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['createOrder'],
    mutationFn: async (data: OrderCreateData) => {
      let payload: FormData | OrderCreateData;
      let headers = {};

      const formData = new FormData();

      formData.append('name', data.name);
      formData.append('clientName', data.clientName);
      formData.append('phonePrefixUuid', data.phonePrefixUuid);
      formData.append('phoneNumber', data.phoneNumber.toString());
      formData.append('address', data.address);
      if (data.email) formData.append('email', data.email);
      formData.append('type', data.type.toString());

      data.measurements?.forEach((measurement, index) => {
        formData.append(`measurements[${index}][typeUuid]`, measurement.typeUuid);
        formData.append(`measurements[${index}][counter]`, measurement.counter.toString());
        formData.append(`measurements[${index}][numberOfInstallationCrews]`, measurement.numberOfInstallationCrews.toString());
        formData.append(
          `measurements[${index}][numberOfAssemblyTeams]`,
          measurement.numberOfAssemblyTeams.toString()
        );
        formData.append(
          `measurements[${index}][plannedAssemblyTime]`,
          measurement.plannedAssemblyTime.toString()
        );
        formData.append(`measurements[${index}][dateStart]`, measurement.date.dateStart);
        formData.append(`measurements[${index}][dateEnd]`, measurement.date.dateEnd);

        measurement.files?.forEach((file, fileIndex) => {
          formData.append(
            `measurements[${index}][files][${fileIndex}]`,
            file
          );
        });
      });

      data.files?.forEach((file) => {
        if (file instanceof File) {
          formData.append('files', file);
        }
      });

      payload = formData;
      headers = { 'Content-Type': 'multipart/form-data' };

      return axiosInstance
        .post('/orders/create', payload, { headers })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Tworzenie zlecenia...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'create-order',
      });
      options?.onMutate?.(variables);
    },
    onSuccess: async (data, variables, context) => {
      Toast({
        message: 'Zlecenie zostało utworzone pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'create-order',
      });
      queryClient.invalidateQueries({ queryKey: ['scheduledOrders'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas tworzenia zlecenia.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'create-order',
      });
      options?.onError?.(error, variables, context);
    },
  });
};

export default usePostOrderCreate;
