import { Button, FileItem, SkeletonRow, StaticAlert, Input } from "components";
import { useRef, useState } from "react";
import { useUploadFile } from "hooks/utils";
import { useUpdateOrderPrice } from "hooks/orders";
import { OrderType } from "types/OrderTypes";
import { Formik, Field } from "formik";
import {
  FILE_ENUM,
  ORDER_ENUM,
  USER_ROLE,
  VALIDATION_MESSAGES,
  PAYMENT_METHOD_NAME,
} from "enums";
import { removeDecimalZeros, hasOrderStatus } from "utils";
import "./order-invoice.scss";
import * as Yup from "yup";

interface OrderInvoiceProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const OrderInvoice = ({ orderDetails, userRole }: OrderInvoiceProps) => {
  const [isEditPrice, setIsEditPrice] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isInvoiceError, setIsInvoiceError] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { files, status, price, paymentMethod } = orderDetails;

  const {
    INSTALLATION_IN_PROGESS,
    FIXED_INSTALLATION,
    INSTALLATION_TO_BE_RELEASED,
    INSTALLATION_DONE,
    ARCHIVED,
  } = ORDER_ENUM;
  const filteredFiles = files.find(
    ({ type }: { type: string }) => type === FILE_ENUM.ORDER_INVOICE
  );
  const isFileInvoice = filteredFiles && filteredFiles.url;
  const uploadFile = useUploadFile({
    onSuccess: () => {
      setSelectedFile(null);
    },
  });
  const updatePrice = useUpdateOrderPrice({
    onSuccess: () => {
      setIsEditPrice(false);
    },
  });

  const isScheduler = userRole === USER_ROLE.scheduleManager;
  const isAdmin = userRole === USER_ROLE.admin;
  const isActiveStatusForScheduler = hasOrderStatus(status, [
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
  ]);
  const isActiveForScheduler = isScheduler && isActiveStatusForScheduler;
  const isActiveForAdmin = isAdmin && isFileInvoice;
  const isAvailable = isActiveForAdmin || isActiveForScheduler;
  const isShowPaymentMethods =
    hasOrderStatus(status, [
      FIXED_INSTALLATION,
      INSTALLATION_IN_PROGESS,
      INSTALLATION_DONE,
      ARCHIVED,
    ]) && isAdmin;

  const handleAddInvoice = () => {
    fileInputRef.current?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE_MB = 10;
    const files = event.target.files ? Array.from(event.target.files) : [];
    const pdfFile = files.find((file) => file.type === "application/pdf");

    if (pdfFile) {
      const fileSizeMB = pdfFile.size / (1024 * 1024);

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        setIsInvoiceError(true);
        return;
      }

      setIsInvoiceError(false);
      setSelectedFile(pdfFile);

      uploadFile.mutate({
        parentUuid: orderDetails?.uuid || "",
        type: FILE_ENUM.ORDER_INVOICE,
        files: [pdfFile],
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setIsInvoiceError(true);
    }
  };

  const validationSchema = Yup.object().shape({
    price: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    updatePrice.mutate({
      uuid: orderDetails.uuid,
      price: values.price,
    });
  };

  return (
    <>
      {isAvailable && (
        <>
          {isActiveForScheduler && <div className="am-separator" />}
          <StaticAlert
            className="order-invoice__alert"
            show={isInvoiceError}
            label="Maksymalny rozmiar pliku 10MB"
          />
          <div className="modal__information-header">
            Faktura
            {isScheduler && !isFileInvoice && (
              <Button
                size="small"
                label={!selectedFile ? "Dodaj" : "Wgrywanie"}
                disabled={!!selectedFile}
                onClick={handleAddInvoice}
              />
            )}
          </div>
          {selectedFile && (
            <SkeletonRow className="order-invoice__skeleton" count={1} />
          )}
          {isFileInvoice && !selectedFile && (
            <div className="order-preview__information-file">
              <FileItem
                confirmation={{
                  isConfirmated: true,
                  text: "Czy jesteś pewien, że chcesz usunąć fakturę z tego zlecenia?",
                }}
                file={filteredFiles}
                isDeleteAvailable={isScheduler}
              />
            </div>
          )}
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="application/pdf"
        onChange={handleFileSelect}
      />
      {(isActiveForScheduler || isAdmin) && (
        <>
          {!isAdmin && <div className="am-separator" />}
          <Formik
            initialValues={{ price: removeDecimalZeros(price) }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  className="order-invoice__price-form"
                >
                  {price && !isEditPrice ? (
                    <>
                      <div className="modal__information-header">
                        Wartość zlecenia
                        {!isAdmin && (
                          <Button
                            label="Edytuj"
                            size="small"
                            onClick={() => setIsEditPrice(true)}
                          />
                        )}
                      </div>
                      <div className="modal__information-descript ">
                        <span className="order-invoice__price-bold">
                          {removeDecimalZeros(price)}
                        </span>{" "}
                        zł netto
                      </div>
                    </>
                  ) : (
                    <>
                      {!isAdmin && (
                        <div className="order-invoice__price">
                          <Field
                            label="Wartość zlecenia"
                            name="price"
                            maxLength={8}
                            withDot
                            rightPlaceholder="zł netto"
                            component={Input}
                            errors={errors}
                          />
                          <Button
                            label="Potwierdź"
                            size="small"
                            onClick={handleSubmit}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {isShowPaymentMethods && (
                    <>
                      <div className="modal__information-header">
                        Metoda płatności
                      </div>
                      <div className="modal__information-descript ">
                        {paymentMethod
                          ? PAYMENT_METHOD_NAME[paymentMethod]
                          : "-"}
                      </div>
                    </>
                  )}
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default OrderInvoice;
