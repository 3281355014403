import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import { useSearchParams } from "react-router-dom";

interface UpdateOrderStatusType {
  uuid: string;
  status: string;
  comment?: string;
}

const useUpdateOrderStatus = (
  options?: UseMutationOptions<any, Error, UpdateOrderStatusType>
) => {
    const [searchParams] = useSearchParams();
    const orderUuid = searchParams.get("orderUuid");
  return useMutation({
    ...options,
    mutationKey: ['updateOrderStatus'],
    mutationFn: async ({ uuid, status, comment }) => {
      return axiosInstance
        .patch(`/orders/schedule-manager/status/${uuid}`, { status, comment })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie aktualizacji statusu zlecenia...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-order-status',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: `Status zlecenia został zaktualizowany`,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-order-status',
      });
      queryClient.invalidateQueries({ queryKey: ['scheduledOrders'] });
      setTimeout(()=> {
        queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });
      },100)
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji statusu zlecenia',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-order-status',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateOrderStatus;
