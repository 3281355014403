/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { fabric } from "fabric";
import { SVG, Button } from "components";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import "./sketch.scss";
import { SVG_TYPE } from "enums";
import { base64ToFile } from "utils";

interface FewSketchesType {
  errors?: any;
  form: any;
  label: string;
  index: number;
  name: string;
}
const FewSketches = ({ form, label, index, name, errors }: FewSketchesType) => {
  const { editor, onReady } = useFabricJSEditor();
  const [text, setText] = useState("");
  const [textPart, setTextPart] = useState("");
  const isArrayField = name.includes(".");
  const dotIndex = name.indexOf(".");
  const elementIndex = name.substring(dotIndex + 1);
  const fieldName = name.substring(0, dotIndex);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isTextMode, setIsTextMode] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const sketchWrapperRef = useRef<HTMLDivElement>(null);
  const isActiveFile = form?.values?.file?.[index];
  // console.log(form?.values?.file?.[index]);
  function removeNonNumericChars(inputString: any) {
    return inputString.replace(/\D/g, "");
  }

  useEffect(() => {
    if (isArrayField) {
      const parts = name.split(".");
      if (parts.length > 2) {
        const dynamicTextPart = parts.slice(2).join(".");
        setTextPart(dynamicTextPart);
      }
    }
  }, [form.values[fieldName], form.values]);

  useEffect(() => {
    if (isActiveFile && isActiveFile.url != null) {
      form.setFieldValue(name, "updatedDrawings");
      const updatedDrawings = [...form.values.drawing];
      if (form.values.drawing[index] !== updatedDrawings[index]) {
        form.setFieldValue("drawing", updatedDrawings);
        form.setFieldValue("file", updatedDrawings);
        form.setFieldValue(name, "");
      }
    }
  }, [isActiveFile, index]);

  function showError() {
    if (isArrayField) {
      if (dotIndex !== -1) {
        if (elementIndex.includes(".")) {
          const afterRemoveString = removeNonNumericChars(elementIndex);

          const errorTextPart =
            errors[fieldName]?.[afterRemoveString]?.[textPart] || "";
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][afterRemoveString] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        } else {
          const errorTextPart =
            errors[fieldName]?.[elementIndex]?.[textPart] || "";

          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][elementIndex] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        }
      } else {
        return null;
      }
    }
  }

  const checkCanvasEmpty = useCallback(() => {
    if (editor && editor.canvas) {
      setIsCanvasEmpty(editor.canvas.getObjects().length === 0);
    }
  }, [editor]);

  const isAvailableDeleteElement = editor && editor.canvas.getActiveObject();

  useEffect(() => {
    const canvas = editor?.canvas;
    if (canvas) {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
      canvas.freeDrawingBrush.color = "black";
      canvas.freeDrawingBrush.width = 2;

      const saveCanvasState = () => {
        const json = canvas.toJSON();
        sessionStorage.setItem("canvasState", JSON.stringify(json));
        saveToImage();
        checkCanvasEmpty();
      };

      canvas.on("object:modified", saveCanvasState);
      canvas.on("object:added", saveCanvasState);
      canvas.on("object:removed", saveCanvasState);
      canvas.on("path:created", saveCanvasState);

      return () => {
        canvas.off("object:modified", saveCanvasState);
        canvas.off("object:added", saveCanvasState);
        canvas.off("object:removed", saveCanvasState);
        canvas.off("path:created", saveCanvasState);
      };
    }
  }, [editor, checkCanvasEmpty]);

  const onAddText = () => {
    setIsTextMode(false);
    if (editor && text) {
      const textObject = new fabric.IText(text, {
        left: 100,
        top: 100,
        fontFamily: "Arial",
        fontSize: 20,
        fill: "#000000",
      });
      editor.canvas.add(textObject);
      setText("");
      checkCanvasEmpty();
    }
  };

  const onClearCanvas = () => {
    if (editor) {
      editor.canvas.clear();
      const updatedDrawings = [...form.values.drawing];
      updatedDrawings[index] = "";
      form.setFieldValue("drawing", updatedDrawings);
      form.setFieldValue(name, "updatedDrawings");
      checkCanvasEmpty();
    }
  };

  const toggleDrawingMode = () => {
    setIsTextMode(false);
    if (editor) {
      const newIsDrawingMode = !isDrawingMode;
      editor.canvas.isDrawingMode = newIsDrawingMode;
      setIsDrawingMode(newIsDrawingMode);
    }
  };

  const toggleTextMode = () => {
    if (isDrawingMode) {
      toggleDrawingMode();
    }
    setIsTextMode(!isTextMode);
  };

  const onDeleteSelected = () => {
    const activeObjects = editor?.canvas?.getActiveObjects();
    if (editor && activeObjects && activeObjects.length) {
      activeObjects.forEach((object) => editor.canvas.remove(object));
      editor.canvas.discardActiveObject();
      editor.canvas.requestRenderAll();
      checkCanvasEmpty();
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const saveToImage = useCallback(() => {
    if (editor && editor.canvas) {
      editor.canvas.setBackgroundColor(
        "white",
        editor.canvas.renderAll.bind(editor.canvas)
      );
      const dataURL = editor.canvas.toDataURL();
      const file = base64ToFile(dataURL, "canvas-image.png", "image/png");

      if (form.values.drawing[index] !== file) {
        const updatedDrawings = [...form.values.drawing];
        updatedDrawings[index] = file;
        form.setFieldValue("drawing", updatedDrawings);
        form.setFieldValue(name, "updatedDrawings");
      }
    }
  }, [editor, form.values.drawing, index, form]);

  useEffect(() => {
    if (isCanvasEmpty && form.values?.file?.length === 0) {
      const updatedDrawings = [...form.values.drawing];
      updatedDrawings[index] = "";
      form.setFieldValue("drawing", updatedDrawings);
      form.setFieldValue(name, "");
    }
  }, [isCanvasEmpty, index]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sketchWrapperRef.current &&
        !sketchWrapperRef.current.contains(event.target as Node)
      ) {
        setIsTextMode(false);
        setIsDrawingMode(false);
        if (editor && editor.canvas) {
          editor.canvas.isDrawingMode = false;
          editor.canvas.discardActiveObject();
          editor.canvas.requestRenderAll();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editor]);

  return (
    <div>
      <div className="am-section-heading">{label}</div>

      <div className="sketch__wrapper" ref={sketchWrapperRef}>
        {isActiveFile && isActiveFile.url ? (
          <div className="sketch__image-wrapper">
            <FabricJSCanvas className="sketch__canvas" onReady={onReady} />
            <div className="sketch__image-delete">
              <button
                type="button"
                className="sketch__button sketch__button--trash"
                onClick={() => {
                  const updatedDrawings = [...form.values.drawing];
                  updatedDrawings[index] = null;
                  form.setFieldValue("file", updatedDrawings);
                  form.setFieldValue("drawing", updatedDrawings);
                  form.setFieldValue(name, "");
                }}
              >
                <SVG type={SVG_TYPE.TRASH} />
              </button>
            </div>
            <img src={isActiveFile?.url} alt="" />
          </div>
        ) : (
          <>
            <div className="sketch__buttons">
              <button
                type="button"
                className={`sketch__button ${
                  isDrawingMode ? "sketch__button--active" : ""
                }`}
                onClick={toggleDrawingMode}
              >
                <SVG type={SVG_TYPE.EDIT} />
              </button>
              <button
                type="button"
                className={`sketch__button ${
                  isTextMode ? "sketch__button--active" : ""
                }`}
                onClick={toggleTextMode}
              >
                <SVG type={SVG_TYPE.TEXT} />
              </button>

              {isTextMode && (
                <>
                  <input type="text" value={text} onChange={handleTextChange} />
                  <Button size="small" label="dodaj" onClick={onAddText} />
                </>
              )}

              <div className="sketch__button--right">
                {isAvailableDeleteElement && (
                  <button
                    type="button"
                    className="sketch__button sketch__button--eraser"
                    onClick={onDeleteSelected}
                  >
                    <SVG type={SVG_TYPE.ERASER} />
                  </button>
                )}
                {!isCanvasEmpty && (
                  <button
                    type="button"
                    className="sketch__button sketch__button--trash"
                    onClick={onClearCanvas}
                    disabled={isCanvasEmpty}
                  >
                    <SVG type={SVG_TYPE.TRASH} />
                  </button>
                )}
              </div>
            </div>
            <FabricJSCanvas className="sketch__canvas" onReady={onReady} />
          </>
        )}
      </div>
      {showError()}
    </div>
  );
};

export default FewSketches;
