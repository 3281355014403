import { useState, useEffect } from "react";
import { StaticAlert, CheckboxPart, Textarea } from "components";
import { useWindowAddition } from "hooks/measurements";
import { InstallationType } from "types/MeasurementTypes";
import { Field } from "formik";
import "./window-accessories.scss";

interface WindowAccessoriesType {
  field: any;
  errors?: any;
  form: any;
  touched?: any;
  values?: any;
  typeOfMeasuementUuid?: string;
}

const WindowAccessories = ({
  errors,
  form,
  field,
  values,
  typeOfMeasuementUuid = undefined,
}: WindowAccessoriesType) => {
  const { data: windowAdditionsLabel } =
    useWindowAddition(typeOfMeasuementUuid);
  const [windowAdditionUuids, setWindowAdditionUuids] = useState<any>(
    values?.windowAdditionUuids || []
  );

  const otherElementUuid =
    windowAdditionsLabel &&
    windowAdditionsLabel.length > 0 &&
    windowAdditionsLabel?.find((item: any) => item.type === "other")?.value;

  useEffect(() => {
    setWindowAdditionUuids(values.windowAdditionUuids);
  }, [values?.windowAdditionUuids]);

  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = windowAdditionUuids.find(
      (item: any) => item === uuid || item.uuid === uuid
    );

    const commentItem =
      windowAdditionsLabel &&
      windowAdditionsLabel.find(
        (item: any) => item.value === uuid && item.type === "other"
      );

    if (!isUuidInArray) {
      const values = [
        ...windowAdditionUuids,
        commentItem
          ? { comment: form.values.commentsWindowAddition || "", uuid }
          : uuid,
      ];
      setWindowAdditionUuids(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = windowAdditionUuids.filter(
        (item: any) => item !== uuid && item.uuid !== uuid
      );
      setWindowAdditionUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  const handleWriteComment = (e: any) => {
    const commentItem =
      windowAdditionsLabel &&
      windowAdditionsLabel.find((item: any) => item.type === "other");
    const isCommentInArray = windowAdditionUuids.find(
      (item: any) => item.comment
    );
    if (commentItem && !isCommentInArray) {
      const values = [
        ...windowAdditionUuids,
        { comment: e, uuid: commentItem?.value },
      ];
      setWindowAdditionUuids(values);
      form.setFieldValue(field.name, values);
    } else if (commentItem && isCommentInArray) {
      const newWindowAddition = windowAdditionUuids.filter(
        (item: any) => item.uuid !== commentItem?.value
      );
      const values = [
        ...newWindowAddition,
        { comment: e, uuid: commentItem?.value },
      ];
      setWindowAdditionUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  const isCommentAvailable = windowAdditionUuids.find(
    (item: any) => item.uuid === otherElementUuid
  );

  return (
    <div className="window-accessories">
      <div className="am-section-heading">
        Dodatki do okna <span> (opcjonalne)</span>
      </div>
      <div className="am-section-checkbox-list construction-site__list">
        {windowAdditionsLabel &&
          windowAdditionsLabel.length > 0 &&
          windowAdditionsLabel.map((item: InstallationType) => {
            if (item.type !== "comments") {
              return (
                <CheckboxPart
                  checked={values?.windowAdditionUuids.find(
                    (uuid: any) =>
                      uuid === item.value || uuid.uuid === item.value
                  )}
                  key={item.value}
                  label={item.label}
                  uuid={item.value}
                  onChange={(uuid) => handleClickCheckbox(uuid)}
                />
              );
            }
            return false;
          })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      {isCommentAvailable && (
        <Field
          values={isCommentAvailable?.comment}
          name="commentsWindowAddition"
          component={Textarea}
          onChange={(e: any) => handleWriteComment(e)}
          maxLength={250}
        />
      )}
      <div className="am-separator" />
    </div>
  );
};

export default WindowAccessories;
