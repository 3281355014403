/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import {
  InstallationPlanning,
  Button,
  StaticAlert,
  InstallationArranging,
  SVG,
  Textarea,
} from "components";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { measurementAllInstallationType } from "types/OrderTypes";
import {
  SVG_TYPE,
  VALIDATION_MESSAGES,
  MEASUREMENTS_TYPE_TRANSLATIONS,
  ROUTE_ENUM,
} from "enums";
import {
  useAddInstallationOrder,
  useUpdateInstallationOrder,
} from "hooks/orders";
import { useNavigate } from "react-router";
import "./installations-form.scss";
import { MeasurementsArrangedInstallationsType } from "types/OrderTypes";

interface installationsFormType {
  orderDetails: any;
  isEdit: boolean;
}

const InstallationsForm = ({ orderDetails, isEdit }: installationsFormType) => {
  const navigate = useNavigate();
  const [toDetermine, setToDetermine] = useState<any[]>();
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);

  const [step, setStep] = useState(1);
  const uuid = searchParams.get("uuid");
  const groupUuid = searchParams.get("groupUuid");
  const [initialValues, setInitialValues] = useState<any>({
    installations: [],
    uuid,
    comment: "",
  });

  const handleOnSubmit = (values: any) => {
    const newDates = {
      date: "",
      teams: [{ employeeUuids: [], start: "", end: "" }],
    };

    if (
      step === 1 &&
      values.installations.length > 0 &&
      values.installations[0].measurementNames.length > 0
    ) {
      const updatedInstallations = values.installations.map(
        (installation: any) => {
          return {
            ...installation,
            dates:
              installation.dates && installation.dates.length > 0
                ? installation.dates
                : [newDates],
          };
        }
      );

      setInitialValues({ ...values, installations: updatedInstallations });
      setStep(2);
      setShowError(false);
    } else if (step === 1) {
      setShowError(true);
    }
    if (step === 2) {
      isEdit
        ? updatedInstallation.mutate(values.installations[0])
        : addInstallation.mutate({ uuid, ...values });
    }
  };

  const addInstallation = useAddInstallationOrder({
    onSuccess: async (response) => {
      const { page } = response;
      navigate(
        `${ROUTE_ENUM.ORDERS_LIST}?tab=fixedOrders&page=${page}&orderUuid=${uuid}`
      );
    },
  });

  const updatedInstallation = useUpdateInstallationOrder({
    onSuccess: async (response) => {
      const { page } = response;
      navigate(
        `${ROUTE_ENUM.ORDERS_LIST}?tab=fixedOrders&page=${page}&orderUuid=${uuid}`
      );
    },
  });

  useEffect(() => {
    if (
      isEdit &&
      orderDetails &&
      orderDetails.measurementsArrangedInstallations.length > 0 &&
      groupUuid &&
      !initialValues?.installations[0]
    ) {
      const filteredInstallations = arrangedInstallation
        .filter((item: any) => item.groupUuid === groupUuid)
        .map((installation: any) => ({
          ...installation,
          measurementNames: installation.measurements.map(
            (measurement: any) => measurement.type
          ),
          comment: installation.schedulerComments,
          dates: installation.dates.map((date: any) => ({
            ...date,
            teams: date.teams.map((team: any) => ({
              ...team,
              employeeUuids: team.employees.map(
                (employee: any) => employee.uuid
              ),
            })),
          })),
        }));

      setInitialValues({
        uuid,
        installations: filteredInstallations,
      });
    } else {
      setInitialValues({
        ...initialValues,
        installations: [
          {
            measurementNames: [],
            dates: [],
          },
        ],
      });
    }
  }, [orderDetails, isEdit, uuid]);

  const InstallationPlanningSchema = Yup.object().shape({
    installations: Yup.array().of(
      Yup.object().shape({
        dates: Yup.array().of(
          Yup.object().shape({
            date: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .nullable(),
            teams: Yup.array().of(
              Yup.object().shape({
                start: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                end: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                employeeUuids: Yup.array()
                  .of(Yup.string().required())
                  .required(VALIDATION_MESSAGES.REQUIRED)
                  .min(1, VALIDATION_MESSAGES.REQUIRED),
              })
            ),
          })
        ),
      })
    ),
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const processData = (
    arrangedInstallation: MeasurementsArrangedInstallationsType[]
  ) => {
    const filteredInstallations = arrangedInstallation.filter(
      (item) => item.groupUuid === groupUuid
    );
    const result = filteredInstallations.flatMap((item) => {
      return item.measurements.map((measurement) => {
        return {
          type: measurement.type,
          counter: measurement.counter,
          numberOfAssemblyTeams: measurement.numberOfAssemblyTeams,
          plannedAssemblyTime: measurement.plannedAssemblyTime,
        };
      });
    });

    return result;
  };

  const initialEdtiMeasurement = (
    arrangedInstallation: MeasurementsArrangedInstallationsType[]
  ): string[] => {
    const filteredInstallations = arrangedInstallation.filter(
      (item) => item.groupUuid === groupUuid
    );
    return filteredInstallations.flatMap((item) =>
      item.measurements.map((measurement) => measurement.type)
    );
  };

  let ordersDetailsMeasurements = orderDetails?.measurementsToBeDetermined;
  const arrangedInstallation = orderDetails.measurementsArrangedInstallations;
  const isArrangedInstallationActive =
    !isEdit && step === 1 && arrangedInstallation.length > 0;

  useEffect(() => {
    if (isEdit && orderDetails) {
      setToDetermine([
        ...ordersDetailsMeasurements,
        ...processData(arrangedInstallation),
      ]);
    } else {
      setToDetermine(ordersDetailsMeasurements);
    }
  }, [isEdit, orderDetails]);

  function formatDateRange(initialDate: { start: string; end: string }) {
    const { start, end } = initialDate;

    const [startDay, startMonth, startYear] = start.trim().split("/");
    const [endDay, endMonth, endYear] = end.trim().split("/");

    if (start === end) {
      return start;
    } else if (startMonth === endMonth && startYear === endYear) {
      return `${startDay}-${endDay}/${startMonth}/${startYear}`;
    } else if (startYear === endYear) {
      return `${startDay}/${startMonth} - ${endDay}/${endMonth} ${startYear}`;
    } else {
      return `${start} - ${end}`;
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={step === 2 && InstallationPlanningSchema}
        onSubmit={async (values) => handleOnSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors, values, validateField }) => {
          function filterMeasurements(toDetermine: any, measurementNames: any) {
            if (toDetermine && measurementNames) {
              return toDetermine.filter((item: any) =>
                measurementNames.includes(item.type)
              );
            } else return [];
          }

          return (
            <form className="installation-planning__form">
              <div className="installation-planning__steps__content">
                <div className="installation-planning__steps">
                  {step === 2 && (
                    <span onClick={() => setStep(1)}>
                      <SVG type={SVG_TYPE.ARROW_BACK} />
                    </span>
                  )}
                  Krok {step}
                  <span>/2</span>
                </div>
                <div className="installation-planning__steps__paragraph">
                  {step === 1
                    ? "Wybierz typy montaży, które chcesz umówić."
                    : "Przypisz pracowników i terminy."}
                </div>
              </div>
              <div className="modal__information-header">
                Montaż do ustalenia
              </div>
              <div className="installation-planning__wrapper">
                {step === 2 &&
                  filterMeasurements(
                    toDetermine,
                    values?.installations[0]?.measurementNames
                  ).length > 0 &&
                  filterMeasurements(
                    toDetermine,
                    values?.installations[0]?.measurementNames
                  ).map((details: measurementAllInstallationType) => {
                    return (
                      <div className="installation-planning">
                        <div className="installation-planning__top">
                          <div className="installation-planning__left">
                            <div className="installation-planning__label">
                              <span>{details.counter}</span>
                              {MEASUREMENTS_TYPE_TRANSLATIONS[details.type]}
                            </div>
                            <div className="installation-planning__numbers">
                              <div className="installation-planning__number-item">
                                <SVG type={SVG_TYPE.CLOCK} />{" "}
                                {details.plannedAssemblyTime}h
                              </div>
                              <div className="installation-planning__number-item">
                                <SVG type={SVG_TYPE.USER} />{" "}
                                {details.numberOfAssemblyTeams}
                              </div>
                              {details.initialDate
                                ?.numberOfInstallationCrews && (
                                <div className="installation-planning__number-item">
                                  <SVG type={SVG_TYPE.USERS_BOLD} />{" "}
                                  {
                                    details.initialDate
                                      .numberOfInstallationCrews
                                  }
                                </div>
                              )}
                              {details.initialDate && (
                                <div className="installation-planning__initial-date">
                                  <SVG type={SVG_TYPE.CALENDAR_SECOND} />{" "}
                                  {formatDateRange(details.initialDate)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {toDetermine &&
                  toDetermine.length > 0 &&
                  toDetermine.map(
                    (
                      details: measurementAllInstallationType,
                      index: number
                    ) => {
                      return (
                        <>
                          <InstallationPlanning
                            toDetermine={toDetermine}
                            setShowError={setShowError}
                            step={step}
                            installationIndex={index}
                            key={`${details.plannedAssemblyTime}--${index}`}
                            details={details}
                            errors={errors}
                            initialValues={values}
                            setInitialValues={setInitialValues}
                            validateField={validateField}
                            initialChecked={initialEdtiMeasurement(
                              arrangedInstallation
                            )}
                          />
                        </>
                      );
                    }
                  )}
                {step === 2 && (
                  <Field
                    className="installation-planning--comment"
                    errors={errors}
                    label="Dodatkowe uwagi"
                    name="installations.[0].comment"
                    optional
                    component={Textarea}
                    maxLength={250}
                  />
                )}
              </div>
              <StaticAlert
                show={showError}
                label="Wybierz montaż do ustalenia, aby przejść do następnego kroku."
              />
              {isArrangedInstallationActive && (
                <>
                  <div className="installation-planning__wrapper installation-arranging__wrapper">
                    <div className="modal__information-header">
                      Ustalone montaże
                    </div>
                    {arrangedInstallation.map((installation: any) => {
                      return (
                        <InstallationArranging installation={installation} />
                      );
                    })}
                  </div>
                </>
              )}

              <div className="modal-buttons aap__buttons">
                <Button stroke onClick={handleGoBack} label="Anuluj" />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  label={step === 1 ? "Następny krok" : "Zapisz"}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default InstallationsForm;
