import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type ExternalDoorReplacementClientInfoData = {
  measurementUuid: string;
  typeOfInstallations:
    | 'installationPreparedHoles'
    | 'disassemblyAssembly'
    | 'disassemblyAssemblyPlaster'
    | 'disassemblyAssemblyGK';
  spoutLevel: 'actual' | 'specifiedByInvestor' | 'specifiedByContractor';
  oldDoorType: 'metal' | 'woodem' | 'pcv' | 'aluminum';
  oldDoorDismantling: boolean;
  floorFurnitureProtection: 'investor' | 'martdom' | 'none';
  constructionSiteCleaningInvestor: boolean;
  comments?: string;
};

const usePutExternalDoorReplacementClientInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorReplacementClientInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsClientInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/external-doors-replacement/client-info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych drzwi...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane drzwi zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-client-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsClientInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych drzwi.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorReplacementClientInfo;
