import { useState } from "react";
import "./scheduler-calendar.scss";
import { Calendar, MultipleSelect } from "components";
import moment from "moment";
import { Field, Formik } from "formik";
import { USER_ROLE } from "enums";
import { useGetFitterAndMeasurer } from "hooks/users";
import { useGetEmployeeSchedule } from "hooks/orders";
import { convertToEvents } from "utils";
import { useGetMe } from "hooks/auth";

const CalendarOfAllEmployees = () => {
  const { data: authorizedUser } = useGetMe();
  const today = moment().format("YYYY-MM-DD");
  const [usersTable, setUsersTable] = useState<any>(["all"]);
  const [userRole, setUserRole] = useState<any>(["all"]);
  const [mobileDate, setMobileDate] = useState<any>();
  const [date, setDate] = useState(today);
  const month = parseInt(date.split("-")[1]) || 5;
  const year = parseInt(date.split("-")[0]);
  const user = authorizedUser;

  const { data: employee } = useGetFitterAndMeasurer(
    userRole === USER_ROLE.fitter ? true : false
  );
  const { data } = useGetEmployeeSchedule(usersTable, month, year, userRole);

  const usersSelect = employee && [
    { label: "Wszyscy pracownicy", value: "all" },
    ...employee,
  ];
  const orderSelect = [
    {
      label: "Wszystkie zlecenia",
      value: "all",
    },
    {
      label: "Pomiary",
      value: "measurements",
    },
    {
      label: "Weryfikacje",
      value: "verifications",
    },
    {
      label: "Montaże",
      value: "installations",
    },
  ];

  const handleChangeOrder = (e: Array<{ label: string; value: string }>) => {
    const userRole = e.map((user: any) => user.value);
    setUserRole(userRole);
  };

  const handleChangeUser = (e: Array<{ label: string; value: string }>) => {
    const usersTable = e.map((user: any) => user.value);
    setUsersTable(usersTable);
  };
  return (
    <div className="scheduler-calendar">
      <Formik
        initialValues={{
          order: orderSelect ? orderSelect[0].value : "",
          userUuid: usersSelect ? usersSelect[0].value : "",
        }}
        enableReinitialize
        onSubmit={(values) => console.log(values)}
      >
        {({ handleSubmit, errors }) => {
          return (
            <form
              className="scheduler-calendar__selects"
              onSubmit={handleSubmit}
            >
              <Field
                errors={errors}
                label="Zlecenia"
                name="order"
                component={MultipleSelect}
                onChange={(e: any) => handleChangeOrder(e)}
                options={orderSelect}
                size="medium"
                employee
                noIcon
                selectAll
                multiple
                white
              />
              {usersSelect && employee && (
                <Field
                  errors={errors}
                  label="Pracownicy"
                  name="userUuid"
                  component={MultipleSelect}
                  onChange={(e: any) => handleChangeUser(e)}
                  options={usersSelect}
                  size="medium"
                  employee
                  selectAll
                  multiple
                  white
                />
              )}
            </form>
          );
        }}
      </Formik>
      <Calendar
        employeeCalendar
        user={user}
        events={data && convertToEvents(data)}
        date={date}
        setDate={setDate}
        main
        mobileDate={mobileDate}
        setMobileDate={setMobileDate}
        size="100%"
      />
    </div>
  );
};

export default CalendarOfAllEmployees;
