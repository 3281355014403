import { ContextMenu, SVG, UserBadge } from "components";
import { SVG_TYPE } from "enums";
import { RateInfoType } from "types/EmployeeRatesTypes";

const items = (
  setEditUserRate: (userRateInfo: RateInfoType) => void,
  setShowRateHistory: (userRateInfo: RateInfoType) => void
) => {
  return (userRateInfo: RateInfoType) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => setEditUserRate(userRateInfo),
      },
      {
        name: "Historia stawek",
        action: () => setShowRateHistory(userRateInfo),
      },
    ];
    return actions;
  };
};

export const mobileColumns = () => [
  {
    width: 500,
    render: (values: any) => (
      <div className="employee-rates-table__mobile-name">
        {`${values.firstName} ${values.lastName}`}
      </div>
    ),
  },
  {
    key: "rate",
    dataIndex: "rate",
    width: 300,
    render: (value: any) => (
      <div className="employee-rates-table__mobile-rate">{`${value} zł/h netto`}</div>
    ),
  },
  {
    dataIndex: "role",
    key: "role",
    width: 40,
    render: (value: any) => <UserBadge role={value} />,
  },
];

export const desktopColumns = (
  currentPage: number,
  perPage: number,
  setEditUserRate: (userRateInfo: RateInfoType) => void,
  setShowRateHistory: (userRateInfo: RateInfoType) => void
) => [
  {
    title: "Lp.",
    key: "index",
    width: 68,
    render: (value: any, record: any, index: number) =>
      (currentPage - 1) * perPage + index + 1,
  },
  {
    title: "Imię",
    key: "firstName",
    dataIndex: "firstName",
    width: 215,
    render: (value: any) => {
      if (value.length > 12) {
        return `${value.slice(0, 12)}...`;
      }
      return value;
    },
  },
  {
    title: "Nazwisko",
    key: "lastName",
    dataIndex: "lastName",
    width: 160,
    render: (value: any) => {
      if (value.length > 12) {
        return `${value.slice(0, 12)}...`;
      }
      return value;
    },
  },
  {
    title: "Stanowisko",
    dataIndex: "role",
    key: "role",
    width: 215,
    render: (value: any) => <UserBadge role={value} />,
  },
  {
    title: "Lokalizacja",
    dataIndex: "location",
    key: "location",
    width: 187,
  },
  {
    title: "Aktualna stawka",
    width: 350,
    render: (value: any) => {
      return (
        <div className="employee-rates-table__rate-wrapper">
          <div className="employee-rates-table__rate">
            {`${value.rate} zł/h netto`}{" "}
            <div className="employee-rates-table__rate-change">
              {`${
                value.plannedRate
                  ? `Zmiana od: ${value.plannedRate.replace(/\//g, ".")}`
                  : " "
              }`}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    title: "Akcje",
    width: 88,
    render: (item: RateInfoType) => (
      <ContextMenu
        data={item}
        items={items(setEditUserRate, setShowRateHistory)(item)}
      >
        <SVG type={SVG_TYPE.ELLIPSIS} />
      </ContextMenu>
    ),
  },
];
