/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Select, Button } from "components";
import { Field, Formik } from "formik";
import { SingleSectionElement } from "features/addingMeasurement";
import {
  SwingGateForm,
  TiltingGateForm,
  SectionalGateForm,
  RollerGateForm,
} from "features/addingMeasurement";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import * as Yup from "yup";
import { ScrollToError } from "utils";
import {
  useStartMeasurement,
  usePutGarageDoorsInfo,
  useGetMeaasurementInfo,
} from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { handleMeasurementSuccess } from "features/addingMeasurement/utils";

interface GarageDoorMeasurementValuesType {
  measurementUuid: string | null;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const GarageDoorMeasurementValues = ({
  measurementUuid,
  setStep,
  step,
  prevStep,
}: GarageDoorMeasurementValuesType) => {
  const navigate = useNavigate();
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [typeOfGate, setTypeOfGate] = useState("");
  const [initialValue, setInitialValue] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const { data: measurementInfo } = useGetMeaasurementInfo(measurementUuid);
  const [isAddNew, setIsAddNew] = useState(false);

  useEffect(() => {
    if (measurementInfo.measurementInfo) {
      setInitialValue(measurementInfo.measurementInfo);
    }
  }, [measurementInfo]);

  useEffect(() => {
    if (!typeOfGate) {
      setTypeOfGate(initialValue?.typeOfGate);
    }
  }, [initialValue]);

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      handleMeasurementSuccess(data, searchParams, setSearchParams, setStep);
    },
  });

  const { mutate: updateGarageDoorInfo } = usePutGarageDoorsInfo({
    onSuccess: (data) => {
      if (isAddNew) {
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const handleOnSubmit = async (values: any) => {
    await updateGarageDoorInfo(values);
  };

  enum GARAGE_DOOR_TYPE {
    SWING_GATE = "swingGate",
    TILTING_GATE = "tiltingGate",
    SECTIONAL_GATE = "sectionalGate",
    ROLLER_GATE = "rollerGate",
  }

  const typeOfGarageDoor = [
    {
      value: GARAGE_DOOR_TYPE.SWING_GATE,
      label: "Brama rozwierna",
    },
    {
      value: GARAGE_DOOR_TYPE.TILTING_GATE,
      label: "Brama uchylna",
    },
    {
      value: GARAGE_DOOR_TYPE.SECTIONAL_GATE,
      label: "Brama segmentowa",
    },
    {
      value: GARAGE_DOOR_TYPE.ROLLER_GATE,
      label: "Brama roletowa",
    },
  ];

  const handleSetTypeOfGate = (e: { value: string; label: string }) => {
    setTypeOfGate(e.value);
  };

  const handleShowForm = (
    submitCount: any,
    type: string,
    touched: any,
    errors: any,
    validateField: any,
    values: any,
    setFieldValue: any,
    setFieldError: any,
    handleCheckValidateDimensions: any
  ) => {
    switch (type) {
      case GARAGE_DOOR_TYPE.SWING_GATE:
        return (
          <SwingGateForm
            values={values}
            validateField={validateField}
            touched={touched}
            errors={errors}
            isErrorDimension={isErrorDimension}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
          />
        );
      case GARAGE_DOOR_TYPE.TILTING_GATE:
        return (
          <TiltingGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
            values={values}
            isErrorDimension={isErrorDimension}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
          />
        );
      case GARAGE_DOOR_TYPE.SECTIONAL_GATE:
        return (
          <SectionalGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
            values={values}
            isErrorDimension={isErrorDimension}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
          />
        );
      case GARAGE_DOOR_TYPE.ROLLER_GATE:
        return (
          <RollerGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
            values={values}
          />
        );
      default: {
        return <div>zobacz sam jak gumisie skacz</div>;
      }
    }
  };

  const getValidationSchema = (typeOfGate: string) => {
    switch (typeOfGate) {
      case GARAGE_DOOR_TYPE.SWING_GATE:
        return Yup.object().shape({
          typeOfGate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          methodOfOpening: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateDivision: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          activeWing: Yup.string().test(
            "is-required-when-asymmetrical",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { gateDivision } = this.parent;
              if (gateDivision === "asymmetrical") {
                return !!value;
              }
              return true;
            }
          ),
          passiveWing: Yup.string().test(
            "is-required-when-asymmetrical",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { gateDivision } = this.parent;
              if (gateDivision === "asymmetrical") {
                return !!value;
              }
              return true;
            }
          ),
          warningComment: isErrorDimension
            ? Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
            : Yup.string(),
        });
      case GARAGE_DOOR_TYPE.TILTING_GATE:
        return Yup.object().shape({
          typeOfGate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          methodOfOpeningManualAutomatic: Yup.string().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          typeOfRail: Yup.string().test(
            "is-required-when-automatic",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { methodOfOpeningManualAutomatic } = this.parent;
              if (methodOfOpeningManualAutomatic === "automatic") {
                return !!value;
              }
              return true;
            }
          ),
          gateType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        });
      case GARAGE_DOOR_TYPE.SECTIONAL_GATE:
        return Yup.object().shape({
          typeOfGate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          styrofoamThickness: Yup.string().test(
            "is-required-when-automatic",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { styrofoamPlannedForTheCeiling } = this.parent;
              if (styrofoamPlannedForTheCeiling) {
                return !!value;
              }
              return true;
            }
          ),
          installationDifficultComment: Yup.string().test(
            "is-required-when-installation-difficult",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { installationDifficult } = this.parent;
              if (installationDifficult) {
                return !!value;
              }
              return true;
            }
          ),
          holeSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          lintelHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          widthOfTheRightLateralSpace: Yup.string().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          widthOfTheLeftLateralSpace: Yup.string().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          garageDepth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          guidanceType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          glazingInTheGate: Yup.string().test(
            "is-required-when-toImprove",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { glazingInTheGateComment } = this.parent;
              if (value === "true" && !glazingInTheGateComment) {
                return false;
              }
              return true;
            }
          ),
          methodOfOpeningManualAutomatic: Yup.string().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          typeOfRail: Yup.string().test(
            "is-required-when-methodOfOpening",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { methodOfOpeningManualAutomatic } = this.parent;
              if (methodOfOpeningManualAutomatic === "automatic") {
                return !!value;
              }
              return true;
            }
          ),
          doorPosition: Yup.string().test(
            "is-required-when-door-position",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { doorInTheGate } = this.parent;
              if (doorInTheGate) {
                return !!value;
              }
              return true;
            }
          ),
          sideOfTheDoor: Yup.string().test(
            "is-required-when-side-of-the-door",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { doorInTheGate } = this.parent;
              if (doorInTheGate) {
                return !!value;
              }
              return true;
            }
          ),
          additionalHangersComments: Yup.string().test(
            "is-required-when-additional-hangers",
            VALIDATION_MESSAGES.REQUIRED,
            function (value) {
              const { additionalHangers } = this.parent;
              if (additionalHangers) {
                return !!value;
              }
              return true;
            }
          ),
        });
      case GARAGE_DOOR_TYPE.ROLLER_GATE:
        return Yup.object().shape({
          typeOfGate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          holeSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          gateSizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          assemblyType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          cableExitSide: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          lintelHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          typeOfDrive: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          sideOfThePowerCable: Yup.string().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
        });
      default:
        return Yup.object().shape({
          typeOfGate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        });
    }
  };

  const getInitialValues = (typeOfGate: string) => {
    switch (typeOfGate) {
      case GARAGE_DOOR_TYPE.SWING_GATE:
        return {
          measurementUuid: measurementUuid || "",
          typeOfGate: typeOfGate,
          holeSizeWidth: initialValue?.holeSizeWidth || "",
          holeSizeHeight: initialValue?.holeSizeHeight || "",
          gateSizeWidth: initialValue?.gateSizeWidth || "",
          gateSizeHeight: initialValue?.gateSizeHeight || "",
          methodOfOpening: initialValue?.methodOfOpening || "",
          gateDivision: initialValue?.gateDivision || "",
          activeWing: initialValue?.activeWing || "",
          passiveWing: initialValue?.passiveWing || "",
          gateType: initialValue?.gateType || "",
          color: initialValue?.color || "",
          warningComment: initialValue?.warningComment || "",
          comments: initialValue?.comments || "",
        };
      case GARAGE_DOOR_TYPE.TILTING_GATE:
        return {
          measurementUuid: measurementUuid || "",
          typeOfGate: typeOfGate,
          holeSizeWidth: initialValue?.holeSizeWidth || "",
          holeSizeHeight: initialValue?.holeSizeHeight || "",
          gateSizeHeight: initialValue?.gateSizeHeight || "",
          gateSizeWidth: initialValue?.gateSizeWidth || "",
          methodOfOpeningManualAutomatic:
            initialValue?.methodOfOpeningManualAutomatic || "",
          typeOfRail: initialValue?.typeOfRail || "",
          gateType: initialValue?.gateType || "",
          color: initialValue?.color || "",
          warningComment: initialValue?.warningComment || "",
          comments: initialValue?.comments || "",
        };
      case GARAGE_DOOR_TYPE.SECTIONAL_GATE:
        return {
          measurementUuid: measurementUuid || "",
          typeOfGate: typeOfGate,
          plasterDuringTheMeasurement:
            initialValue?.plasterDuringTheMeasurement || false,
          styrofoamPlannedForTheCeiling:
            initialValue?.styrofoamPlannedForTheCeiling || false,
          styrofoamThickness: initialValue?.styrofoamThickness || "",
          installationDifficult: initialValue?.installationDifficult || false,
          installationDifficultComment:
            initialValue?.installationDifficultComment || "",
          holeSizeWidth: initialValue?.holeSizeWidth || "",
          holeSizeHeight: initialValue?.holeSizeHeight || "",
          lintelHeight: initialValue?.lintelHeight || "",
          widthOfTheRightLateralSpace:
            initialValue?.widthOfTheRightLateralSpace || "",
          widthOfTheLeftLateralSpace:
            initialValue?.widthOfTheLeftLateralSpace || "",
          garageDepth: initialValue?.garageDepth || "",
          gateSizeWidth: initialValue?.gateSizeWidth || "",
          gateSizeHeight: initialValue?.gateSizeHeight || "",
          guidanceType: initialValue?.guidanceType || "",
          methodOfOpeningManualAutomatic:
            initialValue?.methodOfOpeningManualAutomatic || "",
          typeOfRail: initialValue?.typeOfRail || "",
          doorInTheGate: initialValue?.doorInTheGate || false,
          doorPosition: initialValue?.doorPosition || "",
          sideOfTheDoor: initialValue?.sideOfTheDoor || "",
          additionalHangers: initialValue?.additionalHangers || false,
          unlockingTheDriveFromTheOutside:
            initialValue?.unlockingTheDriveFromTheOutside || false,
          additionalHangersComments:
            initialValue?.additionalHangersComments || "",
          sectionalGateType: initialValue?.sectionalGateType || "",
          typeOfEmbossing: initialValue?.typeOfEmbossing || "",
          glazingInTheGate: initialValue?.glazingInTheGate || false,
          warningComment: initialValue?.warningComment || "",
          comments: initialValue?.comments || "",
          color: initialValue?.color || "",
          glazingInTheGateComment: initialValue?.glazingInTheGateComment || "",
        };
      case GARAGE_DOOR_TYPE.ROLLER_GATE:
        return {
          measurementUuid: measurementUuid || "",
          typeOfGate: typeOfGate,
          assemblyType: initialValue?.assemblyType || "",
          holeSizeWidth: initialValue?.holeSizeWidth || "",
          holeSizeHeight: initialValue?.holeSizeHeight || "",
          lintelHeight: initialValue?.lintelHeight || "",
          gateSizeWidth: initialValue?.gateSizeWidth || "",
          gateSizeHeight: initialValue?.gateSizeHeight || "",
          cableExitSide: initialValue?.cableExitSide || "",
          typeOfDrive: initialValue?.typeOfDrive || "",
          sideOfThePowerCable: initialValue?.sideOfThePowerCable || "",
          color: initialValue?.color || "",
          warningComment: initialValue?.warningComment || "",
          comments: initialValue?.comments || "",
        };
      default:
        return {
          measurementUuid: measurementUuid || "",
          typeOfGate: "",
        };
    }
  };

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any
  ) => {
    switch (values.typeOfGate) {
      case GARAGE_DOOR_TYPE.SWING_GATE:
      case GARAGE_DOOR_TYPE.TILTING_GATE: {
        const {
          holeSizeWidth,
          holeSizeHeight,
          gateSizeWidth,
          gateSizeHeight,
          warningComment,
        } = values;
        const poziomWylewki =
          measurementInfo?.measurementInstallationInfo?.spoutLevelValue;
        const spoutLevel = parseInt(poziomWylewki, 10);
        const holeWidthParse = parseInt(holeSizeWidth, 10);
        const holeHeightParse = parseInt(holeSizeHeight, 10);
        const gateWidthParse = parseInt(gateSizeWidth, 10);
        const gateHeightParse = parseInt(gateSizeHeight, 10);
        const widthDifference = holeWidthParse - gateWidthParse;
        const heightDifference =
          holeHeightParse - (spoutLevel + gateHeightParse);

        let isError = false;
        if (widthDifference < 30 || widthDifference > 50) {
          isError = true;
          if (!errors.warningComment && !warningComment) {
            setFieldError(
              "warningComment",
              "Szerokość bramy powinna być mniejsza o 30-50 mm od szerokości otworu."
            );
          }
        }
        // if (values.typeOfGate === GARAGE_DOOR_TYPE.TILTING_GATE) {
        if (heightDifference < 20 || heightDifference > 30) {
          isError = true;
          if (!errors.warningComment && !warningComment) {
            setFieldError(
              "warningComment",
              "Wysokość bramy powinna być mniejsza o 20-30 mm od wysokości otworu + poziomu wylewki."
            );
          }
        }
        // }

        if (!isError) {
          setIsErrorDimension(false);
          return true;
        } else {
          setIsErrorDimension(true);
          return false;
        }
      }
      case GARAGE_DOOR_TYPE.SECTIONAL_GATE: {
        const {
          holeSizeWidth,
          holeSizeHeight,
          gateSizeWidth,
          gateSizeHeight,
          warningComment,
        } = values;

        const poziomWylewki =
          measurementInfo?.measurementInstallationInfo?.spoutLevelValue;
        const spoutLevel = parseInt(poziomWylewki, 10) || 0;
        const holeWidthParse = parseInt(holeSizeWidth, 10);
        const holeHeightParse = parseInt(holeSizeHeight, 10);
        const gateWidthParse = parseInt(gateSizeWidth, 10);
        const gateHeightParse = parseInt(gateSizeHeight, 10);

        const widthDifference = Math.abs(holeWidthParse - gateWidthParse);
        const heightDifference = Math.abs(
          holeHeightParse - (spoutLevel + gateHeightParse)
        );

        let isError = false;

        if (widthDifference > 20) {
          isError = true;
          if (!errors.warningComment && !warningComment) {
            setFieldError(
              "warningComment",
              "Szerokość bramy powinna być mniejsza o 20mm lub większa o 20mm od szerokości otworu."
            );
          }
        }

        if (heightDifference > 20) {
          isError = true;
          if (!errors.warningComment && !warningComment) {
            setFieldError(
              "warningComment",
              "Wysokość bramy powinna być mniejsza o 20mm lub większa o 20mm od wysokości otworu + poziomu wylewki."
            );
          }
        }

        if (!isError) {
          setIsErrorDimension(false);
          return true;
        } else {
          setIsErrorDimension(true);
          return false;
        }
      }
      default: {
        return "";
      }
    }
  };

  return (
    <>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={getInitialValues(typeOfGate)}
        validationSchema={getValidationSchema(typeOfGate)}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
          resetForm,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          handleCheckValidateDimensions(values, setFieldError, errors);
          return (
            <form
              className={`amfs__form form garage-door`}
              onSubmit={handleSubmit}
            >
              <ScrollToError errorDimension={isErrorDimension} />
              <SingleSectionElement
                touched={touched}
                name="typeOfGate"
                errors={errors}
                className=""
              >
                <Field
                  label="Rodzaj bramy"
                  name="typeOfGate"
                  type="number"
                  options={typeOfGarageDoor}
                  component={Select}
                  onChange={(e: any) => {
                    handleSetTypeOfGate(e);
                    setInitialValue(null);
                    setIsValidated(false);
                    setIsErrorDimension(false);
                    resetForm();
                    setFieldError("warningComment", "");
                  }}
                  white
                />
              </SingleSectionElement>
              {typeOfGate && (
                <>
                  {handleShowForm(
                    submitCount,
                    typeOfGate,
                    touched,
                    errors,
                    validateField,
                    values,
                    setFieldValue,
                    setFieldError,
                    handleCheckValidateDimensions
                  )}
                  <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                    <Button
                      stroke
                      onClick={() => prevStep()}
                      label="Poprzedni krok"
                    />
                    <Button
                      stroke
                      onClick={async () => {
                        handleSubmit();
                        setIsAddNew(true);
                      }}
                      label="Zakończ i dodaj kolejny tego typu"
                    />
                    <Button
                      onClick={() => {
                        setIsAddNew(false);
                        handleSubmit();
                      }}
                      label="Zakończ pomiar"
                    />
                  </div>
                </>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default GarageDoorMeasurementValues;
