import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type ExternalDoorsReplacementData = {
  measurementUuid: string;
  roomName: string;
  doorSide: 'left' | 'right';
  openingDirection: 'inwards' | 'outwards';
  
  holeExternalWidth: number;
  holeInternalWidth: number;
  holeExternalHeight: number;
  holeInternalHeight: number;
  holeWidthDifference: number;
  holeHeightDifference: number;
  
  doorWidth: number;
  doorHeight: number;
  doorSidelightRight?: number;
  doorSidelightLeft?: number;
  doorTopLight?: number;
  doorBottomExtension?: number;
  
  holeIsCorrect: boolean;
  correctHoleWidth?: number;
  correctHoleHeight?: number;
  holePreparation?: 'investor' | 'martdom';
  
  manufacturer?: string;
  model?: string;
  color?: string;
  typeOfDoorFrame?: string;
  doorHandle?: string;
  doorKnocker?: string;
  peephole?: boolean;
  glassPanels?: string;
  powerSupply?: string;
  dripCap?: boolean;
  shortenDoorHeight?: number;
  reedSwitches?: boolean;
  comments?: string;
};

const usePutExternalDoorsReplacementInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorsReplacementData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsReplacementInfo'],
    mutationFn: async (data: ExternalDoorsReplacementData) => {
      return axiosInstance.put(`/measurement/external-doors-replacement/info`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych wymiany drzwi zewnętrznych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-replacement-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane wymiany drzwi zewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-replacement-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsReplacementInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych wymiany drzwi zewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-replacement-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorsReplacementInfo;
