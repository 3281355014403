/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect } from "react";
import { useState } from "react";
import { isMobile } from "utils";
import Table from "rc-table";
import { desktopColumns, mobileColumns } from "./utils";
import "./checklist-table.scss";
import "../../users/UsersTable";
import { useGetMeasurementTypes } from "hooks/measurements";
import { SkeletonRow } from "components";
interface ChecklistTableType {
  setModalManage: (data: any) => void;
  modalManage: any;
  setCheckListPreview: (data: any) => void;
}

const ChecklistTable = ({
  setModalManage,
  modalManage,
  setCheckListPreview,
}: ChecklistTableType) => {
  const { data: measurementTypes, isLoading } = useGetMeasurementTypes();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [activeRowId, setActiveRowId] = useState("");
  const handleClickRow = (record: any) => {
    setActiveRowId(record.value);
    setCheckListPreview(record);
  };

  useEffect(() => {
    if (modalManage.type === "") {
      setActiveRowId("");
    }
  }, [modalManage.type]);

  const setEditActivity = (order: any) =>
    setModalManage({ type: "editChecklist", order });
  const handleExpand = (expanded: any, record: any) => {
    if (isMobile()) {
      const keys = expanded ? [record.value] : [];
      setExpandedRowKeys(keys);
    }
  };

  return (
    <div className={`users-table ${activeRowId ? "users-table--active" : ""}`}>
      <Table
        rowKey="value"
        className="user-table checklist-table closed-table"
        data={measurementTypes}
        columns={
          isMobile()
            ? mobileColumns(setEditActivity)
            : desktopColumns(setEditActivity)
        }
        onRow={(record) => {
          // if(activeRowId === record.value) {
          //   setIsActiveRow(true)
          // } else {
          //   setIsActiveRow(true)
          // }
          return {
            onClick: (event) => {
              const target = event.target as HTMLElement;
              if (
                !target.classList.contains("svg") &&
                !target.classList.contains("context-menu__trigger") &&
                !target.classList.contains("context-menu__item") &&
                target.classList.length > 0
              ) {
                handleClickRow(record);
              }
            },
            className: activeRowId === record.value ? "active-row" : "",
          };
        }}
        expandable={{
          showExpandColumn: false,
          expandRowByClick: true,
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender: (record: any) => (
            <div className="user-table-row__expand">
              <div className="user-table-row__expand-top"></div>
              <div className="user-table-row__expand-bottom">
                <div
                  className="user-table-row__expand-item"
                  onClick={() => setEditActivity(record)}
                >
                  Edytuj
                </div>
              </div>
            </div>
          ),
        }}
      />
      {isLoading && <SkeletonRow count={10} height={52} />}
    </div>
  );
};

export default ChecklistTable;
