/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./switch.scss";

interface SwitchType {
  form?: any;
  name?: any;
  first?: string;
  second?: string;
  valueFirst?: any;
  valueSecond?: any;
  checked?: boolean;
  stringMode?: boolean;
  secondary?: boolean;
  label?: string;
  optional?: boolean;
  className?: string;
  field?: any;
  reverse?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const Switch = ({
  form,
  name,
  first = "TAK",
  second = "NIE",
  valueFirst,
  valueSecond,
  disabled = false,
  checked = false,
  stringMode = false,
  secondary = false,
  optional = false,
  label,
  className = "",
  field,
  reverse = false,
  onChange,
}: SwitchType) => {
  const [isChecked, setIsChecked] = useState(checked || (field && field.value));
  const [currentValue, setCurrentValue] = useState(
    stringMode ? (field && field.value) || valueFirst : isChecked
  );

  useEffect(() => {
    if (stringMode) {
      setCurrentValue((field && field.value) || valueFirst);
    } else {
      setIsChecked(checked);
    }
  }, [checked, stringMode, valueFirst]);

  useEffect(() => {
    if (stringMode) {
      form?.setFieldValue(field?.name || name, currentValue);
    }
    //  else {
    //   form.setFieldValue(field?.name || name, isChecked);
    // }
  }, [isChecked, currentValue]);

  const handleToggle = () => {
    if (disabled) {
      onChange && onChange({ target: { name, value: "" } });
      return null;
    }
    if (stringMode) {
      const newValue = currentValue === valueFirst ? valueSecond : valueFirst;
      setCurrentValue(newValue);
      form.setFieldValue(field?.name || name, newValue);
      if (onChange) {
        onChange({ target: { name, value: newValue } });
      }
    } else {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      form.setFieldValue(field?.name || name, newChecked);
      if (onChange) {
        onChange({ target: { name, checked: newChecked } });
      }
    }
  };

  const handleChange = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className={`switch__wrapper ${className}`}>
      {label && (
        <div className="switch__name">
          {label}{" "}
          {optional ? (
            <span className="switch__optional"> (opcjonalne)</span>
          ) : (
            ""
          )}
        </div>
      )}
      <label className={`switch ${secondary ? "switch__secondary" : ""}`}>
        <input
          type="checkbox"
          checked={
            stringMode
              ? currentValue === valueFirst
              : reverse
              ? !isChecked
              : isChecked
          }
          onChange={handleChange}
        />
        <span className="slider round" onClick={handleToggle}>
          <div className="switch__label switch__label-left">{first}</div>
          <div className="switch__label switch__label-right">{second}</div>
        </span>
      </label>
    </div>
  );
};

export default Switch;
