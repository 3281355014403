import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type windowAdittionLabel = {
  type: string;
  label: string;
  value: string;
};

export default function useWindowAddition(typeOfMeasurementUuid: string | undefined, options = {}) {
  return useQuery({
    queryKey: ["windowAddition", typeOfMeasurementUuid],
    queryFn: async () => {
      return axiosInstance
        .get("/window-addition", {
          params: { typeOfMeasurementUuid },
        })
        .then((res) => res.data);
    },
    select: (data: { windowAdditionsLabel: windowAdittionLabel[] }) => data.windowAdditionsLabel,
    retry: false,
    ...options,
  });
}
