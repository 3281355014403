/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import "../../users/UsersTable";
import { useState } from "react";
import { isMobile } from "utils";
import Table from "rc-table";
import { desktopColumns, mobileColumns } from "./utils";
import "./activity-table.scss";
import { useGetUserActivities } from "hooks/activity";
import { DeleteActivity } from "features/activity";
import { SkeletonRow } from "components";

interface ActiveTableType {
  setModalManage: (data: any) => void;
  modalManage: any;
}

const ActivityTable = ({ setModalManage, modalManage }: ActiveTableType) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const { data: activities, isLoading } = useGetUserActivities();
  const handleExpand = (expanded: any, record: any) => {
    if (isMobile()) {
      const keys = expanded ? [record.id] : [];
      setExpandedRowKeys(keys);
    }
  };

  const setDeleteActivity = (order: any) =>
    setModalManage({ type: "deleteActivity", order });
  const setEditActivity = (order: any) =>
    setModalManage({ type: "editActivity", order });
  const clearModalManage = () => {
    setModalManage({ type: "", order: modalManage.order });
  };

  return (
    <div className="users-table">
      <DeleteActivity
        isOpen={modalManage.type === "deleteActivity"}
        onClose={() => clearModalManage()}
        data={modalManage.order}
      />
      <Table
        rowKey="id"
        className="user-table order-table"
        data={activities}
        columns={
          isMobile()
            ? mobileColumns(setDeleteActivity, setEditActivity)
            : desktopColumns(setDeleteActivity, setEditActivity)
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: true,
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender: (record: any) => (
            <div className="user-table-row__expand">
              <div className="user-table-row__expand-top"></div>
              <div className="user-table-row__expand-bottom">
                <div
                  className="user-table-row__expand-item"
                  onClick={() => setEditActivity(record)}
                >
                  Edytuj
                </div>
                <div
                  className="user-table-row__expand-item"
                  onClick={() => setDeleteActivity(record)}
                >
                  Usuń
                </div>
              </div>
            </div>
          ),
        }}
      />
      {isLoading && <SkeletonRow count={10} height={56} />}
      {activities && activities.length === 0 && (
        <div className="users-table__no-data">
          <div className="users-table__no-data--paragraph">
            Lista jest pusta
            <div className="users-table__no-data--paragraph">
              Kliknij w przycisk “Dodaj” aby dodać nową czynność
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityTable;
