import { Input, Select, Textarea, Switch } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  TypeOfRadiobox,
  AdditionalWarning,
} from "features/addingMeasurement";

interface SectionalGateFormType {
  touched: any;
  errors: any;
  validateField: any;
  values: any;
  isErrorDimension: boolean;
  setFieldValue: any;
  setFieldError: any;
}

const SectionalGateForm = ({
  touched,
  errors,
  validateField,
  values,
  isErrorDimension,
  setFieldValue,
  setFieldError,
}: SectionalGateFormType) => {
  return (
    <>
      <SingleSectionElement
        touched={touched}
        name="plasterDuringTheMeasurement"
        errors={errors}
      >
        <Field
          checked={values.plasterDuringTheMeasurement}
          label={`Czy podczas pomiaru były tynki?`}
          name="plasterDuringTheMeasurement"
          component={Switch}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["styrofoamPlannedForTheCeiling", "styrofoamThickness"]}
        errors={errors}
      >
        <Field
          checked={values.styrofoamPlannedForTheCeiling}
          label={`Czy planowany jest styropian na suficie?`}
          name="styrofoamPlannedForTheCeiling"
          component={Switch}
        />
        {values.styrofoamPlannedForTheCeiling && (
          <Field
            maxLength={30}
            label="Grubość styropianu"
            rightPlaceholder="mm"
            name="styrofoamThickness"
            component={Input}
            type="number"
          />
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["installationDifficult", "installationDifficultComment"]}
        errors={errors}
      >
        <Field
          checked={values.installationDifficult}
          label={`Czy są przeszkody utrudniające montaż (wyłaz, drzwi, belki)?`}
          name="installationDifficult"
          component={Switch}
        />
        {values.installationDifficult && (
          <Field
            className="am-margin-top"
            name="installationDifficultComment"
            component={Textarea}
            maxLength={250}
          />
        )}
      </SingleSectionElement>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        touched={touched}
        name="lintelHeight"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          maxLength={6}
          label="Wysokość nadproża"
          rightPlaceholder="mm"
          type="number"
          name="lintelHeight"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="widthOfTheRightLateralSpace"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          maxLength={10}
          label="Szerokość przestrzeni bocznej prawej"
          rightPlaceholder="mm"
          type="number"
          name="widthOfTheRightLateralSpace"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="widthOfTheLeftLateralSpace"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          maxLength={10}
          label="Szerokość przestrzeni bocznej lewej"
          rightPlaceholder="mm"
          type="number"
          name="widthOfTheLeftLateralSpace"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="garageDepth"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          maxLength={10}
          label="Głębokość garażu"
          rightPlaceholder="mm"
          type="number"
          name="garageDepth"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name={["gateSizeWidth", "gateSizeHeight"]}
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="gateSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="gateSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <AdditionalWarning
          isErrorDimension={isErrorDimension}
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      </SingleSectionElement>
      <Field
        errors={errors}
        name="guidanceType"
        label="Typ prowadzenia"
        options={[
          {
            label: "Przód",
            value: "front",
            uuid: "front",
          },
          {
            label: "Tył",
            value: "back",
            uuid: "back",
          },
          {
            label: "Bok",
            value: "side",
            uuid: "side",
          },
        ]}
        component={TypeOfRadiobox}
      />
      <SingleSectionElement
        touched={touched}
        name={["methodOfOpeningManualAutomatic", "typeOfRail"]}
        errors={errors}
        className="padding-top-separator methodOfOpeningManualAutomatic tilting-gate-selects"
      >
        <Field
          label="Sposób otwierania"
          name="methodOfOpeningManualAutomatic"
          type="number"
          options={[
            {
              value: "manual",
              label: "Ręczna",
            },
            {
              value: "automatic",
              label: "Automatyczna",
            },
          ]}
          component={Select}
          white
        />
        {values.methodOfOpeningManualAutomatic === "automatic" && (
          <Field
            // className="second"
            label="Rodzaj szyny"
            name="typeOfRail"
            options={[
              {
                value: "chain",
                label: "Łańcuch",
              },
              {
                value: "strap",
                label: "Pasek",
              },
            ]}
            component={Select}
            white
          />
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="unlockingTheDriveFromTheOutside"
        errors={errors}
      >
        <Field
          checked={values.unlockingTheDriveFromTheOutside}
          label={`Rozblokowanie napędu z zewnątrz?`}
          name="unlockingTheDriveFromTheOutside"
          component={Switch}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["doorInTheGate", "doorPosition", "sideOfTheDoor"]}
        errors={errors}
      >
        <Field
          label={`Drzwi w bramie?`}
          name="doorInTheGate"
          checked={values.doorInTheGate}
          component={Switch}
        />
        {values.doorInTheGate && (
          <div className="doorInTheGate">
            <Field
              label="Położenie drzwi (widok od wewnątrz)"
              name="doorPosition"
              type="number"
              options={[
                {
                  value: "right",
                  label: "Po prawej",
                },
                {
                  value: "left",
                  label: "Po lewej",
                },
                {
                  value: "middle",
                  label: "Po środku",
                },
              ]}
              component={Select}
              white
            />
            <Field
              label="Stronność drzwi"
              name="sideOfTheDoor"
              options={[
                {
                  value: "left",
                  label: "Po lewej",
                },
                {
                  value: "right",
                  label: "Po prawej",
                },
              ]}
              component={Select}
              white
            />
          </div>
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["additionalHangers", "additionalHangersComments"]}
        errors={errors}
      >
        <Field
          checked={values.additionalHangers}
          label={`Dodatkowe podwieszki do prowadnic`}
          name="additionalHangers"
          component={Switch}
        />
        {values.additionalHangers && (
          <Field
            className="am-margin-top"
            errors={errors}
            name="additionalHangersComments"
            component={Textarea}
            maxLength={250}
          />
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["glazingInTheGate", "glazingInTheGateComment"]}
        errors={errors}
        className="sectional-gate__glazing"
      >
        <Field
          checked={values.glazingInTheGate}
          label={`Przeszklenia w bramie`}
          name={"glazingInTheGate"}
          component={Switch}
        />
        {values.glazingInTheGate && (
          <Field
            errors={errors}
            label="Komentarz"
            name="glazingInTheGateComment"
            component={Textarea}
          />
        )}
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="sectionalGateType"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Typ bramy segmentowej"
          name="sectionalGateType"
          optional
          component={Input}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="Color"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={20}
          label={`Kolor`}
          name="color"
          optional
          component={Input}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="typeOfEmbossing"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={20}
          label="Rodzaj przetłoczeń"
          name="typeOfEmbossing"
          optional
          component={Input}
        />
      </SingleSectionElement>

      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comments"
        optional
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default SectionalGateForm;
