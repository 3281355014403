import { OrderType } from "types/OrderTypes";
import "./completed-measurements.scss";
import { USER_ROLE, ORDER_ENUM, FILE_ENUM } from "enums";
import { hasAccess, hasOrderStatus } from "utils";
import { FileItem } from "components";

interface CompletedMeasurementsProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const CompletedMeasurements = ({
  orderDetails,
  userRole,
}: CompletedMeasurementsProps) => {
  const { scheduleManager, admin, fitter, measurer } = USER_ROLE;
  const {
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
    MEASUREMENTS_DONE,
  } = ORDER_ENUM;
  const { status, files } = orderDetails;
  const isScheduleManager = hasAccess(userRole, [scheduleManager]);
  const orderStatusAccess = hasOrderStatus(status, [
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
    MEASUREMENTS_DONE,
  ]);
  const roleAccess = hasAccess(userRole, [
    admin,
    fitter,
    scheduleManager,
    measurer,
  ]);

  const allowedFileTypes = [
    FILE_ENUM.GENERATED_PDF,
    FILE_ENUM.CUSTOM_MEASUREMENT_FILE,
  ];
  const filteredFiles = files.filter(({ type }: { type: string }) =>
    allowedFileTypes.includes(type as FILE_ENUM)
  );
  const showSection = roleAccess && orderStatusAccess;
  const showDetails = filteredFiles.length > 0;

  return (
    <>
      {showSection && showDetails && (
        <div className="completed-measurements">
          {isScheduleManager && <div className="am-separator" />}
          <div className="modal__information-header determined-and-arranged-installation__header">
            Pliki z pomiaru
          </div>
          <>
            {filteredFiles &&
              filteredFiles.length > 0 &&
              filteredFiles.map((item) => {
                return (
                  <FileItem
                    file={{ ...item, name: item.measurementLabel || item.name }}
                  />
                );
              })}
          </>
        </div>
      )}
    </>
  );
};

export default CompletedMeasurements;
