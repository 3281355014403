import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import { useSearchParams } from "react-router-dom";

interface ArchiveOrderType {
  uuid: string;
}

const useArchivedOrder = (
  options?: UseMutationOptions<any, Error, ArchiveOrderType>
) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");

  return useMutation({
    ...options,
    mutationKey: ['archiveOrder'],
    mutationFn: async ({ uuid }) => {
      return axiosInstance
        .patch(`/orders/schedule-manager/archived/${uuid}`)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie archiwizacji zlecenia...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'archive-order',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: `Zlecenie zostało zarchiwizowane pomyślnie`,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'archive-order',
      });
      queryClient.invalidateQueries({ queryKey: ['scheduledOrders'] });
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });
      }, 100);
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas archiwizacji zlecenia',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'archive-order',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useArchivedOrder;
