import { Button, Modal } from "components";
import { ORDER_ENUM } from "enums";
import { useUpdateOrderStatus } from "hooks/orders"; // Importujemy hook

interface ChangeOrderStatusProps {
  onClose: () => void;
  modalManage: {
    isOpen: boolean;
    orderDetails: any;
    status: string;
    orderStatus: string;
  };
}

const ChangeOrderStatus = ({
  onClose,
  modalManage,
}: ChangeOrderStatusProps) => {
  const updateStatus = useUpdateOrderStatus({
    onSuccess: () => {
      onClose();
    },
  });
  const { orderDetails, orderStatus, status, isOpen } = modalManage;
  const isInstallationToBeReleased =
    ORDER_ENUM.INSTALLATION_TO_BE_RELEASED === orderStatus &&
    status === "Pominięcie weryfikacji";

  const handleConfirm = () => {
    if (orderDetails.uuid) {
      if (isInstallationToBeReleased) {
        updateStatus.mutate({
          uuid: orderDetails.uuid,
          status: orderStatus,
          comment: "Weryfikacja pominięta",
        });
      } else {
        updateStatus.mutate({ uuid: orderDetails.uuid, status: orderStatus });
      }
    }
  };

  return (
    <>
      <Modal
        className="order-status__modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">
            {isInstallationToBeReleased
              ? "Pominięcie weryfikacji i zmiana statusu zlecenia"
              : "Zmiana statusu zlecenia"}
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zmienić status zlecenia
            <span>{orderDetails.name}</span> na{" "}
            <span className="modal-description--blue">
              {isInstallationToBeReleased ? "Montaż do ustalenia" : status}
            </span>
          </div>
          {isInstallationToBeReleased && (
            <div className="modal-description modal-description--second">
              Pamiętaj, że tej akcji nie można cofnąć.
            </div>
          )}
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={handleConfirm} label="Potwierdź" />{" "}
          </div>
        </>
      </Modal>
    </>
  );
};

export default ChangeOrderStatus;
