/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect } from "react";
import { isMobile } from "utils";
import { USER_ROLE } from "enums";
import Table from "rc-table";
import { Pagination, SkeletonRow } from "components";
import { desktopColumns, mobileColumns } from "./utils";
import "./orders-table.scss";
import "../../users/UsersTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetOrders,
  useGetScheduledOrders,
  useGetAllOrders,
} from "hooks/orders";
import { OrderStatus, ScheduleOrderStatus } from "types/OrderTypes";

interface OrderPreviewType {
  setOrderPreview: (order: any) => void;
  activeFloatingMenuItem?: { label: string; value: string } | null;
  userRole: string;
  page: number;
  setPage: (page: number) => void;
  activeRowId: string;
  setActiveRowId: (uuid: string) => void;
  setModalManage: any;
  modalManage: any;
}

const OrdersTable = ({
  setOrderPreview,
  setModalManage,
  activeFloatingMenuItem,
  userRole,
  page,
  setPage,
  activeRowId,
  setActiveRowId,
  modalManage,
}: OrderPreviewType) => {
  const isScheduleManager = userRole === USER_ROLE.scheduleManager;
  const isAdmin = userRole === USER_ROLE.admin;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const uuidFromUrl = searchParams.get("orderUuid") || searchParams.get("uuid");
  const groupuidFromUrl = searchParams.get("groupUuid");
  const tabFromUrl = searchParams.get("tab");
  const isAllOrder = tabFromUrl === "orders";

  useEffect(() => {
    if (modalManage.type === "") {
      setActiveRowId("");
    }
  }, [modalManage.type]);

  const { data: allOrders, isLoading: isLoadingAllOrders } = useGetAllOrders(
    page,
    {
      enabled: isAllOrder,
    }
  );

  const { data: regularOrders, isLoading: isLoadingRegularOrders } =
    useGetOrders(activeFloatingMenuItem?.value as OrderStatus, page, {
      enabled: !isScheduleManager && !isAdmin,
    });

  const isEnable = !!(isScheduleManager && tabFromUrl && tabFromUrl.length > 0);
  const { data: scheduledOrders, isLoading: isLoadingScheduledOrders } =
    useGetScheduledOrders(tabFromUrl as ScheduleOrderStatus, page, {
      enabled: isEnable,
    });

  const orders = isAdmin
    ? allOrders
    : isScheduleManager
    ? scheduledOrders
    : regularOrders;
  const isLoading = isAdmin
    ? isLoadingAllOrders
    : isScheduleManager
    ? isLoadingScheduledOrders
    : isLoadingRegularOrders;

  useEffect(() => {
    if (uuidFromUrl && orders?.data) {
      const record = orders.data.find((r) => r.uuid === uuidFromUrl);
      const record2 = orders.data.find((r) => r.groupUuid === groupuidFromUrl);
      if (record2) {
        setActiveRowId(record2.groupUuid || record2.uuid);
        setOrderPreview(record2);
      } else if (record) {
        setActiveRowId(record.uuid);
        setOrderPreview(record);
      }
    }
  }, [orders, uuidFromUrl]);

  useEffect(() => {
    if (scheduledOrders?.data.length === 0) {
      setPage(page - 1);
    }
  }, [scheduledOrders?.data.length === 0]);

  const handleClickRow = (record: any) => {
    setActiveRowId(record.groupUuid || record.uuid);
    setOrderPreview(record);
    activeFloatingMenuItem?.value
      ? record.groupUuid
        ? setSearchParams({
            page: page.toString(),
            orderUuid: record.uuid,
            groupUuid: record.groupUuid || "",
            tab: activeFloatingMenuItem?.value,
          })
        : setSearchParams({
            page: page.toString(),
            orderUuid: record.uuid,
            tab: activeFloatingMenuItem?.value,
          })
      : record.groupUuid
      ? setSearchParams({
          page: page.toString(),
          orderUuid: record.uuid,
          groupUuid: record.groupUuid,
        })
      : setSearchParams({
          page: page.toString(),
          orderUuid: record.uuid,
        });
  };

  return (
    <div className={`users-table ${activeRowId ? "users-table--active" : ""}`}>
      {orders && orders?.data?.length > 0 && (
        <Table
          rowKey="uuid"
          className="user-table order-table"
          data={orders?.data}
          columns={
            isMobile()
              ? mobileColumns(
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem,
                  setModalManage
                )
              : desktopColumns(
                  navigate,
                  setSearchParams,
                  page,
                  userRole,
                  activeFloatingMenuItem,
                  setModalManage
                )
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                const target = event.target as HTMLElement;
                if (target.closest(".no-click")) {
                  return;
                }
                handleClickRow(record);
              },
              className: record.groupUuid
                ? activeRowId === record.groupUuid
                  ? "active-row"
                  : ""
                : activeRowId === record.uuid
                ? "active-row"
                : "",
            };
          }}
        />
      )}
      {isLoading && <SkeletonRow count={10} height={58} />}
      {orders && orders.data.length === 0 && (
        <div className="users-table__no-data">
          <div className="users-table__no-data--paragraph">
            {isAdmin || isScheduleManager
              ? "Brak zleceń"
              : "Brak zleceń do wykonania"}
          </div>
          {isScheduleManager && (
            <div className="users-table__no-data--paragraph">
              Kliknij w przycisk “Dodaj” aby utworzyć nowe
            </div>
          )}
        </div>
      )}
      {orders?.meta && orders?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={orders?.meta?.lastPage}
          currentPage={page}
          onPageChange={(e) => {
            setPage(e);
            tabFromUrl
              ? setSearchParams({ page: e.toString(), tab: tabFromUrl })
              : setSearchParams({ page: e.toString() });
          }}
        />
      )}
    </div>
  );
};

export default OrdersTable;
