/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./one-day-modal.scss";
import "moment/locale/pl";
import moment from "moment";
import { SVG } from "components";
import { CalendarEventPreview } from "components/Calendars";
import { SVG_TYPE, USER_ROLE, ROUTE_ENUM } from "enums";
import { isTablet, hasAccess } from "utils";
import { PortalWithState } from "react-portal";
import classNames from "classnames";
import { useNavigate } from "react-router";

interface OneDayModalProps {
  position: any;
  setPosition: any;
  card: any;
  day: Date;
  setCardPreview: any;
  activeEvent: any;
  setActiveEvent: any;
  user?: any;
  isMonthView?: any;
  showMoreInfo: any;
  setShowMoreInfo: any;
}

const OneDayModal = ({
  position,
  card,
  day,
  setPosition,
  setCardPreview,
  activeEvent,
  setActiveEvent,
  user,
  isMonthView,
  showMoreInfo,
  setShowMoreInfo,
}: OneDayModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const navigate = useNavigate();
  const { ORDERS_LIST } = ROUTE_ENUM;

  useEffect(() => {
    if (position && position.x) {
      const modalHeight = modalRef.current?.offsetHeight || 0;
      const modalWidth = modalRef.current?.offsetWidth || 0;
      setAdjustedPosition(validatePosition(position, modalHeight, modalWidth));
    }
  }, [position]);

  const isAdminOrAdvisor = hasAccess(user.role, [
    USER_ROLE.admin,
    USER_ROLE.advisor,
  ]);

  const validatePosition = (
    pos: { top: number; left: number },
    modalHeight: number,
    modalWidth: number
  ) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let newTop = pos.top;
    let newLeft = pos.left;

    if (pos.top + modalHeight > viewportHeight) {
      newTop = viewportHeight - modalHeight - 20;
    }

    if (pos.left + modalWidth > viewportWidth) {
      newLeft = viewportWidth - modalWidth - 10;
    }

    if (newTop < 0) {
      newTop = 10;
    }

    if (newLeft < 0) {
      newLeft = 10;
    }

    return { top: newTop, left: newLeft };
  };

  const handleOnClose = () => {
    setCardPreview({ isOpen: false, date: null, eventsOnDate: null });
    setPosition(null);
    setShowMoreInfo();
    const moreButtons = document.querySelectorAll(".rbc-button-link");
    const rbcEvents = document.querySelectorAll(".rbc-event");
    moreButtons.forEach((element) => {
      element.classList.remove("rbc-event--no-click");
    });
    rbcEvents.forEach((element) => {
      element.classList.remove("rbc-event--no-click");
    });
  };
  const dayOfWeek = moment(day).day();

  const popupStyle: React.CSSProperties = {
    position: "fixed",
    top: adjustedPosition?.top || 0,
    left:
      dayOfWeek === 0
        ? (adjustedPosition?.left ?? 0) - (isTablet() ? 350 : 250)
        : dayOfWeek === 6
        ? (adjustedPosition?.left ?? 0) - (isTablet() ? 190 : 150)
        : dayOfWeek === 5
        ? (adjustedPosition?.left ?? 0) - (isTablet() ? 130 : 0)
        : adjustedPosition?.left ?? 0,
  };

  const formattedDay = moment(day).format("dddd, D MMMM");
  const capitalizedDay =
    formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1);
  const isOpenModal =
    !activeEvent &&
    !!(position && position.x) &&
    moment(card.date).format("M") === moment(day).format("M");

  const handleClickEvent = (original: any, index: number) => {
    if (isAdminOrAdvisor) {
      setActiveEvent(original);
    } else {
      setShowMoreInfo(original);
    }
  };

  const formattedDate =
    showMoreInfo &&
    moment(showMoreInfo.date, "DD/MM/YYYY").format("dddd, D MMMM");

  const listOrder = () => {
    const isActive = showMoreInfo.page && showMoreInfo.listStatus;
    if (isActive) {
      const handleClick = () =>
        navigate(
          `${ORDERS_LIST}?page=${showMoreInfo.page}&tab=${showMoreInfo.listStatus}&orderUuid=${showMoreInfo.uuid}`
        );
      return (
        <div className="cpp__details" onClick={() => handleClick()}>
          Szczegóły zlecenia <SVG type={SVG_TYPE.LONG_ARROW} />
        </div>
      );
    }
  };
  return (
    <>
      {!isOpenModal &&
        activeEvent &&
        adjustedPosition &&
        adjustedPosition.top && (
          <CalendarEventPreview
            setActiveEvent={setActiveEvent}
            activeEvent={activeEvent}
            user={user}
            event={{ original: activeEvent }}
          />
        )}
      {isOpenModal && adjustedPosition && adjustedPosition.top && (
        <>
          <PortalWithState
            closeOnEsc={true}
            closeOnOutsideClick={true}
            defaultOpen={card.isOpen}
            onClose={() => handleOnClose()}
          >
            {({ portal }) => (
              <>
                {portal(
                  <>
                    {isOpenModal && (
                      <div
                        ref={modalRef}
                        className="one-day-modal"
                        style={popupStyle}
                      >
                        <div className="one-day-modal__content">
                          {!showMoreInfo ? (
                            <>
                              <div className="one-day-modal__day">
                                {capitalizedDay}
                                <div
                                  className="one-day-modal__close"
                                  onClick={() => handleOnClose()}
                                >
                                  <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                                </div>
                              </div>

                              <div className="one-day-modal__list">
                                {card.eventsOnDate.map(
                                  (event: any, index: number) => {
                                    const { original } = event;
                                    const isAdditional = original.initialDate;
                                    const time =
                                      original.start || original.archivalStart;
                                    const formattedTime = time
                                      ? time.replace(/^0/, "")
                                      : "";
                                    const name = original.title;
                                    const color =
                                      original.employees &&
                                      original.employees.length > 0 &&
                                      original.employees[0].userColor;
                                    const oneDayModalClass = classNames(
                                      "one-day-modal__item",
                                      {
                                        "one-day-modal__item--initial":
                                          isAdditional,
                                      }
                                    );
                                    return (
                                      <div
                                        key={event.index}
                                        className={oneDayModalClass}
                                        onClick={(e) => {
                                          handleClickEvent(original, index);
                                        }}
                                      >
                                        {!isAdditional && (
                                          <>
                                            <div
                                              className="one-day-modal__item-color"
                                              style={{ backgroundColor: color }}
                                            />
                                            <div className="one-day-modal__item-hour">
                                              {formattedTime}
                                            </div>
                                          </>
                                        )}
                                        <div className="one-day-modal__item-name name">
                                          {name}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          ) : (
                            <div
                              className="cpp__wrapper"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="cpp__content"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div
                                  className="cpp__top"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div className="cpp__actions">
                                    <div
                                      className="one-day-modal__edit"
                                      onClick={() => console.log("1")}
                                    >
                                      <SVG type={SVG_TYPE.EDIT_PENCIL} />
                                    </div>
                                    <div
                                      className="one-day-modal__close"
                                      onClick={() => setShowMoreInfo()}
                                    >
                                      <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                                    </div>
                                  </div>
                                </div>
                                <div className="cpp__event">
                                  <div className="cpp__event-name">
                                    {showMoreInfo.title}
                                  </div>
                                  <div className="cpp__event-date">
                                    {showMoreInfo.initialDate ? (
                                      moment(
                                        showMoreInfo.date,
                                        "DD/MM/YYYY",
                                        true
                                      ).isValid() &&
                                      moment(
                                        showMoreInfo.dateEnd,
                                        "DD/MM/YYYY",
                                        true
                                      ).isValid() ? (
                                        moment(
                                          showMoreInfo.date,
                                          "DD/MM/YYYY"
                                        ).isSame(
                                          moment(
                                            showMoreInfo.dateEnd,
                                            "DD/MM/YYYY"
                                          ),
                                          "month"
                                        ) ? (
                                          `${moment(
                                            showMoreInfo.date,
                                            "DD/MM/YYYY"
                                          ).format("D")}-${moment(
                                            showMoreInfo.dateEnd,
                                            "DD/MM/YYYY"
                                          ).format("D")} ${moment(
                                            showMoreInfo.date,
                                            "DD/MM/YYYY"
                                          ).format("MMMM")}`
                                        ) : (
                                          `${moment(
                                            showMoreInfo.date,
                                            "DD/MM/YYYY"
                                          ).format("DD/MM/YYYY")} - ${moment(
                                            showMoreInfo.dateEnd,
                                            "DD/MM/YYYY"
                                          ).format("DD/MM/YYYY")}`
                                        )
                                      ) : (
                                        "Nieprawidłowy format daty"
                                      )
                                    ) : (
                                      <>
                                        {formattedDate} {showMoreInfo.start}-
                                        {showMoreInfo.end}
                                      </>
                                    )}
                                  </div>
                                  <div className="cpp__event-address">
                                    {showMoreInfo.address}
                                  </div>
                                  <div className="cpp__event-user">
                                    {showMoreInfo.employees &&
                                      showMoreInfo.employees.length > 0 && (
                                        <>
                                          <div
                                            style={{
                                              backgroundColor:
                                                showMoreInfo.userColor,
                                            }}
                                            className="cpp__event-user-box"
                                          ></div>
                                          <div className="cpp__event-user-name">
                                            {showMoreInfo.employees.map(
                                              (item: any, index: number) => {
                                                const isMoreThanOneAndNotLast =
                                                  index + 1 <
                                                  showMoreInfo.employees.length;
                                                return (
                                                  <>
                                                    {item.name
                                                      ? item.name
                                                      : `${item.firstName} ${item.lastName}`}
                                                    {isMoreThanOneAndNotLast
                                                      ? ", "
                                                      : ""}
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                              {listOrder()}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </PortalWithState>
        </>
      )}
    </>
  );
};

export default OneDayModal;
