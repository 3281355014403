/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  SVG,
  Select,
  CalendarInput,
  MultipleSelect,
  Button,
  Modal,
} from "components";
import { SVG_TYPE } from "enums";
import { measurementAllInstallationType } from "types/OrderTypes";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { useGetFitterAndMeasurer } from "hooks/users";
import { Field } from "formik";
import { isMobile } from "utils";
import "./installation-planing.scss";
import { useFormikContext } from "formik";
function generateTimeOptions() {
  const timeOptions = [];
  for (let minutes = 6 * 60; minutes <= 22 * 60; minutes += 15) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const label = `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
    timeOptions.push({ value: label, label });
  }

  return timeOptions;
}

interface InstallationPlanningProps {
  details: measurementAllInstallationType;
  errors: any;
  initialValues: any;
  setInitialValues: any;
  installationIndex: number;
  step: number;
  validateField: any;
  toDetermine: any;
  initialChecked?: Array<string>;
  setShowError: (isError: boolean) => void;
}

const InstallationPlanning = ({
  details,
  errors,
  initialValues,
  setInitialValues,
  installationIndex,
  step,
  validateField,
  setShowError,
  initialChecked,
  toDetermine,
}: InstallationPlanningProps) => {
  const { setErrors } = useFormikContext();
  const currentDate = new Date();
  const [activeCheckboxes, setActiveCheckboxes] = useState([""]);
  const [isPlanningOpen, setIsPlanningOpen] = useState(false);
  const [modalManage, setModalManage] = useState<any>({
    isOpen: "",
    modal: "",
    data: { whichOne: 0 },
  });
  const isDeadlineModal = modalManage.modal === "deadline";
  const { data: fitter } = useGetFitterAndMeasurer(true);
  const newDates = {
    date: "",
    teams: [{ employeeUuids: [], start: "", end: "" }],
  };

  useEffect(() => {
    if (initialChecked && activeCheckboxes[0] === "") {
      setActiveCheckboxes(initialChecked);
    }
  }, [initialChecked]);

  useEffect(() => {
    if (step === 2) {
      setIsPlanningOpen(true);
    } else {
      setIsPlanningOpen(false);
    }
  }, [step]);

  const handleAddTimeSlotter = (whichOne: number) => {
    const oldErrors = errors;
    const updatedInstallations = [...initialValues.installations];

    const targetInstallation = updatedInstallations[0];
    if (targetInstallation && targetInstallation.measurementNames) {
      targetInstallation.dates[whichOne] = {
        ...targetInstallation.dates[whichOne],
        teams: [
          ...targetInstallation.dates[whichOne].teams,
          { employeeUuids: [], start: "", end: "" },
        ],
      };

      updatedInstallations[installationIndex] = targetInstallation;
      setInitialValues({
        comment: initialValues.comment,
        installations: updatedInstallations,
      });
    }

    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  const usersSelect = fitter && [...fitter];

  const handleAddDates = () => {
    const oldErrors = errors;

    const updatedInstallations = [...initialValues.installations];

    const firstInstallation = updatedInstallations[0];
    if (firstInstallation) {
      firstInstallation.dates = [...firstInstallation.dates, newDates];
      updatedInstallations[0] = firstInstallation;

      setInitialValues({
        comment: initialValues.comment,
        installations: updatedInstallations,
      });
    }

    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  const handleDeleteInstallation = (
    whichOne: number,
    isSlotter: boolean = false
  ) => {
    const oldErrors = errors;

    const updatedInstallations = [...initialValues.installations];
    const firstInstallation = updatedInstallations[0];

    if (isSlotter) {
      if (
        firstInstallation &&
        firstInstallation.dates[modalManage.data.whichOne]
      ) {
        const updatedTeam = firstInstallation.dates[
          modalManage.data.whichOne
        ].teams.filter(
          (_: any, teamIndex: number) =>
            teamIndex !== modalManage.data.whichOneTeam
        );

        firstInstallation.dates[modalManage.data.whichOne] = {
          ...firstInstallation.dates[modalManage.data.whichOne],
          teams: updatedTeam,
        };
      }
    } else {
      const updatedDates = firstInstallation.dates.filter(
        (_: any, index: number) => index !== whichOne
      );

      if (updatedDates.length > 0) {
        firstInstallation.dates = updatedDates;
      } else {
        firstInstallation.dates = [];
      }
    }

    updatedInstallations[0] = firstInstallation;

    setInitialValues({ installations: updatedInstallations });

    setModalManage({ isOpen: false });

    // Resetujemy błędy
    setTimeout(() => {
      setErrors(oldErrors);
    }, 10);
  };

  function getRandomKey() {
    return Math.random().toString(36).substr(2, 9);
  }

  // useEffect(() => {
  //   if (!initialValues.installations[0]) {
  //     setInitialValues({
  //       ...initialValues,
  //       installations: [
  //         {
  //           measurementNames: [],
  //           dates: [],
  //         },
  //       ],
  //     });
  //   }
  // }, [initialValues, setInitialValues]);

  const handleClickCheckbox = () => {
    const currentInstallation = initialValues.installations[0];

    const isSelected = activeCheckboxes.includes(details.type);

    const updatedMeasurementNames = isSelected
      ? currentInstallation.measurementNames.filter(
          (name: string) => name !== details.type
        )
      : [...currentInstallation.measurementNames, details.type];

    const updatedInstallations = [
      {
        ...currentInstallation,
        measurementNames: updatedMeasurementNames,
      },
    ];

    setActiveCheckboxes(updatedMeasurementNames);
    updatedMeasurementNames.length > 0 && setShowError(false);
    setInitialValues({ ...initialValues, installations: updatedInstallations });
  };
  const isChecked = activeCheckboxes.includes(details.type);
  function formatDateRange(initialDate: { start: string; end: string }) {
    const { start, end } = initialDate;

    const [startDay, startMonth, startYear] = start.trim().split("/");
    const [endDay, endMonth, endYear] = end.trim().split("/");

    if (start === end) {
      return start;
    } else if (startMonth === endMonth && startYear === endYear) {
      return `${startDay}-${endDay}/${startMonth}/${startYear}`;
    } else if (startYear === endYear) {
      return `${startDay}/${startMonth} - ${endDay}/${endMonth} ${startYear}`;
    } else {
      return `${start} - ${end}`;
    }
  }

  return (
    <>
      <Modal
        className="delete-activity"
        isOpened={modalManage.isOpen}
        onClose={() => setModalManage({ isOpen: false })}
      >
        <>
          <div className="modal-heading">
            Usuwanie {isDeadlineModal ? "terminu" : "przedziału czasowego"}
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć{" "}
            {isDeadlineModal ? "termin?" : "przedział czasowy?"}{" "}
            {isDeadlineModal ? (
              <span>{modalManage?.data?.date}</span>
            ) : (
              <>
                <span>
                  {modalManage?.data?.date
                    ? `${modalManage?.data?.timeSlotter} ${
                        modalManage?.data?.date
                          ? `z dnia ${modalManage?.data?.date}`
                          : ""
                      }`
                    : modalManage?.data?.timeSlotter}
                </span>
              </>
            )}
          </div>
          <div className="modal-buttons">
            <Button
              stroke
              onClick={() => setModalManage({ isOpen: false })}
              label="Anuluj"
            />
            <Button
              onClick={() =>
                handleDeleteInstallation(
                  modalManage.data.whichOne,
                  modalManage.modal === "slotter"
                )
              }
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
      <div
        className={`installation-planning 
          ${step === 1 ? "installation-planning--hover" : ""}
          ${
            isPlanningOpen && installationIndex > 0 && step === 2
              ? "installation-planning--hidden"
              : ""
          }`}
        onClick={() => step === 1 && handleClickCheckbox()}
      >
        {!isPlanningOpen && step === 1 && (
          <div className="installation-planning__top">
            <div className="installation-planning__left">
              <div className="installation-planning__label">
                <span>{details.counter}</span>
                {MEASUREMENTS_TYPE_TRANSLATIONS[details.type]}
              </div>
              <div className="installation-planning__numbers">
                <div className="installation-planning__number-item">
                  <SVG type={SVG_TYPE.CLOCK} /> {details.plannedAssemblyTime}h
                </div>
                <div className="installation-planning__number-item">
                  <SVG type={SVG_TYPE.USER} /> {details.numberOfAssemblyTeams}
                </div>
                {details.initialDate?.numberOfInstallationCrews && (
                  <div className="installation-planning__number-item">
                    <SVG type={SVG_TYPE.USERS_BOLD} />{" "}
                    {details.initialDate.numberOfInstallationCrews}
                  </div>
                )}
                {details.initialDate && (
                  <div className="installation-planning__initial-date">
                    <SVG type={SVG_TYPE.CALENDAR_SECOND} />{" "}
                    {formatDateRange(details.initialDate)}
                  </div>
                )}
              </div>
            </div>
            <input
              id={details.type}
              checked={isChecked}
              type="checkbox"
              className="checkbox-part"
            />
            <label
              htmlFor={details.type}
              className="installation-planning--checkbox checkbox-part-label"
            ></label>
          </div>
        )}

        {isPlanningOpen && step === 2 && installationIndex === 0 && (
          <>
            <div className="installation-planning__bottom">
              {initialValues.installations[0]?.dates?.map(
                (item: any, index: number) => {
                  const isLastElement =
                    initialValues.installations[0]?.dates?.length === index + 1;
                  const randomKey = getRandomKey();
                  return (
                    <>
                      <Field
                        key={randomKey}
                        errors={errors}
                        label="Termin"
                        size="medium"
                        name={`installations[${installationIndex}].dates[${index}].date`}
                        component={CalendarInput}
                        minDate={currentDate}
                        onChange={() => {
                          setTimeout(() => {
                            validateField(
                              `installations[${installationIndex}].dates[${index}].date`
                            );
                          }, 10);
                        }}
                      />
                      {errors?.installations?.[installationIndex]?.dates?.[
                        index
                      ]?.date && (
                        <div className="error">To pole jest wymagane</div>
                      )}
                      {item.teams.map((teams: any, ind: number) => {
                        const isStartError =
                          errors?.installations?.[installationIndex]?.dates?.[
                            index
                          ]?.teams?.[ind]?.start;
                        const isEndError =
                          errors?.installations?.[installationIndex]?.dates?.[
                            index
                          ]?.teams?.[ind]?.end;
                        const isTimeError = isStartError || isEndError;
                        const isEmployeeError =
                          errors?.installations?.[installationIndex]?.dates?.[
                            index
                          ]?.teams?.[ind]?.employeeUuids;
                        const nonFirstElement = ind !== 0;
                        const moreThanOne = item.teams.length > 1;

                        return (
                          <div
                            className={`installation-planning__team ${
                              nonFirstElement
                                ? "installation-planning__team--non-first-element"
                                : ""
                            }`}
                          >
                            <div
                              className={`amp__hours ${
                                isTimeError ? "amp__hours--error" : ""
                              }`}
                            >
                              <Field
                                label="Początek"
                                name={`installations[${installationIndex}].dates[${index}].teams[${ind}].start`}
                                required
                                component={Select}
                                disableMobileKeyboard
                                menuPlacement={isMobile() ? "top" : "bottom"}
                                errors={errors}
                                size="medium"
                                options={generateTimeOptions()}
                                onChange={() => {
                                  setTimeout(() => {
                                    validateField(
                                      `installations[${installationIndex}].dates[${index}].teams[${ind}].start`
                                    );
                                  }, 10);
                                }}
                              />
                              {isStartError && (
                                <div className="error">Wymagane</div>
                              )}
                              <div className="amp__hours-separate">-</div>
                              <Field
                                label="Koniec"
                                name={`installations[${installationIndex}].dates[${index}].teams[${ind}].end`}
                                disableMobileKeyboard
                                menuPlacement={isMobile() ? "top" : "bottom"}
                                required
                                size="medium"
                                component={Select}
                                errors={errors}
                                options={generateTimeOptions()}
                                onChange={() => {
                                  setTimeout(() => {
                                    validateField(
                                      `installations[${installationIndex}].dates[${index}].teams[${ind}].end`
                                    );
                                  }, 10);
                                }}
                              />
                              {isEndError && (
                                <div className="error error--end">Wymagane</div>
                              )}
                            </div>
                            <Field
                              label="Pracownik/cy"
                              name={`installations[${installationIndex}].dates[${index}].teams[${ind}].employeeUuids`}
                              component={MultipleSelect}
                              errors={errors}
                              options={usersSelect}
                              size="medium"
                              employee
                              required
                              multiple
                              onChange={() => {
                                setTimeout(() => {
                                  validateField(
                                    `installations[${installationIndex}].dates[${index}].teams[${ind}].employeeUuids`
                                  );
                                }, 10);
                              }}
                            />
                            {isEmployeeError && (
                              <div className="error">To pole jest wymagane</div>
                            )}
                            {moreThanOne && (
                              <div
                                className="installation-planning__delete-wrapper installation-planning__delete-wrapper--time-slotter "
                                onClick={() =>
                                  setModalManage({
                                    isOpen: true,
                                    modal: "slotter",
                                    data: {
                                      whichOne: index,
                                      whichOneTeam: ind,
                                      date: initialValues.installations[0]
                                        .dates[index].date,
                                      timeSlotter: `${initialValues.installations[0].dates[index].teams[ind].start} - ${initialValues.installations[0].dates[index].teams[ind].end}`,
                                    },
                                  })
                                }
                              >
                                <SVG
                                  className="installation-planning__delete-icon"
                                  type={SVG_TYPE.BIN}
                                />
                                <div className="installation-planning__delete-label">
                                  Usuń ten przedział czasowy
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <div className="installation-planning__actions">
                        <div
                          className="installation-planning__time-slotter"
                          onClick={() => handleAddTimeSlotter(index)}
                        >
                          <div className="installation-planning__time-slotter-label">
                            + Dodaj kolejny przedział czasowy
                          </div>
                        </div>
                        {initialValues.installations[0]?.dates.length > 1 && (
                          <div
                            className="installation-planning__delete-wrapper"
                            onClick={() =>
                              setModalManage({
                                isOpen: true,
                                modal: "deadline",
                                data: {
                                  whichOne: index,
                                  date: initialValues.installations[0].dates[
                                    index
                                  ].date,
                                },
                              })
                            }
                          >
                            <SVG
                              className="installation-planning__delete-icon"
                              type={SVG_TYPE.BIN}
                            />
                            <div className="installation-planning__delete-label">
                              Usuń ten termin
                            </div>
                          </div>
                        )}
                        {isLastElement ? (
                          <Button
                            onClick={handleAddDates}
                            className="installation-planning__new-date"
                            stroke
                            size="small"
                            label="Dodaj kolejny dzień"
                          />
                        ) : (
                          <>
                            <div className="am-separator installation-planning__separator installation-planning__separator--time-slotter" />
                          </>
                        )}
                      </div>
                    </>
                  );
                }
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InstallationPlanning;
