import "./order-status.scss";
import classNames from "classnames";
import { ORDER_ENUM, SVG_TYPE } from "enums";
import { hasOrderStatus } from "utils";
import { ContextMenu, SVG } from "components";
import { OrderType } from "types/OrderTypes";
import { useState } from "react";
import { ChangeOrderStatus } from "features/orders";
import { useSearchParams } from "react-router-dom";

interface OrderStatusType {
  status?: string;
  className?: string;
  listOfActivity?: string;
  isSubMenu?: boolean;
  orderDetails?: OrderType;
}

const OrderStatus = ({
  status,
  className = "",
  listOfActivity,
  isSubMenu = false,
  orderDetails,
}: OrderStatusType) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const isAdminList = tab === "orders";
  const [modalManage, setModalManage] = useState<any>({
    orderDetails: {},
    isOpen: false,
    status: "",
  });
  const isMeasurementActivity = listOfActivity === "measurement";
  const isInstallationActivity = listOfActivity === "installation";
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
    ARCHIVED,
  } = ORDER_ENUM;
  const isSubMenuActive =
    status === MEASUREMENTS_DONE && isSubMenu && !isAdminList;

  const green =
    hasOrderStatus(status, [
      FIXED_MEASUREMENTS,
      FIXED_INSTALLATION,
      FIXED_VERIFICATION,
    ]) || isMeasurementActivity;
  const red =
    hasOrderStatus(status, [
      MEASUREMENTS_TO_BE_RELEASED,
      VERIFICATION_TO_BE_RELEASED,
      INSTALLATION_TO_BE_RELEASED,
    ]) || isInstallationActivity;
  const gray = hasOrderStatus(status, [
    INSTALLATION_DONE,
    MEASUREMENTS_DONE,
    ARCHIVED,
  ]);

  const orderStatusClass = classNames("order-status", className, {
    "order-status--green": green,
    "order-status--red": red,
    "order-status--gray": gray,
    "order-status--sub-menu no-click": isSubMenuActive,
  });

  const changeStatusMenu = () => {
    const isVerified = !orderDetails?.isVerified;
    if (isVerified) {
      return [
        {
          name: "Weryfikacja do ustalenia",
          action: () => {
            setModalManage({
              isOpen: true,
              orderDetails: orderDetails,
              status: "Weryfikacja do ustalenia",
              orderStatus: ORDER_ENUM.VERIFICATION_TO_BE_RELEASED,
            });
          },
        },
        {
          name: "Pominięcie weryfikacji",
          action: () => {
            setModalManage({
              isOpen: true,
              orderDetails: orderDetails,
              status: "Pominięcie weryfikacji",
              orderStatus: ORDER_ENUM.INSTALLATION_TO_BE_RELEASED,
            });
          },
        },
      ];
    } else {
      return [
        {
          name: "Montaż do ustalenia",
          action: () => {
            setModalManage({
              isOpen: true,
              orderDetails: orderDetails,
              status: "Montaż do ustalenia",
              orderStatus: ORDER_ENUM.INSTALLATION_TO_BE_RELEASED,
            });
          },
        },
      ];
    }
  };

  return (
    <>
      <ChangeOrderStatus
        modalManage={modalManage}
        onClose={() =>
          setModalManage({ isOpen: false, orderDetails: orderDetails })
        }
      />
      <div className={orderStatusClass}>
        {isSubMenuActive ? (
          <>
            <ContextMenu
              className="order-status--sub-menu"
              items={changeStatusMenu()}
            >
              <div className="order-status__label label">Pomiar zakończony</div>
              <div className="order-status__icon">
                <SVG type={SVG_TYPE.CHEVRON_SMALL} />
              </div>
            </ContextMenu>
          </>
        ) : (
          <div className="order-status__label label">
            <>
              {status === MEASUREMENTS_TO_BE_RELEASED && "Pomiar do ustalenia"}
              {status === FIXED_MEASUREMENTS && "Pomiar ustalony"}
              {status === MEASUREMENTS_DONE && "Pomiar zakończony"}
              {status === INSTALLATION_TO_BE_RELEASED && "Montaż do ustalenia"}
              {status === VERIFICATION_TO_BE_RELEASED &&
                "Weryfikacja do ustalenia"}
              {status === FIXED_VERIFICATION && "Weryfikacja ustalona"}
              {status === FIXED_INSTALLATION && "Montaż ustalony"}
              {status === INSTALLATION_IN_PROGESS && "Montaż w trakcie"}
              {status === INSTALLATION_DONE && "Montaż zakończony"}
              {status === ARCHIVED && "Zarchiwizowane"}
              {isInstallationActivity && "Montaż"}
              {isMeasurementActivity && "Pomiar"}
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderStatus;
