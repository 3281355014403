import { ORDER_ENUM } from "enums";

const meetingAddress = (status: string) => {
  const { MEASUREMENTS_TO_BE_RELEASED, FIXED_MEASUREMENTS, VERIFICATION_TO_BE_RELEASED, FIXED_VERIFICATION, FIXED_INSTALLATION, INSTALLATION_IN_PROGESS, INSTALLATION_DONE } = ORDER_ENUM;

  switch (status) {
    case MEASUREMENTS_TO_BE_RELEASED:
    case FIXED_MEASUREMENTS:
      return 'Termin pomiaru';
    case VERIFICATION_TO_BE_RELEASED:
    case FIXED_VERIFICATION:
      return 'Termin weryfikacji';
    case FIXED_INSTALLATION:
    case INSTALLATION_IN_PROGESS:
    case INSTALLATION_DONE:
      return 'Termin montażu';
    default:
      return '';
  }
};

export default meetingAddress;
