import { useState } from "react";
import { Formik, Field } from "formik";
import { Button, Textarea, Select } from "components";
import { ScrollToError } from "utils";
import { VALIDATION_MESSAGES } from "enums";
import {
  TypeOfInstalationExternalDoorReplacement,
  SingleSectionElement,
  TypeOfRadiobox,
} from "features/addingMeasurement";
import { ExtraWork } from "features/addingMeasurement/ExternalDoorReplacement/components";
import { usePutExternalDoorReplacementClientInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

interface ExternalDoorReplacementGeneralInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorReplacementGeneralInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorReplacementGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const measurementClientInfo = measurementInfo?.measurementClientInfo;

  const { mutate: updateClientInfo } = usePutExternalDoorReplacementClientInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const handleOnSubmit = (values: any) => {
    updateClientInfo(values);
  };

  const typesOfSpoutLevelOptions = [
    {
      value: "actual",
      label: "Rzeczywisty",
    },
    {
      value: "specifiedByInvestor",
      label: "Określony przez inwestora",
    },
    {
      value: "specifiedByContractor",
      label: "Określony przez wykonawcę",
    },
  ];

  const validationSchema = Yup.object().shape({
    typeOfInstallations: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    spoutLevel: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    oldDoorType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          typeOfInstallations: measurementClientInfo?.typeOfInstallations || "",
          spoutLevel: measurementClientInfo?.spoutLevel || "",
          oldDoorType: measurementClientInfo?.oldDoorType || "",
          oldDoorDismantling:
            measurementClientInfo?.oldDoorDismantling || false,
          floorFurnitureProtection:
            measurementClientInfo?.floorFurnitureProtection || "none",
          constructionSiteCleaningInvestor:
            measurementClientInfo?.constructionSiteCleaningInvestor || false,
          comments: measurementClientInfo?.comments || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values, actions) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          // console.log(values, "wartości!!");
          if (submitCount > 0) {
            setIsValidated(true);
          }

          return (
            <form
              className="amfs__form form amfs__form--blinds"
              onSubmit={handleSubmit}
            >
              <ScrollToError />

              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallations"
                component={TypeOfInstalationExternalDoorReplacement}
              />
              <SingleSectionElement
                touched={touched}
                name="spoutLevel"
                errors={errors}
              >
                <Field
                  label="Poziom wylewki"
                  name="spoutLevel"
                  type="number"
                  options={typesOfSpoutLevelOptions}
                  component={Select}
                  white
                />
              </SingleSectionElement>

              <Field
                errors={errors}
                name="oldDoorType"
                label="Typ starych drzwi"
                half
                options={[
                  {
                    label: "Metalowe",
                    value: "metal",
                    uuid: "metal",
                  },
                  {
                    label: "PCV",
                    value: "pcv",
                    uuid: "pcv",
                  },
                  {
                    label: "Drewniane",
                    value: "wooden",
                    uuid: "wooden",
                  },
                  {
                    label: "Aluminiowe",
                    value: "aluminum",
                    uuid: "aluminum",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field isChecked={"sas"} values={values} component={ExtraWork} />
              <div className="am-separator" />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorReplacementGeneralInformation;
