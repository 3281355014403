/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { Pagination, UserStatus, SkeletonRow } from "components";
import { isMobile } from "utils";
import { useGetUsers } from "hooks/users";
// import { data } from "utils/userBase";
import "./users-table.scss";
import { UserType } from "types/UsersTypes";
import { useSearchParams } from "react-router-dom";

interface UsersTableType {
  blockUser: (user: UserType) => void;
  deleteUser: (user: UserType) => void;
  editUser: (user: UserType) => void;
  resendActivateMail: (email: string) => void;
  activeFloatingMenuItem: any;
  isAdminTab: boolean;
  page: number;
  setPage: (page: number) => void;
}

const UsersTable = ({
  resendActivateMail,
  blockUser,
  deleteUser,
  editUser,
  activeFloatingMenuItem,
  isAdminTab,
  setPage,
  page,
}: UsersTableType) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const userLocation = searchParams.get("location");
  const { data, refetch, isLoading } = useGetUsers(
    page,
    activeFloatingMenuItem.value || userLocation || "0"
  );

  useEffect(() => {
    refetch();
  }, [activeFloatingMenuItem]);

  const handleExpand = (expanded: any, record: any) => {
    if (isMobile()) {
      const keys = expanded ? [record.id] : [];
      setExpandedRowKeys(keys);
    }
  };

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("location", activeFloatingMenuItem.value);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="users-table">
      <>
        <Table
          rowKey="id"
          className="user-table"
          data={data?.data}
          columns={
            isMobile()
              ? mobileColumns(isAdminTab)
              : desktopColumns(
                  isAdminTab,
                  data?.meta?.currentPage || 0,
                  data?.meta?.perPage || 0,
                  blockUser,
                  deleteUser,
                  editUser,
                  resendActivateMail
                )
          }
          expandable={{
            showExpandColumn: false,
            expandRowByClick: true,
            expandedRowKeys,

            onExpand: handleExpand,
            expandedRowRender: (record) => (
              <div className="user-table-row__expand">
                <div className="user-table-row__expand-top">
                  <div className="user-table-row__expand-top-location">
                    {!isAdminTab && record.location}
                  </div>
                  <div className="user-table-row__expand-top-status">
                    {!isAdminTab && (
                      <>
                        <UserStatus status={record.status} />
                        {record.status === "active" ? "Aktywny" : "Zablokowany"}
                      </>
                    )}
                  </div>
                </div>
                <div className="user-table-row__expand-bottom">
                  {isAdminTab && record.status === "inactive" && (
                    <div
                      className="user-table-row__expand-item"
                      onClick={() => resendActivateMail(record.email)}
                    >
                      Wyślij link aktywacyjny
                    </div>
                  )}
                  <div
                    className="user-table-row__expand-item"
                    onClick={() => editUser(record)}
                  >
                    Edytuj
                  </div>
                  {record.status !== "inactive" && (
                    <div
                      className="user-table-row__expand-item"
                      onClick={() => blockUser(record)}
                    >
                      {record.status === "suspended" ? "Odblokuj" : "Zablokuj"}
                    </div>
                  )}
                  <div
                    className="user-table-row__expand-item"
                    onClick={() => deleteUser(record)}
                  >
                    Usuń
                  </div>
                </div>
              </div>
            ),
          }}
        />
        {isLoading && <SkeletonRow count={10} height={58} />}
        {data && data.data.length === 0 && (
          <div className="users-table__no-data">
            <div className="users-table__no-data--paragraph">
              Lista jest pusta
              <div className="users-table__no-data--paragraph">
                Kliknij w przycisk “Dodaj” aby utworzyć nowego uytkownika
              </div>
            </div>
          </div>
        )}
        {data?.meta && data?.meta?.lastPage > 1 && (
          <Pagination
            totalPages={data?.meta?.lastPage}
            currentPage={data?.meta?.currentPage || page}
            onPageChange={(e) => handleChangePage(e)}
          />
        )}
      </>
    </div>
  );
};

export default UsersTable;
