/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "axiosConfig";
import { MetaType } from "types/UtilsTypes";
import { OrderListType, OrderStatus } from "types/OrderTypes";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetOrders(status: OrderStatus, initialPage: number = 1, options = {}) {
  const [searchParams, setSearchParams] = useSearchParams();

  return useQuery({
    queryKey: ["orders", status, initialPage],
    queryFn: async () => {
      await delay(1000); 

      let currentPage = initialPage;
      let response;

      while (currentPage > 0) {
        response = await axiosInstance
          .get(`/orders?${status ? `status=${status}&` : ""}page=${currentPage}`)
          .then((res) => res.data);

        if (response.orders.data.length === 0 && currentPage > 1) {
          currentPage--;
        } else {
          if (currentPage !== initialPage) {
            setSearchParams({ page: currentPage.toString(), ...(status && { status }) });
          }
          break;
        }
      }

      return response;
    },
    select: (data: { orders: { data: OrderListType[], meta: MetaType }}) => data?.orders,
    retry: false,
    ...options,
  });
}
