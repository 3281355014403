/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useGetConstructionSites } from "hooks/measurements";
import { ConstructionSiteType } from "types/MeasurementTypes";
import { StaticAlert, CheckboxPart, Textarea } from "components";
import { Field } from "formik";
import "./construction-site.scss";

interface ConstructionSiteTypes {
  field: any;
  errors?: any;
  form: any;
  values: any;
  type?: string;
}

const ConstructionSite = ({
  field,
  form,
  errors,
  values,
  type,
}: ConstructionSiteTypes) => {
  const { data: constructionSites } = useGetConstructionSites(type);
  const [constructionSiteUuids, setConstructionSiteUuids] = useState<any>(
    values[field.name] || []
  );

  console.log(form.values[field.name]);

  useEffect(() => {
    setConstructionSiteUuids(values[field.name]);
  }, [values[field.name]]);
  
  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = constructionSiteUuids.find(
      (item: any) => item === uuid || item.uuid === uuid
    );
    const commentItem =
      constructionSites &&
      constructionSites.find(
        (item: any) => item.value === uuid && item.type === "comments"
      );
    if (!isUuidInArray) {
      const values = [
        ...constructionSiteUuids,
        commentItem
          ? { comment: form.values.constructionSiteComments || "", uuid }
          : uuid,
      ];
      setConstructionSiteUuids(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = constructionSiteUuids.filter(
        (item: any) => item !== uuid && item.uuid !== uuid
      );
      setConstructionSiteUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  const handleWriteComment = (e: any) => {
    const commentItem =
      constructionSites &&
      constructionSites.find((item: any) => item.type === "comments");

    if (commentItem) {
      const isCommentInArray = constructionSiteUuids.find(
        (item: any) => item.uuid === commentItem.value
      );
      let newConstructionSiteUuids;
      if (isCommentInArray) {
        newConstructionSiteUuids = constructionSiteUuids.map((item: any) =>
          item.uuid === commentItem.value ? { ...item, comment: e } : item
        );
      } else {
        newConstructionSiteUuids = [
          ...constructionSiteUuids,
          { comment: e, uuid: commentItem.value },
        ];
      }
      setConstructionSiteUuids(newConstructionSiteUuids);
      form.setFieldValue(field.name, newConstructionSiteUuids);
    }
  };

  const commentUuid =
    constructionSites &&
    constructionSites.length > 0 &&
    constructionSites.find((item: any) => item.type === "comments")?.value;

  const isActiveComment =
    form.values[field.name] &&
    form.values[field.name].length > 0 &&
    form.values[field.name].find(
      (test: any) => test === commentUuid || test.uuid === commentUuid
    );

  return (
    <div className="construction-site">
      <div className="am-section-heading">Teren budowy</div>
      <div className="am-section-checkbox-list construction-site__list">
        {constructionSites &&
          constructionSites.length > 0 &&
          constructionSites.map((item: ConstructionSiteType) => {
            return (
              <CheckboxPart
                checked={values?.constructionSiteUuids.find(
                  (element: any) =>
                    element === item.value || element.uuid === item.value
                )}
                key={item.value}
                label={item.type === "comments" ? "Inne" : item.label}
                uuid={item.value}
                onChange={(uuid) => handleClickCheckbox(uuid)}
              />
            );
          })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      {isActiveComment && (
        <Field
          name="constructionSiteComments"
          component={Textarea}
          maxLength={240}
          onChange={(e: any) => handleWriteComment(e)}
        />
      )}
      <div className="am-separator" />
    </div>
  );
};

export default ConstructionSite;
