import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums'; 
import { useNavigate } from 'react-router-dom';
import { ROUTE_ENUM } from "enums";

let lastLogoutTimestamp: number | null = null;

export const useLogout = (
  options?: UseMutationOptions<any, Error, void>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: ['logout'],
    mutationFn: async () => {
      const now = Date.now();

      if (lastLogoutTimestamp && now - lastLogoutTimestamp < 3000) {
        return Promise.reject(new Error('Duplicate logout request ignored'));
      }
      lastLogoutTimestamp = now;

      return axiosInstance.put('/auth/logout')
        .then((res) => res.data);
    },
    onMutate: () => {
      if (options && options.onMutate) {
        return options.onMutate();
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Pomyślnie wylogowano.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'logout',
      });

      const recoveryAgain = sessionStorage.getItem("loginAdmin");
      if (recoveryAgain) {
        navigate(ROUTE_ENUM.LOGIN);
        sessionStorage.removeItem("loginAdmin");
      } else {
        navigate(ROUTE_ENUM.HOME);
      }
      queryClient.clear();

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      if (error.message === 'Duplicate logout request ignored') {
        return; 
      }

      const recoveryAgain = sessionStorage.getItem("loginAdmin");
      if (recoveryAgain) {
        navigate(ROUTE_ENUM.LOGIN);
        sessionStorage.removeItem("loginAdmin");
      } else {
        navigate(ROUTE_ENUM.HOME);
      }
      queryClient.clear();

      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
