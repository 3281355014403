/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Input, StaticAlert, Select, Button, SVG, Textarea } from "components";
import { Field } from "formik";

import "./addition-side-window.scss";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";

interface AdditionSideWindowType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
  isErrorDimension: boolean;
  setFieldValue: any;
  setFieldError: any;
  windowReplacement: any;
}

const AdditionSideWindow = ({
  errors,
  form,
  field,
  label,
  isErrorDimension,
  validateField,
  setFieldValue,
  setFieldError,
  windowReplacement = false,
}: AdditionSideWindowType) => {
  const [isAdditionalComment, setIsAdditionalComment] = useState(false);

  // useEffect(() => {
  //   if (!isErrorDimension) {
  //     form.setFieldValue("warningComment", "");
  //     setIsAdditionalComment(false);
  //   }
  // }, [isErrorDimension]);

  useEffect(() => {
    if (form.values.warningComment) {
      setIsAdditionalComment(true);
    }
  }, [form.values.warningComment]);

  const windowSideOptions = [
    {
      value: "widening",
      label: "Poszerzenie",
    },
    {
      value: "miniConnector",
      label: "Łącznik mini",
    },
    {
      value: "90connector",
      label: "Łącznik 90",
    },
    {
      value: "pipeConnector",
      label: "Łącznik rurowy",
    },
    {
      value: "45connector",
      label: "Łącznik 45",
    },

    {
      value: "other",
      label: "Inne",
    },
  ];

  const handleWriteComment = (comment: string) => {
    form.setFieldValue("warningComment", comment);
    if (form.values.warningComment === "") {
      form.setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
    } else {
      form.setFieldError("warningComment", "");
    }
  };

  const handleChangeWindowAddition = (value: any) => {
    if (!value) {
      setFieldValue("windowSideAdditionSize", "");
      setFieldValue("windowSideAddition", "");
      setTimeout(() => {
        setFieldError("windowSideAdditionSize", "");
      }, 10);
    }
  };

  return (
    <div className="addition-side-window">
      {/* <div className="am-section-heading">
        {label}
        <span> (opcjonalne)</span>
      </div> */}
      <Field
        label="Dodatek bok okna"
        placeholder="Wybierz"
        name="windowSideAddition"
        onChange={(value: any) => handleChangeWindowAddition(value)}
        component={Select}
        optional
        options={windowSideOptions}
        white
      />
      {/* {form.values.windowSideAddition === "other" && (
        <Field name="windowSideAdditionComment" component={Textarea} />
      )} */}
      <Field
        label="Wymiar"
        name="windowSideAdditionSize"
        type="number"
        rightPlaceholder="mm"
        onChange={() =>
          validateField(
            windowReplacement ? "holeInternalWidth" : "holeSizeWidth"
          )
        }
        component={Input}
      />
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              (errors["windowSideAddition"] && errors["windowSideAddition"]) ||
              (errors["windowSideAdditionSize"] &&
                errors["windowSideAdditionSize"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      {isErrorDimension && (
        <>
          <div className="error-dimension">
            <p>
              Sprawdź, czy szerokość/wysokość jest prawidłowa. Jeśli tak, to
              napisz wyjaśnienie w komentarzu.
            </p>
            {(form.values.file.length === 0 ||
              form.values.warningComment === "") && (
              <>
                {!isAdditionalComment && (
                  <Button
                    className="error-dimensions__button"
                    size="small"
                    secondary
                    stroke
                    label="Dodaj komentarz"
                    onClick={() => setIsAdditionalComment(true)}
                  />
                )}
              </>
            )}
            <SVG type={SVG_TYPE.EXCLAMATION} />
          </div>
          {isAdditionalComment && (
            <>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Komentarz wyjaśniający"
                name="warningComment"
                component={Textarea}
                maxLength={240}
                onChange={(e: string) => handleWriteComment(e)}
              />
            </>
          )}
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default AdditionSideWindow;
