import { Input, StaticAlert, Select } from "components";
import { Field } from "formik";

import "./addition-bottom-window.scss";

interface AdditionBottomWindowType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
  setFieldValue: any;
  setFieldError: any;
  windowReplacement: any;
}

const AdditionBottomWindow = ({
  errors,
  form,
  field,
  label,
  validateField,
  setFieldValue,
  setFieldError,
  windowReplacement = false,
}: AdditionBottomWindowType) => {
  const windowBottomOptions = [
    {
      value: "clinarite",
      label: "Klinaryt",
    },
    {
      value: "systemicExpansion",
      label: "Poszerzenie systemowe",
    },
    {
      value: "warmWindowSill",
      label: "Ciepły parapet",
    },
    {
      value: "undersillStrip",
      label: "Listwa podparapetowa",
    },
    {
      value: "purenit",
      label: "Purenit",
    },
    {
      value: "neo",
      label: "Neo",
    },
    {
      value: "other",
      label: "Inne",
    },
  ];

  const handleChangeWindowAddition = (value: any) => {
    if (!value) {
      setFieldValue("windowBottomAdditionSize", "");
      setFieldValue("windowBottomAddition", "");
      setTimeout(() => {
        setFieldError("windowBottomAdditionSize", "");
      }, 10);
    }
  };

  return (
    <div className="addition-bottom-window">
      {/* <div className="am-section-heading">
        {label}
        <span> (opcjonalne)</span>
      </div> */}
      <Field
        label="Dodatek dół okna"
        placeholder="Wybierz"
        optional
        name="windowBottomAddition"
        onChange={(value: any) => handleChangeWindowAddition(value)}
        component={Select}
        options={windowBottomOptions}
        white
      />
      {/* {form.values.windowBottomAddition === "other" && (
        <Field name="windowBottomAdditionComment" component={Textarea} />
      )} */}
      <Field
        label="Wymiar"
        name="windowBottomAdditionSize"
        type="number"
        rightPlaceholder="mm"
        onChange={() =>
          validateField(
            windowReplacement ? "holeInternalHeight" : "holeSizeHeight"
          )
        }
        component={Input}
      />
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              (errors["windowBottomAddition"] &&
                errors["windowBottomAddition"]) ||
              (errors["windowBottomAdditionSize"] &&
                errors["windowBottomAdditionSize"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default AdditionBottomWindow;
