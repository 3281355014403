/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button, Input, SVG } from "components";
import { FieldArray, Field } from "formik";
import { SVG_TYPE } from "enums";
import "./measurement-area.scss";

interface MeasurementAreaProps {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  measurementConfig: {
    type: string; // Typ pomiaru np. "Powierzchnia podłogi" lub "Listwy przyścienne"
    unit: string; // Jednostka np. "m2" lub "m"
    fieldNames: {
      values: string; // Nazwa pola dla tablicy wartości np. "floorSpaceToBeTotaled" lub "wallStrips"
      total: string; // Nazwa pola dla sumy np. "floorSpace" lub "wallStripsSpace"
      reserve: string; // Nazwa pola dla zapasu np. "reserve" lub "wallStripsReserve"
      totalWithReserve: string; // Nazwa pola dla sumy z zapasem np. "floorSpaceWithReserve" lub "wallStripsSpaceWithReserve"
    };
    labels: {
      title: string; // Etykieta sekcji np. "Powierzchnia podłogi"
      reserve: string; // Etykieta pola dla zapasu
      totalWithReserve: string; // Etykieta pola dla sumy z zapasem
    };
  };
}

const MeasurementArea = ({
  errors,
  form,
  validateField,
  field,
  measurementConfig,
}: MeasurementAreaProps) => {
  const { fieldNames, labels, unit } = measurementConfig;

  useEffect(() => {
    const totalMeasurement = form.values[fieldNames.values]
      .map((value: string) => parseFloat(value) || 0)
      .reduce((acc: number, curr: number) => acc + curr, 0);

    form.setFieldValue(fieldNames.total, Number(totalMeasurement.toFixed(2)));

    const reserve = parseFloat(form.values[fieldNames.reserve]) || 0;
    const measurementWithReserve = totalMeasurement * (1 + reserve / 100);

    form.setFieldValue(
      fieldNames.totalWithReserve,
      Number(measurementWithReserve.toFixed(2))
    );
  }, [
    form.values[fieldNames.values],
    form.values[fieldNames.reserve],
    form.setFieldValue,
  ]);

  return (
    <div className="hole-dimension measurement-area">
      <div>
        <FieldArray
          name={fieldNames.values}
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading">
                <h3>{labels.title}</h3>
              </div>
              <div className="measurement-area__equation">
                {form.values?.[fieldNames.values].map(
                  (_: any, index: number) => (
                    <div
                      className="measurement-area__equation-item"
                      key={index}
                    >
                      <span>
                        <SVG type={SVG_TYPE.PLUS} />
                      </span>
                      <Field
                        errors={errors}
                        maxLength={8}
                        withDot
                        name={`${fieldNames.values}.${index}`}
                        rightPlaceholder={unit}
                        type="number"
                        component={Input}
                      />
                      {form.values?.[fieldNames.values].length > 2 && (
                        <div
                          className="measurement-area__equation-item-delete"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <SVG type={SVG_TYPE.BIN} />
                        </div>
                      )}
                    </div>
                  )
                )}
                <div className="measurement-area__equation-item">
                  <span>
                    <SVG type={SVG_TYPE.EQUAL} />
                  </span>
                  <Field
                    errors={errors}
                    maxLength={10}
                    withDot
                    name={fieldNames.total}
                    rightPlaceholder={unit}
                    component={Input}
                    disabled
                  />
                </div>
              </div>
              <Button
                stroke
                label="Dodaj kolejną wartość"
                size="small"
                className="measurement-area__add-button"
                onClick={() => {
                  arrayHelpers.push("");
                }}
              />
            </div>
          )}
        />
        <div className="measurement-area__ended">
          <Field
            label={labels.reserve}
            maxLength={2}
            rightPlaceholder="%"
            name={fieldNames.reserve}
            type="number"
            component={Input}
          />
          <Field
            label={labels.totalWithReserve}
            maxLength={10}
            name={fieldNames.totalWithReserve}
            disabled
            withDot
            component={Input}
          />
        </div>
      </div>
    </div>
  );
};

export default MeasurementArea;
