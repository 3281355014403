import { VerifiedMeasurement } from "types/OrderTypes";
import { OrderType, ArchivalDateType } from "types/OrderTypes";
import { ARCHIVAL_TYPE, ORDER_ENUM, ROUTE_ENUM } from "enums";
import { CompletedMeasurements } from "features/orders";
import { hasOrderStatus } from "utils";
import { meetingAddress } from "../utils";
import { Button } from "components";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface ArchivalDatesDetailsProps {
  orderDetails: OrderType;
  user: any;
  activeOrder?: boolean;
}

const ArchivalDatesDetails = ({
  orderDetails,
  user,
  activeOrder = false,
}: ArchivalDatesDetailsProps) => {
  const userRole = user?.role;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const { ACTIVE_ORDER } = ROUTE_ENUM;
  const isUserVerification = user?.uuid === orderDetails?.employee?.uuid;
  const isMeasurementsAttempt = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_ATTEPMT
  );
  const isMeasurementsTaken = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_TAKEN
  );
  const isVerificationVisit = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.VERIFICATION_VISIT
  );
  const isInstallationAttempt = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.INSTALLATION_ATTEMPT
  );
  const isAdditionalVisit = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.ADDITIONAL_VISIT
  );
  const orderStatus = orderDetails?.status;
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    FIXED_VERIFICATION,
    VERIFICATION_TO_BE_RELEASED,
    ARCHIVED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
  } = ORDER_ENUM;

  const isShowInstallationAttempt =
    orderStatus !== MEASUREMENTS_TO_BE_RELEASED &&
    orderStatus !== MEASUREMENTS_DONE &&
    orderStatus !== FIXED_MEASUREMENTS;

  const showVerification = hasOrderStatus(orderDetails.status, [
    FIXED_VERIFICATION,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    ARCHIVED,
  ]);
  const isMeasurementDate = orderStatus !== MEASUREMENTS_TO_BE_RELEASED;
  const isCheckListAvailable = !!(
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.length > 0
  );
  const showAssemblyDate =
    orderDetails.date && isMeasurementDate && !isCheckListAvailable;

  const isNotInstallation =
    orderDetails.status !== FIXED_INSTALLATION &&
    orderDetails.status !== INSTALLATION_IN_PROGESS &&
    orderDetails.status !== INSTALLATION_DONE &&
    orderDetails.status !== MEASUREMENTS_TO_BE_RELEASED;

  const isAdditionalInstallation =
    orderDetails &&
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.length > 0;

  const handleClickMeasurement = () => {
    navigate(
      `${ACTIVE_ORDER}?orderUuid=${orderDetails.uuid}${
        page ? `&page=${page}` : ""
      }`
    );
  };

  return (
    <div className="archival-dates">
      {isMeasurementsAttempt && isMeasurementsAttempt.length > 0 && (
        <>
          <div className="modal__information-header">
            Nieudane próby wykonania pomiaru
          </div>
          {isMeasurementsAttempt.map(
            ({ date, start, end, employees, comment }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="failed-meeting-attempt__separator" />
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Komentarz:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {comment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {!activeOrder &&
        showAssemblyDate &&
        !isVerificationVisit &&
        isNotInstallation &&
        meetingAddress(orderDetails?.status) !== "" && (
          <>
            <div className="modal__information-header">
              {meetingAddress(orderDetails?.status)}
            </div>
            <div className="modal__information-descript">
              {orderDetails.date ? (
                <>
                  {orderDetails.date}, godz. {orderDetails.start}-
                  {orderDetails.end}
                </>
              ) : (
                "-"
              )}
            </div>
          </>
        )}
      {isMeasurementsTaken && isMeasurementsTaken.length > 0 && (
        <>
          <div className="modal__information-header">
            Data wykonania pomiaru
          </div>
          {isMeasurementsTaken.map(
            ({ date, start, end, employees }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {isVerificationVisit &&
        isNotInstallation &&
        meetingAddress(orderDetails?.status) !== "" && (
          <>
            <div className="modal__information-header">
              {meetingAddress(orderDetails?.status)}
            </div>
            <div className="modal__information-descript">
              {orderDetails.date ? (
                <>
                  {orderDetails.date}, godz. {orderDetails.start}-
                  {orderDetails.end}
                </>
              ) : (
                "-"
              )}
            </div>
          </>
        )}
      <CompletedMeasurements userRole={userRole} orderDetails={orderDetails} />
      {isVerificationVisit && isVerificationVisit.length > 0 && (
        <>
          <div className="modal__information-header">Weryfikacja</div>
          {isVerificationVisit.map(
            ({ date, start, end, employees }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {orderDetails?.verificationParams &&
        orderDetails?.verificationParams?.length > 0 &&
        showVerification && (
          <>
            {orderDetails.comments ? (
              <>
                <div className="modal__information-header">
                  Uwagi od grafikowca
                </div>
                <div className="modal__information-descript">
                  {orderDetails.comments}
                </div>
              </>
            ) : null}
            <div className="modal__information-header determined-and-arranged-installation__header">
              Do weryfikacji
              {isUserVerification && (
                <Button
                  size="small"
                  label="Wykonaj"
                  onClick={() => handleClickMeasurement()}
                />
              )}
            </div>

            <div className="verified-list">
              {orderDetails.verificationParams.map(
                (item: VerifiedMeasurement) => {
                  return (
                    <>
                      <div className="verified-list__measurement">
                        {item.label}
                      </div>
                      {item.toFix &&
                        item.toFix.length > 0 &&
                        item.toFix.map((fix) => (
                          <div className="verified-list__toFix">
                            {typeof fix === "string" ? fix : fix.label}
                          </div>
                        ))}
                    </>
                  );
                }
              )}
            </div>
          </>
        )}
      {isShowInstallationAttempt &&
        isInstallationAttempt &&
        isInstallationAttempt.length > 0 && (
          <>
            <div className="modal__information-header">
              Nieudane próby wykonania montażu
            </div>
            {isInstallationAttempt.map(
              ({ date, start, end, employees, comment }: ArchivalDateType) => {
                return (
                  <div className="failed-meeting-attempt">
                    <div className="failed-meeting-attempt__content">
                      <div className="failed-meeting-attempt__section">
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Termin:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {date}, godz: {start} - {end}
                          </div>
                        </div>
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Pracownik:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {employees?.map(
                              ({ firstName, lastName }, index) => {
                                const isMoreThanOneAndNotLast =
                                  index + 1 < employees.length;
                                return (
                                  <>
                                    {firstName} {lastName}
                                    {isMoreThanOneAndNotLast ? ", " : ""}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {comment && (
                        <>
                          <div className="failed-meeting-attempt__separator" />
                          <div className="failed-meeting-attempt__section">
                            <div className="failed-meeting-attempt__line">
                              <div className="failed-meeting-attempt__label">
                                Komentarz:
                              </div>
                              <div className="failed-meeting-attempt__value">
                                {comment}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </>
        )}

      {!isAdditionalInstallation &&
        isAdditionalVisit &&
        isAdditionalVisit.length > 0 && (
          <>
            <div className="modal__information-header">
              Montaże uzupełniające
            </div>
            {isAdditionalVisit.map(
              ({ date, start, end, employees }: ArchivalDateType) => {
                return (
                  <div className="failed-meeting-attempt">
                    <div className="failed-meeting-attempt__content">
                      <div className="failed-meeting-attempt__section">
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Termin:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {date}, godz: {start} - {end}
                          </div>
                        </div>
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Pracownik:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {employees?.map(({ firstName, lastName }) => {
                              return (
                                <>
                                  {firstName} {lastName}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </>
        )}
    </div>
  );
};

export default ArchivalDatesDetails;
