/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal, Button, SVG, StaticAlert, Signature } from "components";
import { SVG_TYPE } from "enums";
import moment from "moment";
import "./receipt-protocol.scss";
import {
  OrderType,
  MeasurementsArrangedInstallationsType,
} from "types/OrderTypes";
import { useApproveInstallationProtocol } from "hooks/orders";

interface CheckListItem {
  label: string;
  value: string;
  required: boolean;
  checked: boolean;
}

interface Measurement {
  measurementName: string;
  typeOfMeasurementId: number;
  checkList: CheckListItem[];
}

interface ReceiptProtocolProps {
  isOpen: boolean;
  onClose: () => void;
  checkList: Measurement[];
  orderDetails: OrderType;
  groupInstallation: MeasurementsArrangedInstallationsType | undefined;
  newCustomerComment: string;
  groupUuid: string | null;
}

const ReceiptProtocol = ({
  isOpen,
  onClose,
  checkList,
  orderDetails,
  groupInstallation,
  newCustomerComment,
  groupUuid,
}: ReceiptProtocolProps) => {
  const { name, phoneNumber, email, address, clientName } = orderDetails;
  const { customerComments, itemsLeft, dates } = groupInstallation || {
    customerComment: "-",
    dates: [],
  };
  const [isClientSignatureValid, setIsClientSignatureValid] = useState(false);
  const [isEmployeeSignatureValid, setIsEmployeeSignatureValid] =
    useState(false);
  const [wasClientPresent, setWasClientPresent] = useState(false);
  const [isSignatureError, setIsSignatureError] = useState(false);
  const [isClientSignatureEmpty, setIsClientSignatureEmpty] = useState(true);
  const [isEmployeeSignatureEmpty, setIsEmployeeSignatureEmpty] =
    useState(true);
  const [clientSignatureFile, setClientSignatureFile] = useState<File | null>(
    null
  );
  const [employeeSignatureFile, setEmployeeSignatureFile] =
    useState<File | null>(null);
  const filterCheckedItems = (checkLists: Measurement[]): Measurement[] => {
    return checkLists.map((measurement) => {
      const filteredCheckList = measurement.checkList.filter(
        (item) => item.checked
      );
      return {
        ...measurement,
        checkList: filteredCheckList,
      };
    });
  };
  const { mutate: approveProtocol } = useApproveInstallationProtocol({
    onSuccess: () => {
      onClose();
    },
  });

  const protocolDate = moment().format("D/MM/YYYY");

  const handleClickCheckbox = () => {
    setWasClientPresent(!wasClientPresent);
    setIsClientSignatureValid(false);
    if (!wasClientPresent) {
      setIsSignatureError(false);
      clearClientSignature();
    }
  };

  const clearClientSignature = () => {
    setIsClientSignatureEmpty(true);
  };

  const validateSignatures = () => {
    const isEmployeeSignatureValid = !isEmployeeSignatureEmpty;
    const isClientSignatureValid = wasClientPresent || !isClientSignatureEmpty;
    return isEmployeeSignatureValid && isClientSignatureValid;
  };

  useEffect(() => {
    if (isSignatureError && validateSignatures()) {
      setIsSignatureError(false);
    }
  }, [isClientSignatureEmpty, isEmployeeSignatureEmpty]);

  const handleSaveProtocol = () => {
    const isClientSignatureValid = wasClientPresent || !isClientSignatureEmpty;
    const isEmployeeSignatureValid = !isEmployeeSignatureEmpty;
    setIsClientSignatureValid(!isClientSignatureValid);
    setIsEmployeeSignatureValid(!isEmployeeSignatureValid);
    if (!isClientSignatureValid || !isEmployeeSignatureValid) {
      setIsSignatureError(true);
    } else {
      setIsSignatureError(false);
      if (employeeSignatureFile && clientSignatureFile && groupUuid) {
        console.log("tutaj2");
        approveProtocol({
          groupUuid: groupUuid,
          signatureContractor: employeeSignatureFile,
          signatureCustomer: clientSignatureFile,
        });
      } else if (employeeSignatureFile && groupUuid) {
        approveProtocol({
          groupUuid: groupUuid,
          signatureContractor: employeeSignatureFile,
        });
      }
    }
  };

  const getSignatureErrorMessage = (): string => {
    const isEmployeeSignatureMissing = isEmployeeSignatureEmpty;
    const isClientSignatureMissing =
      !wasClientPresent && isClientSignatureEmpty;

    if (isEmployeeSignatureMissing && isClientSignatureMissing) {
      return "To pole jest wymagane. Uzupełnij podpisy";
    }
    if (isClientSignatureMissing) {
      return "To pole jest wymagane. Uzupełnij podpis lub zaznacz “Brak klienta na odbiorze”.";
    }
    if (isEmployeeSignatureMissing) {
      return "To pole jest wymagane. Uzupełnij podpis";
    }
    return "";
  };

  interface DateObject {
    date: string;
    teams: any[];
  }

  const getBoundaryDate = (
    dates: DateObject[],
    type: string
  ): string | null => {
    if (!Array.isArray(dates) || dates.length === 0) {
      return null;
    }
    const sortedDates = [...dates].sort((a, b) => {
      const dateA = new Date(a.date.split("/").reverse().join("-"));
      const dateB = new Date(b.date.split("/").reverse().join("-"));
      return dateA.getTime() - dateB.getTime();
    });

    if (type === "firstDay") {
      return sortedDates[0].date;
    } else if (type === "lastDay") {
      return sortedDates[sortedDates.length - 1].date;
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal className="full-page am" isOpened={isOpen} onClose={onClose}>
        <div className="am__content">
          <div className="am-top">
            <Button
              label="Zamknij"
              stroke
              size="medium"
              svgType={SVG_TYPE.CLOSE}
              onClick={() => {
                onClose();
                setIsSignatureError(false);
              }}
            />
          </div>
          <div className="receipt-protocol">
            <div className="receipt-protocol__top">
              <div className="receipt-protocol__heading">
                Protokół odbioru montażu
              </div>
              <div className="receipt-protocol__name">{name}</div>
            </div>
            <div className="receipt-protocol__content">
              <div className="receipt-protocol__halfs">
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Zamawiajcy
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {clientName ||
                      "Brak informacji o imieniu i nazwisku klienta"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {phoneNumber || "Brak telefonu"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {email || "Brak informacji o mailu"}
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    {address || "Brak danych adresowych"}
                  </div>
                </div>
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Wykonawca
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    Martdom Marcin Cichy
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    +48 660 905 085
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    biuro@martdom.pl
                  </div>
                  <div className="receipt-protocol__box__paragraph">
                    ul. 29 Listopada 22 <br /> 32-090 Słomniki
                  </div>
                </div>
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heading">
                  Wykonane prace
                </div>
                {checkList &&
                  filterCheckedItems(checkList).map(
                    ({ measurementName, checkList }, index) => {
                      const IsSecondOrLaterElement = index >= 1;
                      return (
                        <>
                          {IsSecondOrLaterElement && (
                            <div className="am-separator" />
                          )}
                          <div className="receipt-protocol__checklist__element">
                            {measurementName}
                          </div>
                          {checkList.map(({ label }) => {
                            return (
                              <div className="receipt-protocol__checklist__element">
                                <div className="receipt-protocol__checklist__icon">
                                  <SVG type={SVG_TYPE.CHECK_CIRCLE} />
                                </div>
                                {label}
                              </div>
                            );
                          })}
                        </>
                      );
                    }
                  )}
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heaading">
                  Uwagi od klienta
                </div>
                <div className="receipt-protocol__checklist__element">
                  {newCustomerComment || customerComments || "-"}
                </div>
              </div>
              <div className="receipt-protocol__halfs">
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Termin wykonania montażu
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    Rozpoczęcie robót: {getBoundaryDate(dates, "firstDay")}
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    Zakończenie robót: {getBoundaryDate(dates, "lastDay")}
                  </div>
                </div>
                <div className="receipt-protocol__box receipt-protocol__half">
                  <div className="receipt-protocol__box__heading">
                    Data sporządzenia protokołu
                  </div>
                  <div className="receipt-protocol__checklist__element">
                    {protocolDate}
                  </div>
                </div>
              </div>
              <div className="receipt-protocol__box">
                <div className="receipt-protocol__box__heading">
                  Lista rzeczy pozostawionych u klienta
                </div>
                {itemsLeft && itemsLeft?.length > 0 ? (
                  <ul className="receipt-protocol__list">
                    {itemsLeft?.map(({ name }, index) => {
                      return (
                        <li className="receipt-protocol__list-item">{name}</li>
                      );
                    })}
                  </ul>
                ) : (
                  "-"
                )}
              </div>
              <div className="receipt-protocol__rules">
                Zamawiający oświadcza, że dokładnie sprawdził zamontowaną
                stolarkę i nie stwierdza żadnych uszkodzeń mechanicznych
                produktów. Późniejsze zgłoszenia wad produktu, widocznych w
                momencie odbioru stolarki, nie będą uznawane.
              </div>
              <div className="receipt-protocol__signature-box">
                <Signature
                  type="client"
                  onCanvasStateChange={setIsClientSignatureEmpty}
                  isActiveClear={isClientSignatureEmpty}
                  clearCanvasTrigger={clearClientSignature}
                  isValid={isClientSignatureValid}
                  wasClientPresent={wasClientPresent}
                  setValidation={setIsClientSignatureValid}
                  onExport={setClientSignatureFile}
                />
                <StaticAlert
                  show={isSignatureError}
                  label={getSignatureErrorMessage()}
                />
                <div className="receipt-protocol__client">
                  <input
                    name="client"
                    id="client"
                    type="checkbox"
                    className="receipt-protocol__checkbox"
                    defaultChecked={wasClientPresent}
                    onClick={handleClickCheckbox}
                  />
                  <label htmlFor="client">Brak klienta na odbiorze</label>
                </div>
                <Signature
                  type="employee"
                  onCanvasStateChange={setIsEmployeeSignatureEmpty}
                  isValid={isEmployeeSignatureValid}
                  setValidation={setIsEmployeeSignatureValid}
                  onExport={setEmployeeSignatureFile}
                />
              </div>

              <div className="receipt-protocol__buttons">
                <Button label="Zapisz protokół" onClick={handleSaveProtocol} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReceiptProtocol;
