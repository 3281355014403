import { useState } from "react";
import Table from "rc-table";
import "./employee-rates-table.scss";
import { useGetUserRates } from "hooks/employee-rates";
import { Pagination, SkeletonRow } from "components";
import { isMobile } from "utils";
import { mobileColumns, desktopColumns } from "./utils";
import { RateInfoType } from "types/EmployeeRatesTypes";
import { useSearchParams } from "react-router-dom";

interface EmployeeRatesTableType {
  userRole: string;
  setEditUserRate: (userRateInfo: RateInfoType) => void;
  setShowRateHistory: (userRateInfo: RateInfoType) => void;
  setPage: (page: number) => void;
  page: number;
}

const EmployeeRatesTable = ({
  userRole,
  setEditUserRate,
  setShowRateHistory,
  page,
  setPage,
}: EmployeeRatesTableType) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const { data: userRates, isLoading } = useGetUserRates(userRole, page);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleExpand = (expanded: any, record: any) => {
    if (isMobile()) {
      const keys = expanded ? [record.id] : [];
      setExpandedRowKeys(keys);
    }
  };

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="users-table">
      <Table
        rowKey="id"
        className="user-table"
        data={userRates?.data}
        columns={
          isMobile()
            ? mobileColumns()
            : desktopColumns(
                userRates?.meta?.currentPage || 0,
                userRates?.meta?.perPage || 0,
                setEditUserRate,
                setShowRateHistory
              )
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: true,
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender: (record) => (
            <div className="user-table-row__expand">
              <div className="user-table-row__expand-top"></div>
              <div className="user-table-row__expand-bottom">
                <div
                  className="user-table-row__expand-item"
                  onClick={() => setEditUserRate(record)}
                >
                  Edytuj
                </div>
                <div
                  className="user-table-row__expand-item"
                  onClick={() => setShowRateHistory(record)}
                >
                  Historia stawek
                </div>
              </div>
            </div>
          ),
        }}
      />
      {isLoading && <SkeletonRow count={10} height={58} />}
      {userRates && userRates.data.length === 0 && (
        <div className="users-table__no-data">
          <div className="users-table__no-data--paragraph">
            Lista stawek jest pusta
            <div className="users-table__no-data--paragraph">
              Dodaj pracownika żeby zobaczyć jego stawkę na liście
            </div>
          </div>
        </div>
      )}
      {userRates?.meta && userRates?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={userRates?.meta?.lastPage}
          currentPage={userRates?.meta?.currentPage || page}
          onPageChange={(e) => handleChangePage(e)}
        />
      )}
    </div>
  );
};

export default EmployeeRatesTable;
