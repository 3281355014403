import { Field, Formik } from "formik";
import { Button, Modal, Input, Select } from "components";
import { VALIDATION_MESSAGES } from "enums";
import * as Yup from "yup";
import { useAddUsedAssemblyMaterial } from "hooks/assembly-materials";
import { useSearchParams } from "react-router-dom";

interface AddingUsedAssemblyMaterialType {
  isOpen: boolean;
  onClose: () => void;
  usedAssemblyMaterialLabel: { label: string; value: string }[] | undefined;
}

const AddingUsedAssemblyMaterial = ({
  isOpen,
  onClose,
  usedAssemblyMaterialLabel,
}: AddingUsedAssemblyMaterialType) => {
  const { mutate: addUsedAssemblyMaterial } = useAddUsedAssemblyMaterial({
    onSuccess: () => {
      onClose();
    },
  });
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");

  const initialValues = {
    count: "",
    assemblyUuid: "",
    orderUuid: orderUuid,
  };

  const validationSchema = Yup.object().shape({
    count: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    assemblyUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = async (values: any) => {
    await addUsedAssemblyMaterial(values);
  };

  return (
    <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
      <>
        <div className="modal-heading">Dodawanie zużytego materiału</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => handleOnSubmit(values)}
        >
          {({ handleSubmit, errors }) => {
            return (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Materiał"
                  name="assemblyUuid"
                  maxLength={20}
                  component={Select}
                  options={usedAssemblyMaterialLabel}
                />
                <Field
                  errors={errors}
                  label="Ilość"
                  name="count"
                  component={Input}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.,]/g, "") // Tylko cyfry, przecinek i kropka
                      .replace(/,/g, ".") // Zamienia przecinek na kropkę (jeśli chcesz tylko kropki)
                      .replace(/(\..*)\./g, "$1") // Usuwa dodatkowe kropki
                      .replace(/^(\d{0,6})(\.\d{0,2})?.*/, "$1$2"); // Maksymalnie 6 cyfr przed kropką i 2 po kropce
                  }}
                />

                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    </Modal>
  );
};

export default AddingUsedAssemblyMaterial;
