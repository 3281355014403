import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type ExternalDoorsReplacementInstallationInfoData = {
  measurementUuid: string;
  roofOverTheDoor: boolean;
  plannedAssemblyTime: number;
  numberOfAssemblyTeams: number;
};

const usePutExternalDoorsReplacementInstallationInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorsReplacementInstallationInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsReplacementInstallationInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/external-doors-replacement/installation-info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych instalacji drzwi...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-installation-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane instalacji drzwi zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-installation-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsReplacementInstallationInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych instalacji drzwi.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-installation-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorsReplacementInstallationInfo;
