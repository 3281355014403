import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  TypeOfRadiobox,
} from "features/addingMeasurement";
import { Button, Textarea, Select, Input } from "components";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import {
  usePutRollerBlindsAndBlindsInfo,
  useStartMeasurement,
} from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import { useNavigate } from "react-router";

interface BlindsAndShuttersMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const BlindsAndShuttersMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: BlindsAndShuttersMeasurementValuesType) => {
  const navigate = useNavigate();
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const [isAddNew, setIsAddNew] = useState(false);

  const initialMeasurementInfo = measurementInfo?.measurementInfo;

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}&page=${page}`);
  };

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const { mutate: editInfo } = usePutRollerBlindsAndBlindsInfo({
    onSuccess: () => {
      if (isAddNew) {
        setIsAddNew(false);
        startMeasurement.mutate({
          typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
          orderUuid,
        });
      } else {
        goToList();
      }
    },
  });

  const validationSchema = Yup.object().shape({
    productKind: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    productType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    width: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    height: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    cableExitSide: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    typeOfDrive: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    assemblyType: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    sideOfThePowerCable: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = async (values: any) => {
    await editInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          productKind: initialMeasurementInfo?.productKind || "",
          productType: initialMeasurementInfo?.productType || "",
          color: initialMeasurementInfo?.color || "",
          comments: initialMeasurementInfo?.comments || "",
          width: initialMeasurementInfo?.width || "",
          height: initialMeasurementInfo?.height || "",
          cableExitSide: initialMeasurementInfo?.cableExitSide || "",
          typeOfDrive: initialMeasurementInfo?.typeOfDrive || "",
          assemblyType: initialMeasurementInfo?.assemblyType || "",
          sideOfThePowerCable:
            initialMeasurementInfo?.sideOfThePowerCable || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, submitCount, touched, values }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                touched={touched}
                name="productKind"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Rodzaj produktu"
                  name="productKind"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "rollerBlinds", label: "Rolety" },
                    { value: "blinds", label: "Żaluzje" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="productType"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Typ produktu"
                  name="productType"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "standard", label: "Standardowa" },
                    { value: "flushMounted", label: "Podtynkowa" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator single-section-element--strips"
              >
                <Field
                  optional
                  label="Kolor"
                  maxLength={30}
                  name="color"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="width"
                errors={errors}
              >
                <Field
                  label="Szerokość"
                  name="width"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="height"
                errors={errors}
              >
                <Field
                  label="Wysokość"
                  name="height"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                name="cableExitSide"
                label="Strona wyjścia kabla"
                options={[
                  {
                    label: "Bok",
                    value: "side",
                    uuid: "side",
                  },
                  {
                    label: "Tył",
                    value: "back",
                    uuid: "back",
                  },
                  {
                    label: "Przód",
                    value: "front",
                    uuid: "front",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field
                errors={errors}
                name="assemblyType"
                label="Typ montażu"
                options={[
                  {
                    label: "We wnęce",
                    value: "inTheAlcove",
                    uuid: "inTheAlcove",
                  },
                  {
                    label: "Na ścianie na zewnątrz",
                    value: "onTheWallOutside",
                    uuid: "onTheWallOutside",
                  },
                  {
                    label: "Na ścianie wewnątrz",
                    value: "onTheWallInside",
                    uuid: "onTheWallInside",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field
                errors={errors}
                name="typeOfDrive"
                label="Rodzaj napędu"
                half
                options={[
                  {
                    label: "Radiowy",
                    value: "radio",
                    uuid: "radio",
                  },
                  {
                    label: "Radiowy plus klawisz",
                    value: "radioPlusKey",
                    uuid: "radioPlusKey",
                  },
                  {
                    label: "Ręczny",
                    value: "manual",
                    uuid: "manual",
                  },
                  {
                    label: "Klawisz",
                    value: "key",
                    uuid: "key",
                  },
                  {
                    label: "Klawisz zewnętrzny",
                    value: "outsideKey",
                    uuid: "outsideKey",
                  },
                  {
                    label: "Wifi",
                    value: "wifi",
                    uuid: "wifi",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <SingleSectionElement
                touched={touched}
                name="sideOfThePowerCable"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Strona kabla zasilającego/linki (widok od wewnątrz)"
                  name="sideOfThePowerCable"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "right", label: "Prawa" },
                    { value: "left", label: "Lewa" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={async () => {
                    setIsAddNew(true);
                    handleSubmit();
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button
                  onClick={() => {
                    setIsAddNew(false);
                    handleSubmit();
                  }}
                  label="Zakończ pomiar"
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlindsAndShuttersMeasurementValues;
