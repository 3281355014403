import { Modal, SVG } from "components";
import { SVG_TYPE, ROUTE_ENUM } from "enums";
import moment from "moment";
import { useNavigate } from "react-router";

interface CalendarEventModalType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const CalendarEventModal = ({
  isOpen,
  onClose,
  data,
}: CalendarEventModalType) => {
  const navigate = useNavigate();

  const handleClickEdit = () => {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${data?.original?.uuid}&tab=${data?.original?.status}&edit=true`
    );
  };

  const handleClose = () => {
    onClose();
  };

  const formattedDate = moment(data?.original?.date, "DD/MM/YYYY").format(
    "dddd, D MMMM"
  );

  const listOrder = () => {
    const isActive = data?.original?.page && data?.original?.listStatus;
    if (isActive) {
      const handleClick = () =>
        navigate(
          `${ROUTE_ENUM.ORDERS_LIST}?page=${data?.original.page}&tab=${data?.original.listStatus}&orderUuid=${data.original.uuid}`
        );
      return (
        <div className="cpp__details" onClick={() => handleClick()}>
          Szczegóły zlecenia <SVG type={SVG_TYPE.LONG_ARROW} />
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        className="modal--calendar-event-modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="cpp__wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="cpp__content" onClick={(e) => e.stopPropagation()}>
              <div className="cpp__top" onClick={(e) => e.stopPropagation()}>
                <div className="cpp__actions">
                  <div
                    className="cpp__action"
                    onClick={() => handleClickEdit()}
                  >
                    <SVG type={SVG_TYPE.EDIT_PENCIL} />
                  </div>
                  <div className="cpp__action" onClick={() => handleClose()}>
                    <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                  </div>
                </div>
              </div>
              <div className="cpp__event">
                <div className="cpp__event-name">{data?.original?.title}</div>
                <div className="cpp__event-date">
                  {formattedDate} {data?.original?.start}-{data?.original?.end}
                </div>
                <div className="cpp__event-address">
                  {data?.original?.address}
                </div>
                <div className="cpp__event-user">
                  {data &&
                    data.original &&
                    data.original.employees &&
                    data.original.employees.length > 0 && (
                      <>
                        <div
                          style={{
                            backgroundColor: data.original.userColor,
                          }}
                          className="cpp__event-user-box"
                        ></div>
                        <div className="cpp__event-user-name">
                          {data.original.employees.map(
                            (item: any, index: number) => {
                              const isMoreThanOneAndNotLast =
                                index + 1 < data.original.employees.length;
                              return (
                                <>
                                  {item.name
                                    ? item.name
                                    : `${item.firstName} ${item.lastName}`}
                                  {isMoreThanOneAndNotLast ? ", " : ""}
                                </>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                </div>
                {listOrder()}
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default CalendarEventModal;
